<template>
  <Teleport to="body">
    <vi-dialog label="" content-class="whitelist-modal" :model-value="modelValue"
               @update:modelValue="$emit('update:modelValue', $event)">

      <select-payment
        @close="onUpdatedTokenAndNetwork"
        @networkChanged="onNetworkChanged"
        @updateChosenTokenAndNetwork="onUpdatedTokenAndNetwork"
      />

    </vi-dialog>

  </Teleport>

</template>


<script>
import SelectPayment from "@/components/dialogs/SelectPayment.vue";
import {Wallet} from "@/services/wallet.js";
import {mapGetters, mapState} from "vuex";

export default {
  components: {SelectPayment},
  props: {
    modelValue: Boolean,
  },
  emits: ['initWallet'],
  computed: {
    ...mapState({
      chosenToken: state => state.wallet.chosenToken,
      isWalletConnected: state => state.wallet.isWalletConnected,
    }),
    ...mapGetters({
      getActiveContract: 'presale/getActiveContract'
    })
  },

  methods: {
    onNetworkChanged({contract, tokenAddress, tokenName}) {
      console.log('switchNetwork', contract)
      if (this.isWalletConnected) {

        return Wallet.modal.switchNetwork(contract.chainId)
          .then((res) => {
            return this.$store.dispatch('wallet/updateChosenTokenAndNetwork', {
              chainId: contract.chainId, chosenToken: {
                address: tokenAddress,
                currency: tokenName,
              }
            }).catch((err) => {
              console.log('err', err)
            })
          })

      }


    },
    onUpdatedTokenAndNetwork() {
      this.step = 0;
      this.$emit('update:modelValue', false)
    }
  },

  beforeUnmount() {

  }

}
</script>
