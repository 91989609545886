<template>

  <a
    v-if="href"
    :href="href"
    target="_blank"
    class="vi-button sbc-button"
    :type="type"
    v-bind="$props"
    :class="{
      'button-medium': buttonMedium,
      'button-small': buttonSmall,
      'button-extra-small': buttonExtraSmall,
      'is-primary': isPrimary,
      'is-disabled': isDisabled,
      'is-blue-gradient': isBlueGradient,
      'is-black': isBlack,
      'is-dark-gray': isDarkGray,
      'is-disabled2': isDisabled2,
      'is-success': isSuccess,
      'is-loading': isLoading,
      'is-referral': isReferral,
      'has-border': hasBorder,
    }"
  >
    <span>{{ buttonLabel }}</span>


    <div class="icon-left" :class="{[iconColor] : iconColor}" v-if="iconLeft">
      <sbc-icon :type="iconType" :icon="iconLeft"/>
    </div>

    <div class="icon-right" :class="{[iconColor] : iconColor}" v-if="iconRight || isLoading">
      <sbc-icon :type="iconType" :icon="iconRight" v-if="iconRight"/>
      <sbc-loading v-if="isLoading"/>
    </div>

  </a>

  <vi-button
    v-else
    class="sbc-button"
    :button-label="buttonLabel"
    :type="type"
    :is-disabled="isDisabled"
    v-bind="$props"
    :class="{
      'button-medium': buttonMedium,
      'button-small': buttonSmall,
      'button-extra-small': buttonExtraSmall,
      'is-primary': isPrimary,
      'is-disabled': isDisabled,
      'is-blue-gradient': isBlueGradient,
      'is-black': isBlack,
      'is-dark-gray': isDarkGray,
      'is-disabled2': isDisabled2,
      'is-success': isSuccess,
      'is-loading': isLoading,
      'is-referral': isReferral,
      'has-border': hasBorder,
    }"
  >

    <template v-slot:icon-left>
      <div class="icon-left" :class="{[iconColor] : iconColor}" v-if="iconLeft">
        <sbc-icon :type="iconType" :icon="iconLeft"/>
      </div>
    </template>

    <template v-slot:icon-right>
      <div class="icon-right" :class="{[iconColor] : iconColor}" v-if="iconRight || isLoading">
        <sbc-icon :type="iconType" :icon="iconRight" v-if="iconRight"/>
        <sbc-loading v-if="isLoading"/>
      </div>
    </template>

  </vi-button>

</template>

<script>
import SbcIcon from "../general/SbcIcon.vue";
import SbcLoading from "@/components/SbcLoading.vue";

export default {
  name: "SbcButton",
  components: {SbcLoading, SbcIcon},
  props: {
    iconType: {
      type: String,
      default: 'regular'
    },
    buttonLabel: {
      type: String
    },
    iconLeft: {
      type: String
    },
    iconRight: {
      type: String
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    type: {
      type: String,
      default: 'button'
    },
    href: {
      type: String,
    },

    buttonMedium: Boolean,
    buttonSmall: Boolean,
    buttonExtraSmall: Boolean,
    isPrimary: Boolean,
    isDisabled: Boolean,
    isBlack: Boolean,
    isBlueGradient: Boolean,

    isDarkGray: Boolean,
    isDisabled2: Boolean,

    isSuccess: Boolean,
    isLoading: Boolean,
    isReferral: Boolean,
    hasBorder: Boolean,
  }
}
</script>

<style lang="scss">
@import "SbcButton";
</style>
