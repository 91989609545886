<template>
  <div class="time-countdown">

    <countdown-timer v-if="activePresaleStage && activePresaleStage?.endsAt " :date-time="activePresaleStage.endsAt"
                     v-slot="{ days, hours, minutes, seconds }">

      <div class="time-box">
        <div class="timer-value body-m-regular m-body-m-regular">{{ days }}</div>
        <div class="timer-title caption-s-regular m-caption-s-regular">days</div>
      </div>

      <div class="time-box">
        <div class="timer-value body-m-regular m-body-m-regular">{{ hours }}</div>
        <div class="timer-title caption-s-regular m-caption-s-regular">hours</div>
      </div>

      <div class="time-box">
        <div class="timer-value body-m-regular m-body-m-regular">{{ minutes }}</div>
        <div class="timer-title caption-s-regular m-caption-s-regular">minutes</div>
      </div>

      <div class="time-box">
        <div class="timer-value body-m-regular m-body-m-regular">{{ seconds }}</div>
        <div class="timer-title caption-s-regular m-caption-s-regular">seconds</div>
      </div>

    </countdown-timer>

  </div>
</template>

<script>
import CountdownTimer from "@/components/general/CountdownTimer.vue";
import {mapGetters} from "vuex";

export default {
  name: "TimeCountdown",
  components: {CountdownTimer},

  computed: {
    ...mapGetters('presale', ["getActivePresaleStage"]),
    activePresaleStage() {
      return this.getActivePresaleStage
    }
  },
};
</script>

<style lang='scss'>
@import "TimeCountdown";
</style>
