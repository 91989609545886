<template>
  <div class="closed-stage" v-if="pastStage">

    <div class="header">

      <div class="stage-number text-white m-heading-s-regular">
        <div class="heading-s-regular ">Stage</div>
        <div class="stage">
          <div class="current-stage heading-s-regular ">{{ pastStage?.number }}</div>
          <div class="text-red heading-s-regular">Closed</div>
        </div>
      </div>

      <!--      <time-countdown/>-->
      <div class="stage-date body-m-regular m-body-m-regular">{{ $formatDateTime(pastStage?.endsAt) }}</div>

    </div>

    <div class="total-boxes">

      <total-box
        class="amount-raised"
        title="AMOUNT RAISED"
        :main-value="$formatPrice($formatUnits(totalRaised.toString(), 8), 0)"
      />

      <total-box
        title="TOKEN PRICE"
        :main-value="$formatPrice($formatUnits(pastStage?.price.toString(), 8))"
      />

    </div>

  </div>
</template>

<script>
import TotalBox from "@/components/hero-components/TotalBox.vue";
import TimeCountdown from "@/components/hero-components/TimeCountdown.vue";
import {mapGetters} from "vuex";

export default {
  name: "ClosedStage",
  components: {TimeCountdown, TotalBox},

  computed: {
    ...mapGetters('presale', ["getTotalRaised"]),
    ...mapGetters('presale', ["getTotalTokensSold"]),
    ...mapGetters('presale', ["getTotalTokensInAllStages"]),
    ...mapGetters('presale', ["hasTransactionErrors"]),
    totalRaised() {
      return this.getTotalRaised;
    },
    totalTokensSold() {
      return this.getTotalTokensSold;
    },
    totalTokensInAllStages() {
      return this.getTotalTokensInAllStages
    },

    ...mapGetters('presale', ["getPastStage"]),
    pastStage() {
      return this.getPastStage
    }
  }
};
</script>

<style lang='scss'>
@import "ClosedStage";
</style>
