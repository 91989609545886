import ethereum from '/assets/blockchains/01.Ethereum.svg'
import bnb from '/assets/blockchains/02.BNB.svg'
import polygon from '/assets/blockchains/03.Polygon.svg'
import tron from '/assets/blockchains/04.Tron.svg'
import huobiECO from '/assets/blockchains/05.HuobiECO.svg'
import avalanche from '/assets/blockchains/06.Avalanche.svg'
import cosmos from '/assets/blockchains/07.Cosmos.svg'
import okx from '/assets/blockchains/08.OKX.svg'
import celestia from '/assets/blockchains/09.Celestia.svg'
import fantom from '/assets/blockchains/10.Fantom.svg'
import pulseChain from '/assets/blockchains/11.PulseChain.svg'
import blast from '/assets/blockchains/blast.svg'
import plus from '/assets/blockchains/30plus.svg'

const Blockchains = [{
    src: ethereum, link: 'https://ethereum.org/en/'
}, {
    src: bnb, link: 'https://www.bnbchain.org/en'
}, {
    src: polygon, link: 'https://polygon.technology/'
}, {
    src: tron, link: 'https://tron.network/'
}, {
    src: huobiECO, link: 'https://docs.chain.com/docs/cloud/supported-chains/huobi'
}, {
    src: avalanche, link: 'https://www.avax.network/'
}, {
    src: cosmos, link: 'https://cosmos.network/'
}, {
    src: okx, link: 'https://www.okx.com/oktc'
}, {
    src: celestia, link: 'https://celestia.org/'
}, {
    src: fantom, link: 'https://fantom.foundation/'
}, {
    src: pulseChain, link: 'https://pulsechain.com/'
}, {
    src: blast, link: 'https://blast.io/en', smallSize: true,
}, {
    src: plus,
},];

export default Blockchains;
