<template>

  <div class="release-timer">

    <div class="title-wrapper">
      <div class="title heading-s-regular m-heading-s-regular">
        Pre-Sale <span class="highlight-blue">Opens in</span>
      </div>
    </div>

    <time-countdown2 :time="time"/>

  </div>

</template>

<script>
import TimeCountdown2 from "@/components/general/TimeCountdown2.vue";

export default {
  name: "ReleaseTimer",
  components: {TimeCountdown2},

  props: {
    time: {
      type:String,
      required: true
    }
  },

  methods: {}
};
</script>

<style lang='scss'>
@import "ReleaseTimer";
</style>
