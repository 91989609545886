<template>

  <div class="faq-box" @click="toggleAnswer" :class="{'active':showAnswer}">

    <div class="top-row">

      <div class="question heading-s-regular m-body-m-regular" v-html="faq.question"></div>

      <div class="icon-container">

        <Transition>
          <svg v-show="!showAnswer" class="open-close open" width="60" height="56" viewBox="0 0 60 56"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30 1.75C20.5938 1.75 11.9531 6.78125 7.25 14.875C2.54688 23.0781 2.54688 33.0312 7.25 41.125C11.9531 49.3281 20.5938 54.25 30 54.25C39.2969 54.25 47.9375 49.3281 52.6406 41.125C57.3438 33.0312 57.3438 23.0781 52.6406 14.875C47.9375 6.78125 39.2969 1.75 30 1.75ZM30 56C19.9375 56 10.75 50.75 5.71875 42C0.6875 33.3594 0.6875 22.75 5.71875 14C10.75 5.35938 19.9375 0 30 0C39.9531 0 49.1406 5.35938 54.1719 14C59.2031 22.75 59.2031 33.3594 54.1719 42C49.1406 50.75 39.9531 56 30 56ZM29.125 38.5V28.875H19.5C18.9531 28.875 18.625 28.5469 18.625 28C18.625 27.5625 18.9531 27.125 19.5 27.125H29.125V17.5C29.125 17.0625 29.4531 16.625 30 16.625C30.4375 16.625 30.875 17.0625 30.875 17.5V27.125H40.5C40.9375 27.125 41.375 27.5625 41.375 28C41.375 28.5469 40.9375 28.875 40.5 28.875H30.875V38.5C30.875 39.0469 30.4375 39.375 30 39.375C29.4531 39.375 29.125 39.0469 29.125 38.5Z"
                fill="#D4D6D8"/>
          </svg>
        </Transition>

        <Transition>
          <svg v-show="showAnswer" width="60" height="56" viewBox="0 0 60 56" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30 1.75C20.5938 1.75 11.9531 6.78125 7.25 14.875C2.54688 23.0781 2.54688 33.0312 7.25 41.125C11.9531 49.3281 20.5938 54.25 30 54.25C39.2969 54.25 47.9375 49.3281 52.6406 41.125C57.3438 33.0312 57.3438 23.0781 52.6406 14.875C47.9375 6.78125 39.2969 1.75 30 1.75ZM30 56C19.9375 56 10.75 50.75 5.71875 42C0.6875 33.3594 0.6875 22.75 5.71875 14C10.75 5.35938 19.9375 0 30 0C39.9531 0 49.1406 5.35938 54.1719 14C59.2031 22.75 59.2031 33.3594 54.1719 42C49.1406 50.75 39.9531 56 30 56ZM19.5 27.125H40.5C40.9375 27.125 41.375 27.5625 41.375 28C41.375 28.5469 40.9375 28.875 40.5 28.875H19.5C18.9531 28.875 18.625 28.5469 18.625 28C18.625 27.5625 18.9531 27.125 19.5 27.125Z"
                fill="#414548"/>
          </svg>
        </Transition>

      </div>

    </div>

    <div class="answer body-s-regular m-caption-l-regular" v-html="faq.answer"></div>

  </div>

</template>

<script>
export default {
  name: "FAQBox",

  props: {
    faq: Object,
    showAnswer: {
      type: Boolean
    }
  },

  methods: {
    toggleAnswer() {
      if (this.showAnswer) {
        this.$emit('setActiveBox', null)
      } else {
        this.$emit('setActiveBox', this.faq.id)
      }

    }
  }
};
</script>

<style lang='scss'>
@import "FAQBox";
</style>