<template>

  <div v-if="isKYCCompleted" class="kyc-box completed">
    <div class="title heading-s-regular ">KYC</div>
    <div class="status caps-m-regular">completed</div>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#6F757B"/>
      <path
          d="M18.7812 7.21875C19.0625 7.53125 19.0625 8 18.7812 8.28125L10.5312 16.5312C10.2188 16.8438 9.75 16.8438 9.46875 16.5312L5.21875 12.2812C4.90625 12 4.90625 11.5312 5.21875 11.25C5.5 10.9375 5.96875 10.9375 6.25 11.25L9.96875 14.9688L17.7188 7.21875C18 6.9375 18.4688 6.9375 18.75 7.21875H18.7812Z"
          fill="#00BDD6"/>
    </svg>
  </div>

  <div v-else class="kyc-box progress" :class="{'active' : isKYCCompleted}">
    <div class="heading-s-regular">KYC</div>
    <div class="status caps-m-regular ">in progress</div>
    <div class="loading-icon">
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "KYCBox",
  computed: {
    ...mapState({
      isWalletConnected: state => state.wallet.isWalletConnected,
      isKYCCompleted: state => state.wallet.isKYCCompleted,
    })
  },
};
</script>

<style lang='scss'>
@import "KYCBox";
</style>