<template>
  <div class="currency-dropdown">

    <div class="currency-dropdown-wrapper" @click="$emit('openPaymentDialog')">

      <div class="currency-container">

        <div class="currency-wrapper">

          <div class="currency-image-container">
            <img v-if="chosenToken?.currency" :src="tokenIcons[chosenToken?.currency]" alt="">
          </div>

          <div class="amount-row">

            <div class="currency heading-s-regular m-heading-s-regular">{{ chosenToken?.currency }}</div>

            <div class="coin-wrapper">

              <div class="body-s-regular m-body-s-regular available">AVBL</div>
              <div class="amount body-s-regular m-body-s-regular">{{
                  $formatTokenAmount($formatUnits(currentTokenBalance ? currentTokenBalance.toString() : "0", 18),chosenToken?.currency )
                }}
              </div>
              <!--          <div class="currency-long body-s-regular">{{ contract?.name }}</div>-->
            </div>

          </div>


        </div>


      </div>

      <div class="arrow">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.34375 7.375C7.15625 7.5625 6.8125 7.5625 6.625 7.375L0.625 1.375C0.4375 1.1875 0.4375 0.84375 0.625 0.65625C0.8125 0.46875 1.15625 0.46875 1.34375 0.65625L7 6.3125L12.625 0.65625C12.8125 0.46875 13.1562 0.46875 13.3438 0.65625C13.5312 0.84375 13.5312 1.1875 13.3438 1.375L7.34375 7.375Z"
            fill="white"/>
        </svg>
      </div>

    </div>

  </div>


</template>

<script>
import {mapGetters, mapState} from "vuex";
import {chainIcons, tokenIcons} from "@/services/chainAndTokenIcons.js";

export default {
  name: "CurrencyDropdown",
  emits: ['openPaymentDialog'],

  data: () => ({
    tokenIcons,
    chainIcons,

  }),

  computed: {
    ...mapState({
      currentNetwork: state => state.wallet.currentNetwork,
      currentAddress: state => state.wallet.currentAddress,
      isWalletConnected: state => state.wallet.isWalletConnected,
      currentTokenBalance: state => state.wallet.currentTokenBalance,
      chosenToken: state => state.wallet.chosenToken,
      chosenChainId: state => state.wallet.chosenChainId,
      presaleContracts: state => state.presale.presaleContracts,
    }),
    ...mapGetters('presale', ["getContractByChainId"]),
    contract() {
      return this.getContractByChainId(this.chosenChainId)
    }
  },

  methods: {
    getAmountForChosenToken() {
      this.$store.dispatch('wallet/calculateCurrentTokenBalance');
    }
  },

  created() {
    setTimeout(() => this.getAmountForChosenToken(), 500)
    this.checkBalance = setInterval(this.getAmountForChosenToken, 30000);
  },

  beforeUnmount() {
    clearInterval(this.checkBalance)
  }


};
</script>

<style lang='scss'>
@import "CurrencyDropdown";
</style>
