<template>
  <div
      ref="container"
      class="sbc-carousel"
      :class="{'dragging': isDragging}"
      @touchmove="onTouchMove"
      @touchstart="onTouchStart"
      @touchend="onTouchEnd"
      @mousedown="onMouseDown"
      @mousemove="onMouseMove"
      @mouseup="onMouseUp"
      @mouseleave="onMouseLeave"
      @mouseenter="onMouseEnter"
  >
    <template v-if="initialized">
      <div v-if="!itemsScroll || !itemsScroll.length">
        <slot name="noContent"></slot>
      </div>
      <template v-else>
        <div ref="containerScroll" class="sbc-carousel-scroll" :style="{transform: `translateX(${offsetX}px)`}">
          <div class="sbc-carousel-item" :style="{width: itemWidth}" v-for="(item, index) in itemsScroll" :key="index">
            <slot name="listItem" v-bind="{item, index}"/>
          </div>
        </div>
      </template>
    </template>

    <div
        class="item-scroll-bar"
        v-if="enableScrollBar"
        @mousedown="onMouseDownBar"
        @mousemove="onMouseMoveBar"
        @mouseup="onMouseUpBar"
    >
      <div :style="{width: `${scrollBarWidth}%`, transform: `translateX(${-(offsetX * (scrollBarWidth/100))}px)`}"
           class="bar"></div>
      <div class="item-scroll-bar-placeholder"></div>
    </div>

  </div>
</template>

<script>

import {wait} from "@/services/utils.js";

export default {
  name: 'SbcCarousel',
  props: {
    items: Array,
    minWidth: {
      type: Number,
      default: 0
    },
    cols: {
      type: Number,
      default: 3
    },
    autoScroll: {
      type: Boolean,
      default: false
    },
    autoScrollSpeed: {
      type: Number,
      default: 1,
    },
    enableScrollBar: {
      type: Boolean,
      default: false
    },
    autoScrollDelay: {
      type: Number,
      default: 0
    }
  },

  data: () => ({
    initialized: false,
    containerWidth: null,
    offsetX: 0,
    isDragging: false,
    itemsScroll: [],
    overflowIndex: 0,
    scrollBarWidth: 0,
    autoScrollActive: false,
    autoScrollDelayTimeout: 0,
    autoScrollInitiallyStarted: false
  }),

  computed: {
    itemWidth() {
      let width = this.containerWidth / this.cols;

      return `${width > this.minWidth ? width : this.minWidth}px`
    }
  },

  methods: {
    handleWheelEvent(e) {
      if (this.$refs.container.contains(e.target) || this.$refs.container === e.target) {
        e.preventDefault();

        this.offsetX -= e.deltaX;
        document.documentElement.scrollTop += e.deltaY
        this.checkValidOffset();
      }
    },
    checkValidOffset() {
      if (this.offsetX > 0) {
        this.offsetX = 0
      }

      if (this.$refs?.containerScroll.scrollWidth + this.offsetX < this.containerWidth) {
        this.offsetX = this.containerWidth - this.$refs?.containerScroll.scrollWidth;
      }
    },
    onTouchMove(e) {
      let event = e.touches[0];

      if (this.prevTouch) {
        let currentTimeSegment = Math.floor(Date.now() / 50)
        if (currentTimeSegment !== this.previousTimeSegment) {
          this.previousTimeSegment = currentTimeSegment;
          this.previousOffset = event.clientX;
        }
        if (Math.abs(this.previousOffset - event.clientX) > 15) {
          // console.log('this.previousOffset - event.clientX', this.previousOffset - event.clientX)
          e.preventDefault()
        }
        this.offsetX -= this.prevTouch.clientX - event.clientX
        this.checkValidOffset()
      }
      this.prevTouch = event;
    },

    onTouchStart(e) {
      this.prevTouch = e.touches[0]

      this.previousTimeSegment = Math.floor(Date.now() / 50);
      this.previousOffset = this.prevTouch.clientX;
      this.velocity = this.amplitude = 0;
      this.timestamp = Date.now();
      this.frame = this.offsetX;
      clearInterval(this.ticker);
      this.ticker = setInterval(this.track, 100);

      if (this.autoScroll) {
        this.stopAutoScroll()
      }
    },
    onTouchEnd() {
      this.prevTouch = null
      this.previousOffset = 0;

      clearInterval(this.ticker)
      if (this.velocity > 10 || this.velocity < -10) {
        this.amplitude = 0.2 * this.velocity;
        this.target = Math.round(this.offsetX + this.amplitude);
        this.timestamp = Date.now();
        this.scrollKineticActive = true
        requestAnimationFrame(this.scrollKinetic);
      }
      // if (this.autoScroll) {
      //   this.startAutoScroll()
      // }
    },

    track() {
      let now, elapsed, delta, v;

      now = Date.now();
      elapsed = now - this.timestamp;
      this.timestamp = now;
      delta = this.offsetX - this.frame;
      this.frame = this.offsetX;

      v = 1000 * delta / (1 + elapsed);
      this.velocity = 0.8 * v + 0.4 * this.velocity;
    },

    scrollKinetic() {
      let elapsed, delta;
      if (this.amplitude && this.scrollKineticActive) {
        elapsed = Date.now() - this.timestamp;
        delta = -this.amplitude * Math.exp(-elapsed / 325);
        if (delta > 0.5 || delta < -0.5) {
          this.offsetX = this.target + delta;
          requestAnimationFrame(this.scrollKinetic);
        } else {
          this.offsetX = this.target;
          this.scrollKineticActive = false
          this.applyOverscroll()
          if (this.autoScroll) {
            this.startAutoScroll()
          }
        }

        this.checkValidOffset()
      } else {
        if (this.autoScroll) {
          this.startAutoScroll()
        }
      }
    },

    onMouseDown(e) {
      // if (this.autoScroll) {
      //   return
      // }
      this.dragStart = {
        x: e.clientX,
        y: e.clientY,
        offsetX: this.offsetX,
        click: true
      }
    },
    onMouseDownBar(e) {
      e.stopPropagation()
      this.dragStartScroll = {
        x: e.clientX,
        y: e.clientY,
        offsetX: this.offsetX,
        click: true
      }
    },

    onMouseMove(e) {
      // if (this.autoScroll) {
      //   return
      // }
      if (this.dragStart) {
        if (Math.abs(this.dragStart.x - e.clientX) > 10) {
          this.dragStart.click = false;
          this.isDragging = true;
          e.preventDefault();
          this.offsetX = -this.dragStart.x + e.clientX + this.dragStart.offsetX

          this.checkValidOffset();
        }
      }
    },

    onMouseMoveBar(e) {
      if (this.dragStartScroll) {
        e.stopPropagation();
        e.preventDefault();
        if (Math.abs(this.dragStartScroll.x - e.clientX) > 10) {
          this.dragStartScroll.click = false;
          this.isDragging = true;
          // e.preventDefault();
          this.offsetX = (this.dragStartScroll.x - e.clientX)/(this.scrollBarWidth/100) + this.dragStartScroll.offsetX

          this.checkValidOffset();
        }
      }
    },

    onMouseLeave(e) {
      if (this.autoScroll) {
        this.startAutoScroll()
      }
    },
    onMouseUp(e) {
      if (this.autoScroll && this.dragStart?.click) {
        // this.startAutoScroll()
        // return
      }
      if (!this.autoScroll && !this.dragStart?.click) {
        e.preventDefault()
      }
      setTimeout(() => {
        this.isDragging = false
      }, 10)
      this.dragStart = null
      this.dragStartScroll = null
    },

    onMouseUpBar(e) {
      if (this.autoScroll && this.dragStartScroll?.click) {
        // this.startAutoScroll()
        // return
      }
      if (!this.autoScroll && !this.dragStartScroll?.click) {
        e.preventDefault()
      }
      setTimeout(() => {
        this.isDragging = false
      }, 10)
      this.dragStart = null
      this.dragStartScroll = null
    },

    onMouseEnter() {
      this.stopAutoScroll()
    },

    stopAutoScroll() {
      clearInterval(this.autoScrollInterval)
      this.autoScrollActive = false
      clearTimeout(this.autoScrollDelayTimeout)
    },

    async startAutoScroll() {
      clearInterval(this.autoScrollInterval);
      this.autoScrollActive = true

      if(!this.autoScrollInitiallyStarted) {
        await new Promise(res => {
          this.autoScrollDelayTimeout = setTimeout(() => {
            res()
          }, this.autoScrollDelay)
        })
        this.autoScrollInitiallyStarted = true
      }

      if(!this.autoScrollActive) {
        return;
      }

      this.autoScrollInterval = setInterval(() => {
        this.offsetX -= (1 * this.autoScrollSpeed)
        if ((this.$refs?.containerScroll.scrollWidth - 500) + this.offsetX < this.containerWidth) {
          this.applyOverscroll()
        }
        // this.checkValidOffset();
      }, 15)
    },

    applyOverscroll() {
      if ((this.$refs?.containerScroll.scrollWidth - 500) + this.offsetX < this.containerWidth) {
        let lastScroll = this.$refs?.containerScroll.scrollWidth;
        this.itemsScroll.push(this.items[this.overflowIndex])
        // console.log('this.$refs?.containerScroll.scrollWidth ADDED ELEMENT', this.itemsScroll.length, this.$refs?.containerScroll.scrollWidth)
        this.overflowIndex++;
        this.overflowIndex %= this.items.length;
        if (this.itemsScroll.length > ((this.items.length * 2) + 4)) {
          this.$nextTick()
            .then(() => {
              lastScroll = this.$refs?.containerScroll.scrollWidth;
              this.itemsScroll.shift()
              this.itemsScroll.shift()
            })
            .then(() => {
              // console.log('this.$refs?.containerScroll.scrollWidth ', lastScroll, this.$refs?.containerScroll.scrollWidth, this.offsetX)
              this.offsetX += lastScroll - this.$refs?.containerScroll.scrollWidth;
            })
          // console.log('')
          // this.itemsScroll.shift()
          // this.$nextTick()

        }

        if (this.overflowIndex === this.items.length) {
          this.overflowIndex = 0;
        }
      }
    }
  },

  created() {
    // this.onTouchMove = throttle(this.onTouchMove, 50)
  },

  mounted() {
    this.itemsScroll = [...this.items]
    // window.addEventListener('wheel', this.handleWheelEvent, {passive: false})
    this.containerWidth = this.$refs.container.offsetWidth;
    this.initialized = true;

    this.$nextTick()
        .then(() => {
          this.scrollBarWidth = Math.round((this.containerWidth / this.$refs?.containerScroll.scrollWidth) * 100);
        })
    if (this.autoScroll) {

      this.observer = new window.IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          this.startAutoScroll()
          return
        }
        this.stopAutoScroll()
        if (entry.boundingClientRect.top > 0) {

        } else {
        }
      }, {
        root: null,
        threshold: 0,
      })
      this.observer.observe(this.$refs.container);
    }
  },

  beforeUnmount() {
    window.removeEventListener('wheel', this.handleWheelEvent, false)
    this.stopAutoScroll()
    this.autoScrollActive = false
    this.observer?.disconnect();
  }
}
</script>

<style lang="scss">
@import "SbcCarousel.scss";
</style>
