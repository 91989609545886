<template>
  <Teleport to="body">
    <vi-dialog label="Join our whitelist" content-class="whitelist-modal" :model-value="modelValue" @update:modelValue="$emit('update:modelValue', $event)">
      <a class="e-widget no-button" href="https://gleam.io/b2fHO/testsbc-2" rel="nofollow">Whitelist for SUISSE Token</a>
    </vi-dialog>
  </Teleport>
</template>

<script>
export default {
  name: 'WhitelistDialog',
  props: {
    modelValue: Boolean
  },

  watch: {
    modelValue() {
      console.log('document.getElementById(\'gleeam-script\'', document.getElementById('gleeam-script'))
      if(document.getElementById('gleeam-script')) {
        document.getElementById('gleeam-script').remove();
      }

      let script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', 'https://widget.gleamjs.io/e.js');
      script.setAttribute('async', 'true');
      script.id = 'gleeam-script'
      console.log('MOUNTED')

      document.body.append(script);

    }
  }
}
</script>

<style lang="scss">
.whitelist-modal {
  background: #0F8A75;
}
</style>