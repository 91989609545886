export default {

    mounted(el, binding, vnode) {
        let value = binding.value;
        el.trackScrollTrigger = () => {
            let rect = el.getBoundingClientRect()
            let offset = 0;
            let inView = (
                rect.width > 0 &&
                rect.height > 0 &&
                rect.top >= 0 &&
                (rect.bottom + offset - ( rect.height * 0.8)) <= (window.innerHeight || document.documentElement.clientHeight)
            )
            if (inView) {
                try {
                    value()
                } catch (e) {}
                window.removeEventListener('scroll', el.trackScrollTrigger)
            }
        }
        setTimeout(() => {
            el.trackScrollTrigger(el, value)
        }, 300)

        window.addEventListener('scroll', el.trackScrollTrigger)
    },
    beforeUnmount(el, binding, vnode) {
        window.removeEventListener('scroll', el.trackScrollTrigger)
    },
}