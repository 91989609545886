import nuls from '/assets/support/01-nuls.svg'
import noma from '/assets/support/02-noma.png'
import innoqube from '/assets/support/05-innoqube.svg'
import plugAndPlay from '/assets/support/06-plugandplay.svg'
import wengerVieli from '/assets/support/07-wengervieli.svg'
import sma from '/assets/support/11-sma.png'
import coinFinance from '/assets/support/13-coinfinance.svg'
import fhgr from '/assets/support/14-fhgr.svg'
import berkely from '/assets/support/15-berkely.svg'
import nabox from '/assets/support/16-nabox.svg'
import swapBox from '/assets/support/17-swapbox.svg'
import nerve from '/assets/support/18-nerve.svg'
import startupColors from '/assets/support/19-startupcolors.png'
import peakora from '/assets/support/20-peakora.svg'
import adna from '/assets/support/22-adna.svg'
import sinoswissTechnopark from '/assets/support/23-sinoswiss-technopark.jpg'
import foundersInstitute from '/assets/support/24-foundersinstitute.svg'
import blockchainNation from '/assets/support/blockchain-nation.svg'
import notion from '/assets/support/notion.svg'
import innoSuisse from '/assets/support/innosuisse.svg'
import blockchainFederation from '/assets/support/sbf-logo.svg'
import cryptovalley from '/assets/support/cryptovalley.svg'
import sge from '/assets/support/sge.svg'
import coinTelegraph from '/assets/support/cointelegraph-logo.svg'
import vardex from '/assets/support/vardex-logo.svg'

const LogoSliderItems = [
    {
        image: nuls,
        link: 'https://nuls.io/',
    },
    {
        image: coinFinance,
        link: 'https://www.coin-finance.ch/',
    },
    {
        image: blockchainFederation,
        link: 'https://blockchainfederation.ch/?lang=en',
    },
    {
        image: foundersInstitute,
        link: 'https://fi.co',
    },
    {
        image: cryptovalley,
        link: 'https://cryptovalley.swiss/',
    },
    {
        image: plugAndPlay,
        link: 'https://www.plugandplaytechcenter.com/',
    },
    {
        image: nabox,
        link: 'https://nabox.io/',
    },
    {
        image: coinTelegraph,
        link: 'https://cointelegraph.com/',
    },
    {
        image: berkely,
        link: 'https://www.berkeley.edu/',
    },
    {
        image: wengerVieli,
        link: 'https://www.wengervieli.ch/en-us',
    },
    {
        image: innoqube,
        link: 'https://innoqube.swiss/',
    },
    {
        image: nerve,
        link: 'https://nerve.network/',
    },
    {
        image: swapBox,
        link: 'https://swapbox.nabox.io/',
    },
    {
        image: blockchainNation,
        link: 'https://www.blockchainnation.ch/en/home'
    },
    {
        image: notion,
        link: 'https://www.notion.so/'
    },
    {
        image: noma,
        link: 'https://noma.pro/',
    },
    {
        image: sma,
        link: 'https://metassociation.ch/en/',
    },
    {
        image: sge,
        link: 'https://www.s-ge.com/en/non-sbh?ct',
    },
    {
        image: fhgr,
        link: 'https://www.fhgr.ch/en/',
    },
    {
        image: startupColors,
        link: 'https://startupcolors.com/',
    },
    {
        image: peakora,
        link: 'https://www.peakora.io/',
    },
    {
        image: adna,
        link: 'https://adna.io/',
    },
    {
        image: sinoswissTechnopark,
        link: 'http://www.sinoswiss-technopark.com/pc_en/',
    },
    {
        image: innoSuisse,
        link: 'https://www.innosuisse.ch/inno/en/home.html'
    },
    {
        image: vardex,
        link: 'https://www.vaerdex.ch/'
    },
];

export default LogoSliderItems;
