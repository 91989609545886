<template>
  <form class="subscribe"
        :class="{'subscribe-medium' : subscribeMedium, 'subscribe-small':subscribeSmall}"
        @submit.prevent="submitForm"
  >
    <input :placeholder="placeholder" v-model="email">

    <sbc-button
      :button-label="label"
      :button-medium="buttonMedium"
      :button-small="buttonSmall"
      :button-extra-small="buttonExtraSmall"
      icon-type="regular"
      type="submit"
    />

    <div v-for="error in v$.$errors" class="validation caption-l-regular">{{ error.$message }}</div>
  </form>
</template>

<script>
import {email, required} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: 'Subscribe',
  components: {SbcButton},

  props: {
    label: {
      type: String,
      required: true,
    },
    subscribeSmall: {
      type: Boolean,
      required: false,
    },
    subscribeMedium: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },

    buttonMedium: {
      type: Boolean,
      required: false,
    },
    buttonSmall: {
      type: Boolean,
      required: false,
    },
    buttonExtraSmall: {
      type: Boolean,
      required: false,
    },
  },

  data: () => ({
    email: null,
  }),
  validations: {
    email: {
      required,
      email,
    },
  },
  setup: () => ({v$: useVuelidate()}),

  methods: {
    async submitForm() {

      this.v$.$touch()
      console.log("Dasdsd", this.v$.$error)
      if (this.v$.$error) {
        return;
      }
      await fetch(`https://suisseblockchain.us20.list-manage.com/subscribe/post-json?u=f6e1d4b573898f5ea1d8342c6&id=f5a525b5d2&EMAIL=${this.email}&c=?`, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': "application/json; charset=utf-8"
        },
        body: JSON.stringify({EMAIL: this.email})
      });
    },
  },

}
</script>

<style lang="scss">
@import "Subscribe.scss";
</style>
