<template>
  <div class="address-box" @click="copyAddress">

    <div class="address heading-s-regular m-heading-s-regular">{{ shortenedText }}</div>

    <svg  width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M18 16.5C18.7969 16.5 19.5 15.8438 19.5 15V5.4375C19.5 5.25 19.4062 5.0625 19.2656 4.92188L16.0781 1.73438C15.9375 1.59375 15.75 1.5 15.5625 1.5H10.5C9.65625 1.5 9 2.20312 9 3V15C9 15.8438 9.65625 16.5 10.5 16.5H18ZM20.2969 3.84375C20.7188 4.26562 21 4.875 21 5.4375V15C21 16.6875 19.6406 18 18 18H10.5C8.8125 18 7.5 16.6875 7.5 15V3C7.5 1.35938 8.8125 0 10.5 0H15.5625C16.125 0 16.7344 0.28125 17.1562 0.703125L20.2969 3.84375ZM3 6H6V7.5H3C2.15625 7.5 1.5 8.20312 1.5 9V21C1.5 21.8438 2.15625 22.5 3 22.5H10.5C11.2969 22.5 12 21.8438 12 21V19.5H13.5V21C13.5 22.6875 12.1406 24 10.5 24H3C1.3125 24 0 22.6875 0 21V9C0 7.35938 1.3125 6 3 6Z"
          fill="white"/>
    </svg>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AddressBox",
  props: {
    text: {
      type: String,
    }
  },

  computed: {
    ...mapState({
      currentAddress: state => state.wallet.currentAddress
    }),

    shortenedText() {
      if (!this.text) return '';
      if (this.text?.length <= 11) {
        return this.text;
      }
      return `${this.text.slice(0, 4)}...${this.text.slice(-4)}`;
    }
  },

  methods:{
    async copyAddress() {
      try {
        // Use the Clipboard API to write the link to the clipboard
        await navigator.clipboard.writeText(this.currentAddress);
        alert('Link copied to clipboard: ' + this.currentAddress);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
  }

  // methods: {
  //   copyText() {
  //     const text = this.shortenedText
  //     navigator.clipboard.writeText('text').then(() => {
  //       alert("Text copied to clipboard!");
  //     }).catch(err => {
  //       console.error("Failed to copy text: ", err);
  //     });
  //   }
  // }
};
</script>

<style lang='scss'>
@import "AddressBox";
</style>
