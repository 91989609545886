<template>
  <div class="app">

    <main-header
      :show-mobile-menu="showMobileMenu"
      @toggleMobileMenu="toggleMobileMenu"
    />

    <router-view @initWallet="init"/>

    <main-footer
      v-if="!showUserDashboard && $isMobile  || !$isMobile"
    />

    <Transition name="mobile-menu">

      <mobile-menu
        v-if="showMobileMenu"
        @toggleMobileMenu="toggleMobileMenu"
      />
    </Transition>

    <secondary-menu/>

    <kyc-authentication-dialog v-if="showKYCDialog" v-model="showKYCDialog"/>

  </div>
</template>

<script>
import {RouterView} from 'vue-router'
import MainHeader from "@/components/general/MainHeader.vue";
import MobileMenu from "@/components/general/MobileMenu.vue";
import MainFooter from "@/components/general/MainFooter.vue";
import {Wallet} from "@/services/wallet.js";
import {mapGetters, mapState} from 'vuex';
import {sendForVerification, signInWithEthereum} from "@/services/walletAuth.js";
import KycAuthenticationDialog from "@/components/dialogs/KycAuthenticationDialog.vue";
import SecondaryMenu from "@/components/general/SecondaryMenu.vue";

export default {
  name: "App",
  components: {SecondaryMenu, KycAuthenticationDialog, MainFooter, MainHeader, MobileMenu},

  data: () => ({
    showMobileMenu: false,
    showKYCDialog: false
  }),

  computed: {
    ...mapState({
      presaleContracts: state => state.presale.presaleContracts,
      currentAddress: state => state.wallet.currentAddress,
      chosenChainId: state => state.wallet.chosenChainId,
      chosenToken: state => state.wallet.chosenToken,
      isWalletLoading: state => state.wallet.isWalletLoading,
      isWalletConnected: state => state.wallet.isWalletConnected,
      self: state => state.presale.self,
      showUserDashboard: state => state.general.showUserDashboard
    }),
    ...mapGetters('presale', ["getContractByChainId"]),
  },

  methods: {
    async init() {
      console.log('INITNITNTINTIN')

      if (Wallet.isConnected()) {
        this.$store.commit('wallet/setIsWalletLoading', true)
      }

      let isValidNetwork = await Wallet.checkValidNetwork();
      if (!isValidNetwork) {
        this.$store.commit('wallet/setIsWalletLoading', false)
        return;
      }

      await Wallet.modal.switchNetwork(this.chosenChainId)

      this.$store.dispatch('wallet/signInWithEthereum')
        .then(() => {
          this.$store.commit('wallet/setIsWalletLoading', false)
          this.$store.commit('wallet/setIsWalletConnected', Wallet.isConnected())
          if (Wallet.isConnected()) {
            Wallet.getCurrentNetwork()
              .then(network => {
                this.$store.commit('wallet/setCurrentAddress', Wallet.getCurrentAddress());
                this.$store.commit('wallet/setCurrentNetworkId', network.chainId);

                if (this.chosenChainId === parseInt(network.chainId)) {
                  console.log("this.chosenChainId === network.chainId")
                  this.$store.dispatch('wallet/updateChosenTokenAndNetwork', {
                    chainId: network.chainId,
                    chosenToken: this.chosenToken
                  });
                } else {
                  console.log("ELSE ")
                  this.$store.dispatch('wallet/updateChosenTokenAndNetwork', {chainId: network.chainId});
                }


                this.$store.dispatch('presale/listPresaleTransactions', {filter: {isReferral: false}});
                this.$store.dispatch('presale/listRocketFuelPresaleTransactions', {filter: {}});
                this.$store.dispatch('presale/selfPresale')
                  .then(() => {
                    if (this.self.kycStatus === 'NOT_STARTED') {
                      this.showKYCDialog = true;
                    }

                    if (this.self.kycStatus === 'NOT_STARTED' || this.self.kycStatus === 'PENDING') {
                      this.selffInterval = setInterval(this.getSelf, 10000);
                    }
                  })

                this.$store.commit('wallet/setIsNabox', !!Wallet.isNuls || !!Wallet.isNabox)

                try {
                  window.ethereum.on("accountsChanged", (accounts) => {
                    if (!accounts.includes[this.currentAddress]) {
                      console.log("ACCOUNT DISCONßNECTED");
                      Wallet.modal.adapter.disconnect();
                      this.$store.commit('wallet/resetState');
                      this.$store.commit('presale/resetState');
                    }
                  });
                  window.NaboxWallet?.on("accountsChanged", (accounts) => {
                    console.log('NaboxWallet accounts', accounts)
                    if (!accounts.includes[this.currentAddress]) {
                      console.log("ACCOUNT DISCONNECTED");
                      Wallet.modal.adapter.disconnect();
                      this.$store.commit('wallet/resetState');
                      this.$store.commit('presale/resetState');
                    }
                  });
                } catch (error) {
                  console.log("ACCOUNT CHANGE ERROR", error)
                }
              })
          }
        })
        .catch(err => {
          this.$store.commit('wallet/setIsWalletLoading', false)
          Wallet.modal.adapter.disconnect();
          this.$store.commit('wallet/resetState');
          this.$store.commit('presale/resetState');
          console.log("ERRRRRR", err)
        })

    },
    toggleMobileMenu() {
      console.log('toggleMobileMenu')
      if (this.$isMobile) {
        this.showMobileMenu = !this.showMobileMenu;
      } else {
        this.$router.push({name: 'HomePage', hash: '#buy-suisse'});
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    },
    tick() {
      this.$store.commit('updateTime')
    },
    listPresaleStages() {
      this.$store.dispatch('presale/listPresaleStages')
    },
    listPresaleContracts() {
      return this.$store.dispatch('presale/listPresaleContracts', {
        pagination: {
          page: 0,
          rowsPerPage: 10
        }
      })
    },
    getSelf() {
      this.$store.dispatch('presale/selfPresale')
        .then((self) => {
          if (self.kycStatus === 'SUCCESS') {
            clearInterval(this.selffInterval)
          }
        })
    }
  },

  created() {
    this.tickInterval = setInterval(this.tick, 1000);
    this.listPresaleStagesInterval = setInterval(this.listPresaleStages, 15000);
    this.listPresaleContractsInterval = setInterval(this.listPresaleContracts, 15000);


    this.listPresaleStages();

    this.listPresaleContracts()
      .then((presaleContracts) => {

        this.$store.dispatch('wallet/updateChosenTokenAndNetwork', {chainId: presaleContracts[0].chainId});

        Wallet.initilizeModal(this.presaleContracts)
          .then(() => {
              if (Wallet.isConnected()) {
                this.$store.commit('wallet/setIsWalletLoading', true)
                this.init()
              }
            }
          );

        Wallet.modal.subscribeEvents(async event => {
          console.log('EVENT::', event.data.event)
          if (event.data.event === 'CONNECT_SUCCESS') {
            Wallet.modal.initPromise?.then(() => setTimeout(() => this.init(), 100))
          } else if (event.data.event === 'MODAL_CLOSE') {
            console.log('event.data.event', this.isWalletLoading, event.data)
            if (this.isWalletLoading) {
              return;
            }

            if (event.data.properties.connected) {
              this.init()
              return
            }

            let isValidNetwork = await Wallet.checkValidNetwork();

            if (!isValidNetwork) {
              Wallet.modal.adapter.disconnect();
              this.$store.commit('wallet/resetState')
              this.$store.commit('presale/resetState');
              return;
            }
            this.$store.commit('wallet/setIsWalletConnected', Wallet.isConnected())
            if (!Wallet.isConnected() || !this.chosenChainId) {
              this.$store.commit('wallet/resetState')
            }
          } else if (event.data.event === 'MODAL_LOADED') {


          } else if (event.data.event === 'SWITCH_NETWORK') {
            console.log(`event.data.event === 'SWITCH_NETWORK'`, event.data.event)
            this.init();
          }
        })

        /**
         * When the network is changed in the wallet directly
         */
        Wallet.modal.subscribeState(newState => {
          console.log('Wallet.modal.subscribeState', newState)
          if (newState.selectedNetworkId && newState.selectedNetworkId !== this.chosenChainId && this.isWalletConnected) {
            console.log("NEEED TO UPDATE THE TOKEN")
            this.$store.dispatch('wallet/updateChosenTokenAndNetwork', {chainId: newState.selectedNetworkId})
          }
        })

        Wallet.modal.subscribeCaipNetworkChange((newState) => {
          console.log('Wallet.modal.subscribeCaipNetworkChange', newState)
        })

        // subscribe to events
      })
  },

  beforeUnmount() {
    clearInterval(this.tickInterval)
    clearInterval(this.listPresaleStagesInterval)
    clearInterval(this.listPresaleContractsInterval)
    clearInterval(this.selffInterval)
  },

  watch: {
    showKYCDialog(newVal) {
      if (newVal) {
        document.body.classList.add('prevent-scroll');
      } else {
        document.body.classList.remove('prevent-scroll');
      }
    },
  }
}
</script>
