const getNulsBalance = (account) => {
  console.log('ACCOUNT' ,account)
  return fetch("https://public1.nuls.io/jsonrp", {
    method: "POST",
    headers: {
      "Content-Type":"application/json",
    },
    body: JSON.stringify({
      "jsonrpc":"2.0",
      "method":"getAccountBalance",
      "params":[1,1,1, account],
      "id": Date.now()
    })
  }).then(res => res.json())

    .then(res => res.error? Promise.reject(res.error): Promise.resolve(BigInt(parseInt(res.result?.totalBalance ))))

}

const getNulsPrice = (priceFeedAddress) => {
  return fetch("https://public1.nuls.io/jsonrp", {
    method: "POST",
    headers: {
      "Content-Type":"application/json",
    },
    body: JSON.stringify({
      "jsonrpc":"2.0",
      "method":"invokeView",
      "params" : [ 1, priceFeedAddress, "getValue", "", [ "NULSUSDT" ] ],
      "id": Date.now().toString()
     })}
    ).then(res => res.json())
    .then(res => res.error? Promise.reject(res.error): Promise.resolve(res.result))
    .then(res => JSON.parse(res.result))
    .then(res => parseFloat(res.value) * 10 ** 8)
    .then(res => BigInt(parseInt(res)))
}

export {
  getNulsBalance,
  getNulsPrice
}
