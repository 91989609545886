<template>
  <div class="benefits-section">

    <div class="main-container-2 spacing">

      <div class="display-s-regular m-heading-m-regular text-highlight">
        Safe Haven for
      </div>

      <div class="display-s-regular m-heading-m-regular">
        Investors and Founders
      </div>

      <sbc-carousel :items="benefitBoxes" :min-width="256" :enable-scroll-bar="true">

        <template #listItem="{item, index}" :key="index">

          <benefit-box
              :benefit-box="item"
          />

        </template>

      </sbc-carousel>

    </div>

  </div>
</template>

<script>
import SbcCarousel from "@/components/general/SbcCarousel.vue";
import benefitBoxes from "@/services/benefitBoxes.js";
import BenefitBox from "@/components/BenefitBox.vue";
import benefitBox from "@/components/BenefitBox.vue";

export default {
  name: "BenefitsSection",
  computed: {
    benefitBox() {
      return benefitBox
    }
  },

  components: {BenefitBox, SbcCarousel},

  data() {
    return {
      benefitBoxes
    }
  }
};
</script>

<style lang='scss'>
@import "BenefitsSection";
</style>