<template>
  <div class="product-section spacing">

    <div class="main-container">

      <info-split-box
          :background="background"
      >
        <template v-slot:content-left>
          <div class="logo-container">
            <img src="/assets/logo/swisspad-logo-1.svg" alt="">
          </div>

          <div class="title display-s-regular m-heading-m-regular"><span class="text-highlight">Simple, Secure,</span>
            Superior
          </div>

          <ul class="item-list body-m-regular m-body-s-regular">
            <li>Modular framework</li>
            <li>Flexible blockchain expansion</li>
            <li>Cross-chain functionality</li>
            <li>Staking, Airdrops, NFTs, Governance and more</li>
            <li>Developed in-house</li>
          </ul>

        </template>
        <template v-slot:content-right>
          <sbc-button
              button-label="COMING SOON"
              :is-primary="true"
              :has-shadow="true"
          />
        </template>
      </info-split-box>

    </div>

  </div>
</template>

<script>
import InfoSplitBox from "@/components/InfoSplitBox.vue";
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: "ProductSection",
  components: {SbcButton, InfoSplitBox},

  data() {
    return {
      background: '/assets/SCR_Launchpad.jpg',
    };
  },
};
</script>

<style lang='scss'>
@import "ProductSection";
</style>