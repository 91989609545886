import Patric from "/assets/team/Patric.jpg"
import Alex from "/assets/team/Alex.jpg"
import Ashutosh from "/assets/team/Ashutosh.jpg"
import Berzeck from "/assets/team/Berzeck.jpg"
import Camran from "/assets/team/Camran.jpg"
import Dado from "/assets/team/Dado.jpg"
import Franco from "/assets/team/Franco.jpg"
import Marco from "/assets/team/Marco.jpg"
import Marco2 from "/assets/team/Marco2.jpg"
import Stew from "/assets/team/Stew.jpg"
import Arnoldas from "/assets/team/Arnoldas.jpg"
import Dani from "/assets/team/Dani.jpg"

import linkedIn from "@/assets/icons/linked-in.svg";
import link from "@/assets/icons/link.svg";


const teamContainer = [
    {
        title: 'Leadership <span class="text-highlight">Team</span>',
        teamMembers: [
            {
                image: Patric,
                name: "Patric Vogel",
                position: "CEO & CO-FOUNDER",
                link: "https://www.linkedin.com/in/patric-vogel-8070471b6/",
                socialMedia: linkedIn,
                workHistory: [
                    "8+ years in blockchain, pioneering industry advancements",
                    "Former VP of Brand Management, NULS Foundation",
                    "Owner of a Swiss based Investment Company",
                    "Enthusiastic leader with proven track record",
                ]
            },
            {
                image: Berzeck,
                name: "Mario Blacutt",
                position: "cto & Board Member",
                link: "https://suisseblockchain.notion.site/Mario-Blacutt-7626a313e63c408c8c465e7b2a440b09",
                socialMedia: link,
                workHistory: [
                    "20+ years in software engineering",
                    "Experience in managing and implementing complex systems",
                    "8+ years experience in blockchain and crypto",
                    "Design and implementation of first micro-services based architecture for a blockchain (NULS and NerveNetwork)",
                    "Design and implementation of ERP",
                ]
            },
            {
                image: Dado,
                name: "Drazenko Ilic",
                position: "CpO",
                link: "https://www.linkedin.com/in/drilic/",
                socialMedia: linkedIn,
                workHistory: [
                    "10+ years of experience as a versatile product leader across diverse roles and markets",
                    "Deep expertise in financial services, telecommunications, and marketplaces",
                    "7 years shaping blockchain technology, investments, and diverse use cases for multiple entities",
                ]
            },
            {
                image: Dani,
                name: "Daniel Kipfer",
                position: "CFO",
                link: "https://www.linkedin.com/in/daniel-kipfer-b1160810/",
                socialMedia: linkedIn,
                workHistory: [
                    "Founding partner of Coin Finance, an accounting company specialized in crypto accounting",
                    "7+ years of experience in blockchain industry, shaping the accounting practices of crypto start ups",
                    "14 years of experience in the banking industry, held leading positions in the CFO area in New York, London and Zurich for one of the world’s leading banks",
                    "In depth knowledge in strategic planning, financial forecasting and reporting",
                    "Proficient accounting background ensuring precision in financial records and streamlined processes",
                ]
            },
        ]
    },

    {
        title: 'Board <span class="text-highlight">of Directors</span>',
        teamMembers: [
            {
                image: Marco2,
                name: "Marco Quinter",
                position: "Co-Founder & Chairman",
                link: "https://www.linkedin.com/in/marco-quinter-b710a71/",
                socialMedia: linkedIn,
                workHistory: [
                    "30 years of track record and experience in building, growing, and leading successful businesses in the IT, telecom, and digital industry",
                    "Extensive experience in strategy and business development, marketing, sales, business transformation, operative management, and leadership",
                    "Built one of the best and highly profitable B2B telecom providers in Switzerland with 200 million revenue, 100 million EBITDA and 80 quarters of continuous growth",
                ]
            },
            {
                image: Alex,
                name: "Alex Fries",
                position: "Co-Founder & Board Member",
                link: "https://www.linkedin.com/in/alexanderfries/",
                socialMedia: linkedIn,
                workHistory: [
                    "Funding Partner of 3 Venture Capital Funds",
                    "Invested in over 100 startups on 4 continents",
                    "Board Member and Advisor to multiple Incubators in Europe and USA",
                    "Board Member to Energy / CleanTech Fund",
                    "Founder and CEO of PURE SWISS Inc",
                    "Nominated 4 years in a row as one of Switzerland's 100 Digital Shapers",
                ]
            },
            {
                image: Franco,
                name: "Franco Quinter",
                position: "Co-Founder & Board Member",
                link: "https://www.linkedin.com/in/franco-quinter-38076964/",
                socialMedia: linkedIn,
                workHistory: [
                    "28 years as Founder, Owner and Director of 20+ companies",
                    "25 years as Founder, Owner, Advisor, Board Member of countless international startups",
                    "28 years as Head of one of the largest Swiss Engineering Groups",
                    "14 years as BOD at Swiss Bank",
                    "Initiator, Founder, Owner and Developer of InnoQube Swiss",
                ]
            },
        ]
    },

    {
        title: 'Advisors',
        teamMembers: [
            {
                image: Camran,
                name: "Camran Macci",
                position: "vc",
                link: "https://www.linkedin.com/in/camranmacci/",
                socialMedia: linkedIn,
                hideButton: true,
            },
            {
                image: Ashutosh,
                name: "Ashutosh Singh",
                position: "strategy",
                link: "https://www.linkedin.com/in/ashutosh-singh-isb/",
                socialMedia: linkedIn,
                hideButton: true,
            },
            {
                image: Stew,
                name: "Stew Langille",
                position: "marketing",
                link: "https://www.linkedin.com/in/stewlangille/",
                socialMedia: linkedIn,
                hideButton: true,
            },
            {
                image: Arnoldas,

                name: "Arnoldas Jonaitis",
                position: "Strategy",
                link: "https://www.linkedin.com/in/arnoldasjonaitis/",
                socialMedia: linkedIn,
                hideButton: true,
            },
        ]
    },
];

export {
    teamContainer
};
