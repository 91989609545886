<template>
  <div class="user-total">

    <div class="top-bar">

      <address-box
          :text="text"
          class="title"
      />

      <disconnect-wallet
          class="title"
      />

    </div>

    <div class="boxes-container">

      <total-box2
          class="total-box-1"
          title="Total $SUISSE Holdings"
          :amount="$formatAmount(self?.totalCoins, 0)"
      />

      <total-box2
          title="Total Rocket Fuel"
          :isRocket="true"
          :amount="$formatAmount(self?.points, 0)"
      />

    </div>

  </div>
</template>

<script>
import TotalBox2 from "@/components/hero-components/TotalBox2.vue";
import KYCBox from "@/components/hero-components/KYCBox.vue";
import AddressBox from "./AddressBox.vue";
import DisconnectWallet from "@/components/hero-components/DisconnectWallet.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "UserTotal",
  components: {DisconnectWallet, AddressBox, KYCBox, TotalBox2},
  props: {
    text: String,
  },
  computed: {
    ...mapState({
      self: state => state.presale.self,
      currentAddress: state => state.wallet.currentAddress,
    }),
  },
};
</script>

<style lang='scss'>
@import "UserTotal";
</style>
