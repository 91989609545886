<template>
  <div class="stage-price-bar">

    <div class="top-bar">

      <div class="value-container ">
        <div class="caps-s-regular m-caps-s-regular">RAISED</div>
        <div class="heading-s-regular text-blue m-heading-s-regular">
          {{
            $formatPrice(activePresaleStage?.totalPurchased * $formatUnits(activePresaleStage?.price.toString(), 8), 0)
          }}
        </div>
      </div>

      <div class="value-container content-right">
        <div class="caps-s-regular m-caps-s-regular">FROM</div>
        <div class="heading-s-regular text-white m-heading-s-regular">{{
            $formatPrice(activePresaleStage?.total * $formatUnits(activePresaleStage?.price, 8), 0)
          }}
        </div>
      </div>

    </div>

    <div class="rectangle">
      <div class="bar" :style="{'left' : barPercentage}">{{ barPercentage }}</div>
      <div class="bar-blue" :style="{'width' : barPercentageRight}"></div>
    </div>

    <div class="bottom-bar">
      <div class="value-container bottom-container">
        <div class="caps-s-regular m-caps-s-regular">TOKEN PRICE</div>
        <div class="tooltip-wrapper">

          <div class="body-s-regular text-white m-body-s-regular">{{
              $formatPrice($formatUnits(activePresaleStage?.price.toString(), 8), 2)
            }}
          </div>

          <div class="icon-container show-mobile">
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M9 1C6.46875 1 4.1875 2.34375 2.9375 4.5C1.65625 6.6875 1.65625 9.34375 2.9375 11.5C4.1875 13.6875 6.46875 15 9 15C11.5 15 13.7812 13.6875 15.0312 11.5C16.3125 9.34375 16.3125 6.6875 15.0312 4.5C13.7812 2.34375 11.5 1 9 1ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.5 11H8.5V8H7.75C7.46875 8 7.25 7.78125 7.25 7.5C7.25 7.25 7.46875 7 7.75 7H9C9.25 7 9.5 7.25 9.5 7.5V11H10.5C10.75 11 11 11.25 11 11.5C11 11.7812 10.75 12 10.5 12H7.5C7.21875 12 7 11.7812 7 11.5C7 11.25 7.21875 11 7.5 11ZM9 5.75C8.5625 5.75 8.25 5.4375 8.25 5C8.25 4.59375 8.5625 4.25 9 4.25C9.40625 4.25 9.75 4.59375 9.75 5C9.75 5.4375 9.40625 5.75 9 5.75Z"
                  fill="#00BDD6"/>
            </svg>
            <sbc-tooltip
                label="Listing price: $0.3"
            />
          </div>

        </div>
      </div>

      <div class="value-container bottom-container middle hide-mobile">
        <div class="caps-s-regular m-caps-s-regular">listing Price</div>
        <div class="body-s-regular text-white m-body-s-regular">$0.3
        </div>
      </div>

      <div class="value-container bottom-container">
        <div class="caps-s-regular m-caps-s-regular">Purchase min.</div>
        <div class="limits text-white m-body-s-regular">
          <div>{{ minLimitInDollars }}</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {formatUnits} from "ethers";
import SbcTooltip from "@/components/SbcTooltip.vue";

export default {
  name: "StagePriceBar",
  components: {SbcTooltip},
  props: {
    valueRaised: String,
    valueFrom: String,
    valueCurrent: String,
    valueNextStage: String,
  },

  computed: {
    ...mapGetters('presale', ["getActivePresaleStage"]),
    ...mapGetters('presale', ["getNextStage", "getMaxPerStage", "getMinPerStage"]),

    activePresaleStage() {
      return this.getActivePresaleStage;
    },
    nextStage() {
      return this.getNextStage;
    },
    barPercentage() {
      return `${Math.floor((this.activePresaleStage?.totalPurchased / this.activePresaleStage?.total) * 100)}%`
    },
    minLimitInDollars() {
      console.log('this.getMinPerStage', this.getMinPerStage)
      return this.$formatPrice(this.getMinPerStage, 0)
    },
    maxLimitInDollars() {
      return this.$formatPrice(this.getMaxPerStage, 0)
    },
    barPercentageRight() {
      const percentageValue = parseFloat(this.barPercentage);
      return (100 - percentageValue) + '%';
    }
  }
};
</script>

<style lang='scss'>
@import "StagePriceBar";
</style>
