import {apolloClient} from "../boot/apollo";
import LIST_PRESALE_STAGES from "./listPresaleStages.gql"
import LIST_PRESALE_CONTRACTS from "./listPresaleContracts.gql"
import LIST_PRESALE_TRANSACTIONS from "./listPresaleTransactions.gql"
import CREATE_PRESALE_TRANSACTION from "./createPresaleTransaction.gql"
import CREATE_USDC_PRESALE_TRANSACTION from "./createUSDCPresaleTransaction.gql"
import UPDATE_SUMSUB_KYC_ACCESS_TOKEN from "./updateSumsubKYCAccesToken.gql"
import GET_NONCE from "./getNonce.gql"
import GET_SELF_PRESALE from "./getSelfPresale.gql"
import WALLET_AUTHENTICATE from "./walletAuthenticate.gql"
import LOG_ERROR from "./logError.gql"
import UNSUBSCRIBE_FROM_LISTS from "./unsubscribeFromLists.js"
import GET_NOTIFICATION_RECIPIENT from "./getNotificationRecipient.js"

const createPresaleTransaction = ({transactionHash, contractId, currency, isNabox}) => {
  return apolloClient.mutate({
    mutation: CREATE_PRESALE_TRANSACTION, variables: {
      input: {
        transactionHash, contractId, currency, isNabox
      }
    }, fetchPolicy: "no-cache"
  }).then(({data: {presaleTransaction}}) => presaleTransaction)
}

const createUSDCPresaleTransaction = ({transactionHash, value, contractId, currency, isNabox}) => {
  return apolloClient.mutate({
    mutation: CREATE_USDC_PRESALE_TRANSACTION, variables: {
      input: {
        transactionHash, contractId, currency, isNabox, value,
      }
    }, fetchPolicy: "no-cache"
  }).then(({data: {presaleTransaction}}) => presaleTransaction)
}

const listPresaleStages = () => {
  return apolloClient.query({
    query: LIST_PRESALE_STAGES, fetchPolicy: "no-cache"
  }).then(({data: {presaleStages}}) => presaleStages)
};
const listPresaleContracts = (input) => {
  return apolloClient.query({
    query: LIST_PRESALE_CONTRACTS, variables: {
      input
    }, fetchPolicy: "no-cache"
  }).then(({data: {contracts}}) => contracts)
};

const listPresaleTransactions = ({pagination, filter}) => {
  return apolloClient.query({
    query: LIST_PRESALE_TRANSACTIONS, fetchPolicy: "no-cache", variables: {
      input: {pagination, filter},
    }
  }).then(({data: {transactions}}) => transactions)
};

const getNonce = () => {
  return apolloClient.query({
    query: GET_NONCE, fetchPolicy: "no-cache"
  }).then(({data: {nonce}}) => nonce)
};

const selfPresale = (addr) => {
  return apolloClient.query({
    query: GET_SELF_PRESALE,
    variables: {
      addr
    },
    fetchPolicy: "no-cache"
  })
    .then(({data: {self}}) => self)

}

const walletAuthenticate = ({
                              message, signature, preSignedToken, referralCode, address, isNuls, pubKey, whitelistCode
                            }) => {
  return apolloClient.mutate({
    mutation: WALLET_AUTHENTICATE, variables: {
      input: {
        message, signature, preSignedToken, referralCode, address, isNuls, pubKey, whitelistCode
      }
    }, fetchPolicy: "no-cache"
  }).then(({data: {auth}}) => auth)
}

const updateSumsubKycAccessToken = ({accountId}) => {
  return apolloClient.mutate({
    mutation: UPDATE_SUMSUB_KYC_ACCESS_TOKEN, variables: {
      input: {
        accountId
      }
    }, fetchPolicy: "no-cache"
  }).then(({data: {kycAccessToken}}) => kycAccessToken)
}

const logError = (error) => {
  return apolloClient.mutate({
    mutation: LOG_ERROR, variables: {
      error
    }, fetchPolicy: "no-cache"
  }).then(({data: {error}}) => error)
}

const unsubscribeFromLists = ({listIds, token}) => apolloClient
  .mutate({
    mutation: UNSUBSCRIBE_FROM_LISTS, variables: {
      input: {
        token, lists: listIds.map(listId => ({id: listId}))
      }
    }, fetchPolicy: 'no-cache'
  }).then(({data: {unsubscribed}}) => unsubscribed);


const getNotificationRecipient = (token, {includeLists = false}) => apolloClient
  .query({
    query: GET_NOTIFICATION_RECIPIENT, variables: {
      token, includeLists
    }, fetchPolicy: 'no-cache'
  }).then(({data: {notificationRecipient}}) => notificationRecipient);

export {
  createPresaleTransaction,
  createUSDCPresaleTransaction,
  listPresaleStages,
  listPresaleContracts,
  getNonce,
  walletAuthenticate,
  listPresaleTransactions,
  selfPresale,
  updateSumsubKycAccessToken,
  logError,
  unsubscribeFromLists,
  getNotificationRecipient
}
