const defaultState = () => ({
  showUserDashboard: false
})

export default {
  namespaced: true, actions: {
    showUserDashboard({commit}, showUserDashboard) {
      commit('setShowUserDashboard', showUserDashboard)
    }
  }, mutations: {
    setShowUserDashboard(state, showUserDashboard) {
      state.showUserDashboard = showUserDashboard;
    }, resetState(state,) {
      Object.assign(state, defaultState(),)
    }
  }, state: defaultState()
}
