<template>

  <div class="total-box-2" :class="{'is-rocket' : isRocket}">

    <div class="content-container">

      <div class="info-wrapper">
        <div class="caps-s-regular m-caps-s-regular">{{ title }}</div>

        <div class="amount-container">

          <svg v-if="!isRocket" width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.26009 15.6486V14.7402H7.58188C7.1585 14.7402 6.81209 14.3938 6.81209 13.9699V2.68501C6.81209 1.20802 8.02009 0 9.49705 0H14.5043C15.9808 0 17.1888 1.20849 17.1888 2.68501V5.28469C17.1888 5.70808 16.8424 6.05495 16.4191 6.05495C15.9952 6.05495 15.6488 5.70854 15.6488 5.28469V2.68501C15.6488 2.0548 15.1336 1.53959 14.5039 1.53959H9.49659C8.86685 1.53959 8.35166 2.05526 8.35166 2.68501V9.25981H9.25963V7.58156C9.25963 7.15771 9.60603 6.81176 10.0299 6.81176H18.9593H21.315C22.7915 6.81176 24 8.02025 24 9.49677V14.5042C24 15.9807 22.7915 17.1892 21.315 17.1892H18.7154C18.292 17.1892 17.9452 16.8428 17.9452 16.4194C17.9452 15.996 18.292 15.6491 18.7154 15.6491H21.315C21.9448 15.6491 22.46 15.1339 22.46 14.5042V9.49677C22.46 8.86703 21.9448 8.35135 21.315 8.35135H14.7399V9.25981H16.4181C16.842 9.25981 17.1884 9.60621 17.1884 10.0296V21.315C17.1884 22.792 15.9804 24 14.5039 24H9.49659C8.01963 24 6.81163 22.792 6.81163 21.315V18.7153C6.81163 18.2915 7.15803 17.945 7.58188 17.945C8.00526 17.945 8.35166 18.2915 8.35166 18.7153V21.315C8.35166 21.9447 8.86685 22.4604 9.49659 22.4604H14.5039C15.1331 22.4604 15.6488 21.9443 15.6488 21.315V14.7402H14.7404V16.4184C14.7404 16.8418 14.394 17.1882 13.9706 17.1882H5.04067H2.68496C1.20846 17.1882 0 15.9802 0 14.5032V9.49585C0 8.01886 1.208 6.81084 2.68496 6.81084H5.28459C5.70797 6.81084 6.05437 7.15724 6.05437 7.5811C6.05437 8.00448 5.70797 8.35089 5.28459 8.35089H2.68496C2.05522 8.35089 1.54002 8.8661 1.54002 9.49631V14.5037C1.54002 15.1334 2.05522 15.6486 2.68496 15.6486H9.25963H9.26009ZM15.6488 13.2006V10.7999H13.2008V8.35135H10.8001V10.7999H8.35212V13.2006H10.8001V15.6486H13.2008V13.2006H15.6488Z"
                  fill="white"/>
          </svg>

          <svg v-else width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.8125 18.4375C11.8281 17.5938 16.3281 15.625 18.6719 14.0781C23.5469 10.75 23.8281 5.82812 23.2656 2.73438C20.1719 2.17188 15.25 2.45312 11.9219 7.32812C10.375 9.625 8.35938 14.0781 7.46875 16.0938L9.8125 18.4375ZM6.10938 15.5312C6.20312 15.25 6.34375 14.875 6.53125 14.5H1.75C1.46875 14.5 1.23438 14.4062 1.09375 14.1719C0.953125 13.9375 0.953125 13.6562 1.04688 13.4219L3.4375 8.6875C3.95312 7.65625 4.98438 7 6.10938 7H10.3281C10.4688 6.8125 10.5625 6.67188 10.7031 6.48438C14.5469 0.8125 20.2656 0.625 23.6406 1.28125C24.2031 1.375 24.625 1.79688 24.7188 2.35938C25.375 5.73438 25.1875 11.4531 19.5156 15.2969C19.3281 15.4375 19.1875 15.5312 19 15.6719V19.8906C19 21.0156 18.3438 22.0469 17.3125 22.5625L12.5781 24.9531C12.3438 25.0469 12.0625 25.0469 11.8281 24.9062C11.5938 24.7656 11.5 24.5312 11.5 24.25L11.4531 19.375C11.0781 19.5625 10.7031 19.7031 10.375 19.8438C9.85938 20.0781 9.20312 19.9375 8.78125 19.5156L6.39062 17.1719C5.96875 16.7031 5.82812 16.0938 6.10938 15.5312ZM13 23.0781L16.6562 21.2031C17.1719 20.9688 17.5 20.4531 17.5 19.8906V16.5156C16.0469 17.3125 14.4062 18.0625 13 18.7188V23.0781ZM6.10938 8.5C5.54688 8.5 5.03125 8.82812 4.79688 9.34375L2.92188 13H7.23438C7.89062 11.5938 8.6875 9.95312 9.48438 8.5H6.10938ZM19.375 7.75C19.375 7.14062 18.8594 6.625 18.25 6.625C17.5938 6.625 17.125 7.14062 17.125 7.75C17.125 8.40625 17.5938 8.875 18.25 8.875C18.8594 8.875 19.375 8.40625 19.375 7.75ZM18.25 5.125C19.1875 5.125 20.0312 5.64062 20.5 6.4375C20.9688 7.28125 20.9688 8.26562 20.5 9.0625C20.0312 9.90625 19.1875 10.375 18.25 10.375C17.2656 10.375 16.4219 9.90625 15.9531 9.0625C15.4844 8.26562 15.4844 7.28125 15.9531 6.4375C16.4219 5.64062 17.2656 5.125 18.25 5.125Z"
              fill="#00BDD6"/>
          </svg>

          <div class="amount heading-m-regular m-heading-m-regular">{{ amount || "0" }}</div>

        </div>
      </div>

      <!--      <div v-if="!isRocket" class="price body-s-regular">${{ price || "0" }}</div>-->

      <!--      <tooltip v-if="!isRocket" placement="right"-->
      <!--               title="Token claim will be available after TGE. For more details, visit our Docs section."-->
      <!--               class="button-tooltip">-->
      <!--        <sbc-button-->
      <!--          class="box-button"-->
      <!--          button-label="Claim"-->
      <!--          icon-type="light"-->
      <!--          icon-right="fa-circle-info"-->
      <!--          :button-small="true"-->
      <!--          :is-dark-gray="true"-->
      <!--        />-->
      <!--      </tooltip>-->


      <div v-if="!isRocket" class="button-container">
        <sbc-button
          class="box-button has-tooltip"
          button-label="Claim"
          icon-type="light"
          icon-right="fa-circle-info"
          :button-small="true"
          :is-dark-gray="true"
        />
        <sbc-tooltip
          label="Token claim will be available after TGE. For more details, visit our Docs section."
          :class="{'tooltip-bottom': $isMobile}"
        />
      </div>

      <sbc-button
        v-else
        class=" box-button referral"
        button-label="Referral Link"
        icon-type="light"
        icon-right="fa-copy"
        :button-small="true"
        :is-referral="true"
        icon-color="white"
        @click="copyReferralLink"
      />

    </div>

  </div>

</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";
import SbcIcon from "@/components/general/SbcIcon.vue";
import {mapState} from 'vuex';
import {Tooltip} from '@programic/vue3-tooltip'
import SbcTooltip from "@/components/SbcTooltip.vue";

export default {
  name: "TotalBox2",
  components: {SbcTooltip, SbcIcon, SbcButton, Tooltip},
  props: {
    isRocket: Boolean,
    title: String,
    amount: String,
    price: String,
  },
  computed: {
    ...mapState({
      self: state => state.presale.self
    })
  },

  methods: {
    async copyReferralLink() {
      const link = window.location.host + `?referral=` + this.self.referralCode
      console.log('link', link);
      try {
        // Use the Clipboard API to write the link to the clipboard
        await navigator.clipboard.writeText(link);
        alert('Link copied to clipboard: ' + link);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },

  }

};

</script>

<style lang='scss'>
@import "TotalBox2";
</style>
