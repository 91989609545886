<template>
  <div class="divider-text">

    <div class="title">{{ title }}</div>
    <div v-if="hasLimit" class="stage-limit">
      <div>|</div>
      <div>Stage Limit:</div>
      <div class="values">
        <div>{{ minLimitInDollars }}</div>
        <div>-</div>
        <div>{{ maxLimitInDollars }}</div>
      </div>
      <div>per User</div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {formatUnits, parseUnits} from "ethers";

export default {
  name: "DividerText",
  props: {
    title: String,
    hasLimit: {
      type: Boolean,
      required: false,
    }
  },

  // :min-limit="$formatPrice($formatUnits(activePresaleStage?.minPerUser?.toString(), 8))"
  //:max-limit="$formatPrice($formatUnits(activePresaleStage?.maxPerUser.toString(), 8))"
  //

  computed: {
    ...mapGetters('presale', ["getActivePresaleStage"]),
    ...mapGetters('presale', ['currentStateAmountSuisse']),
    activePresaleStage() {
      return this.getActivePresaleStage;
    },
    minLimitInDollars() {
      return this.$formatPrice(this.activePresaleStage.minPerUser * this.activePresaleStage?.price / 10**8)
    },
    maxLimitInDollars() {
      return this.$formatPrice((this.activePresaleStage.maxPerUser  - this.currentStateAmountSuisse) * this.activePresaleStage?.price / 10**8)
    },

  },
};
</script>

<style lang='scss'>
@import "DividerText";
</style>
