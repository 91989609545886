<template>
  <div class="pay-box" id="pay-box">

    <div class="top-bar">

      <div class="title">
        <div class="body-s-regular m-body-s-regular">You <span class="text-white m-body-s-regular">Pay</span></div>
      </div>

      <div class="title">
        <div class="body-s-regular m-body-s-regular">{{ activePresaleContract?.name }}</div>
        <div class="network-icon">
          <img :src="chainIcons[activePresaleContract?.chainId]" alt="">
        </div>
      </div>

    </div>

    <div class="wrapper">

      <div class="left-content">

        <div class="values-container">

          <div class="value1 heading-s-regular ">
            <span v-if="isWalletConnected && presaleTransaction.errors['max_reached']">Max Reached</span>
            <input
              v-else
              placeholder="Enter amount"
              type="number"
              lang="en-US"
              :value="presaleTransaction.amountOfTokensToPay" class="amount m-heading-s-regular"
              @input="setAmount"
            />

          </div>

          <div class="value2 body-s-regular m-body-s-regular">
            <div v-if="isWalletConnected && presaleTransaction.errors['max_reached']">0</div>
            <div v-else> {{ $formatPrice(presaleTransaction.amountInDollars / 10 ** 8, 2) }}</div>
          </div>

        </div>

        <div class="incremental-buttons">

          <button class="incremental-button" @click="setMax"
                  :class="{'disabled' :this.presaleTransaction.errors['max_reached']}">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.75781 0.367188L9.88281 4.49219C10.0234 4.63281 10.0234 4.89063 9.88281 5.03125C9.74219 5.17188 9.48438 5.17188 9.34375 5.03125L5.875 1.53906L5.875 10.375C5.875 10.5859 5.6875 10.75 5.5 10.75C5.28906 10.75 5.125 10.5859 5.125 10.375L5.125 1.53906L1.63281 5.03125C1.49219 5.17188 1.23437 5.17188 1.09375 5.03125C0.953125 4.89062 0.953125 4.63281 1.09375 4.49219L5.21875 0.367188C5.35938 0.226563 5.61719 0.226563 5.75781 0.367188Z"
                fill="#414548"/>
            </svg>
            <span class="caps-s-regular m-caps-s-regular">MAX</span>
          </button>

          <button class="incremental-button down" @click="setMin"
                  :class="{'disabled' :this.presaleTransaction.errors['max_reached']}">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.75781 0.367188L9.88281 4.49219C10.0234 4.63281 10.0234 4.89063 9.88281 5.03125C9.74219 5.17188 9.48438 5.17188 9.34375 5.03125L5.875 1.53906L5.875 10.375C5.875 10.5859 5.6875 10.75 5.5 10.75C5.28906 10.75 5.125 10.5859 5.125 10.375L5.125 1.53906L1.63281 5.03125C1.49219 5.17188 1.23437 5.17188 1.09375 5.03125C0.953125 4.89062 0.953125 4.63281 1.09375 4.49219L5.21875 0.367188C5.35938 0.226563 5.61719 0.226563 5.75781 0.367188Z"
                fill="#414548"/>
            </svg>
            <span class="caps-s-regular m-caps-s-regular">MIN</span>
          </button>

        </div>

      </div>

      <div class="right-content">

        <currency-dropdown
          :key="activePresaleContract?.chainId"
          @openPaymentDialog="$emit('openPaymentDialog')"
        />

      </div>

    </div>

    <div class="warning-message caption-l-regular m-caption-l-regular"
         v-if="isWalletConnected && presaleTransaction.errors['no_balance']"
         style="color:#D7001D; text-align: center">
      You don't have enough funds in your wallet.
    </div>

    <div class="warning-message caption-l-regular m-caption-l-regular"
         v-else-if="isWalletConnected && presaleTransaction.errors['max_reached']"
         style="color:#D7001D; text-align: center">
      You've reached the maximum token limit for this stage.
    </div>

    <div class="warning-message caption-l-regular m-caption-l-regular"
         v-else-if="isWalletConnected && presaleTransaction.errors['err_mix_max']"
         style="color:#D7001D; text-align: center">
      The amount you want to purchase needs to be between {{ $formatTokenAmount(minPerUser, chosenToken?.currency) }}
      {{ chosenToken?.currency }} and
      {{ $formatAmount(maxPerUser, 4) }} {{ chosenToken?.currency }}.
    </div>


    <div class="warning-message caption-l-regular m-caption-l-regular"
         v-else-if="isWalletConnected && presaleTransaction.errors['no_amount'] && isDirty"
         style="color:#D7001D; text-align: center">
      Please enter a valid token amount.
    </div>

    <div class="warning-message caption-l-regular m-caption-l-regular"
         v-for="message in presaleTransaction.errorMessages"
         v-else-if="isWalletConnected"
         style="color:#D7001D; text-align: center">
      {{ message }}
    </div>

  </div>

</template>

<script>
import CurrencyDropdown from "@/components/hero-components/CurrencyDropdown.vue";
import {mapGetters, mapState} from "vuex";
import {formatUnits} from "ethers";
import {chainIcons} from "@/services/chainAndTokenIcons.js";

export default {
  name: "PayBox",
  components: {CurrencyDropdown},
  emits: ['openPaymentDialog'],
  props: {
    modelValue: {},
    hasDropdown: Boolean,
  },

  data() {
    return {
      hasErr: false,
      chainIcons,
    }
  },

  computed: {
    ...mapState({
      presaleTransaction: state => state.presale.presaleTransaction,
      isDirty: state => state.presale.isDirty,
      isWalletConnected: state => state.wallet.isWalletConnected,
      chosenToken: state => state.wallet.chosenToken,
    }),
    ...mapGetters('presale', ["getActiveContract", 'getMinPerUser', 'getMaxPerUser']),

    activePresaleContract() {
      return this.getActiveContract
    },
    minPerUser() {
      return this.getMinPerUser;
    },
    maxPerUser() {//toke
      return this.getMaxPerUser
    },
  },

  methods: {
    setMax() {
      if (this.presaleTransaction.errors['max_reached']) return;
      let maxPerUser = Math.floor(this.maxPerUser * 10 ** 4) / 10 ** 4;
      if(maxPerUser < 0) {
        maxPerUser = 0
      }
      this.$store.commit('presale/setIsDirty', true)
      console.log('maxPerUser', maxPerUser)
      this.$store.dispatch('presale/updatePresaleTransaction', maxPerUser)
    },
    setMin() {
      if (this.presaleTransaction.errors['max_reached']) return;
      let minPerUser = Math.ceil(this.minPerUser * 10 ** 4) / 10 ** 4;
      this.$store.commit('presale/setIsDirty', true)
      this.$store.dispatch('presale/updatePresaleTransaction', minPerUser)
    },
    setAmount(e) {
      this.$store.commit('presale/setIsDirty', true)
      let value = e.target.value;
      if(value < 0) {
        value = 0;
      }
      this.$store.dispatch('presale/updatePresaleTransaction', value)
    }
  },

};
</script>

<style lang='scss'>
@import "PayBox";
</style>
