<template>
  <div v-in-viewport-trigger="startTransition" ref="container" class="tokenomic-statistic">

    <div class="token-count">
      <div class="token-count-value body-s-regular m-caption-l-regular">{{ statistic.value }}</div>
    </div>

    <div class="title caption-l-regular m-caption-s-regular">{{ statistic.title }}</div>

    <div class="token-title-bar"
         :style="{width: statistic.barWidth, transform: currentTransform, transitionDuration: `${transitionDuration}s`}"
    >
    </div>
    <div class="percent caption-s-medium m-caps-s-medium"
         :style="{left: currentLeft, transitionDuration: `${transitionDuration}s`}">
      {{ currentPercentage }}%
    </div>

  </div>
</template>

<script>
import inViewportTrigger from "@/directives/inViewportTrigger.js";

export default {
  name: "TokenomicStatistic",
  directives: {
    inViewportTrigger
  },
  props: {
    statistic: Object,
    width: String
  },

  data: () => ({
    currentPercentage: 0,
    numIterations: 0,
    currentIteration: 0,
    progress: 0,
    interval: null,
    transitionDuration: 0.4,
    currentTransform: `translateX(-100%)`,
    currentLeft: 0,
    isMobile: window.innerWidth < 768
  }),
  computed: {
    barWidth() {
      return this.width;
    }
  },

  methods: {
    startTransition() {
      let percentage = parseInt(this.statistic.percentLabel)
      this.numIterations = percentage;
      this.progress = (percentage / 25) * 100
      this.currentTransform = 'translateX(0)'
      if (this.isMobile) {
        this.currentLeft = `calc(60% + 16px)`
      } else {
        this.currentLeft = `calc(${this.statistic.barWidth} + 16px)`
      }

      let msStep = Math.round((this.transitionDuration * 1000) / this.numIterations);

      this.interval = setInterval(() => {
        if (this.currentIteration < this.numIterations) {
          this.currentPercentage++;
          this.currentIteration++;
        } else {
          clearInterval(this.interval)
        }
      }, msStep)

    }
  },

  created() {
    this.transitionDuration = parseInt(this.statistic.barWidth) * 0.02
  }
};
</script>

<style lang='scss'>
@import "TokenomicStatistic";
</style>