<template>
  <slot
      :days="countdown.days"
      :minutes="countdown.minutes"
      :seconds="countdown.seconds"
      :hours="countdown.hours"
  />
</template>

<script>
import {mapState} from "vuex";

function countdown(s) {

  const d = Math.floor(s / (3600 * 24));
  s -= d * 3600 * 24;

  const h = Math.floor(s / 3600);
  s -= h * 3600;

  const m = Math.floor(s / 60);
  s -= m * 60;

  const tmp = {
    hours: 0,
    days: 0,
    minutes: 0,
    seconds: 0
  };


  (d) && (tmp.days = d);

  (d || h) && (tmp.hours = h);

  (d || h || m) && (tmp.minutes = m);

  tmp.seconds = s

  return tmp
}


export default {
  name: 'CountdownTimer',
  props: {
    dateTime: [String, Number]
  },
  computed: {
    ...mapState({
      currentTime: state => state.currentTime
    }),

    countdown() {
      let remainingTime = this.dateTime - this.currentTime;
      if (remainingTime > 0) {
        return countdown(Math.round(remainingTime / 1000))
      }

      return {
        hours: 0,
        days: 0,
        minutes: 0,
        seconds: 0
      }
    }
  }
}
</script>

<style lang="scss">
//@import "CountdownTimer.scss";
</style>
