<template>
  <div class="support-box">

    <div class="bg-blur"></div>

    <div class="label caption-s-regular m-caption-s-regular">{{ label }}</div>

    <div class="logo-wrapper" :class="{'gap' : logo2}">

      <a :href="link" target="_blank" class="logo">
        <img :src="logo" alt="">
      </a>

      <a :href="link2" target="_blank" v-if="logo2" class="logo">
        <img :src="logo2" alt="">
      </a>
    </div>

  </div>
</template>

<script>
export default {
  name: "SupportBox",
  props: {
    label: String,
    logo: String,
    logo2: String,
    link: String,
    link2: String,
  }
}
;
</script>

<style lang='scss'>
@import "SupportBox";
</style>
