<template>
  <div class="ongoing-stage">

    <div class="header">

      <div class="stage-number">

        <div class="heading-s-regular text-white m-heading-s-regular">Stage</div>

        <div class="stage">
          <div class="current-stage heading-s-regular text-white m-heading-s-regular">{{
              activePresaleStage?.number
            }}
          </div>
          <div v-if="!isPaused" class="text-blue heading-s-regular m-heading-s-regular">Ongoing</div>
          <div v-else class="paused heading-s-regular m-heading-s-regular">Paused</div>
        </div>

      </div>

      <time-countdown/>

    </div>

    <stage-price-bar/>

    <div class="total-boxes">

      <total-box
        class="hide-mobile"
        title="TOTAL AMOUNT RAISED"
        :main-value="$formatPrice($formatUnits(totalRaised.toString(), 8), 0)"
      />

      <total-box
        class="hide-mobile"
        title="TOTAL TOKENS SOLD"
        :main-value="$formatAmount(totalTokensSold, 0)"
        :is-tokens="true"
      />

      <total-box
        class="show-mobile"
        title="TOTAL <br>AMOUNT RAISED"
        :main-value="$formatPrice($formatUnits(totalRaised.toString(), 8), 0)"
      />

      <total-box
        class="show-mobile"
        title="TOTAL <br>TOKENS SOLD"
        :main-value="$formatAmount(totalTokensSold, 0)"
        :is-tokens="true"
      />

    </div>

  </div>
</template>

<script>
import StagePriceBar from "@/components/hero-components/StagePriceBar.vue";
import TotalBox from "@/components/hero-components/TotalBox.vue";
import TimeCountdown from "@/components/hero-components/TimeCountdown.vue";
import {mapGetters} from "vuex";
import wallet from "@/store/wallet.js";

export default {
  name: "OngoingStage",
  components: {TimeCountdown, TotalBox, StagePriceBar},

  data() {
    return {
      isPaused: false,
    };
  },

  computed: {
    wallet() {
      return wallet
    },
    ...mapGetters('presale', ["getTotalRaised"]),
    ...mapGetters('presale', ["getTotalTokensSold"]),
    ...mapGetters('presale', ["getTotalTokensInAllStages"]),
    ...mapGetters('presale', ["hasTransactionErrors"]),
    totalRaised() {
      return this.getTotalRaised;
    },
    totalTokensSold() {
      return this.getTotalTokensSold;
    },
    totalTokensInAllStages() {
      return this.getTotalTokensInAllStages
    },


    ...mapGetters('presale', ["getActivePresaleStage"]),
    activePresaleStage() {
      return this.getActivePresaleStage
    }
  }
};
</script>

<style lang='scss'>
@import "OngoingStage";
</style>
