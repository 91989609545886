<template>
  <div class="disconnect-wallet" @click="disconnectWallet">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M21.75 0.5H3.75C1.64062 0.5 0 2.1875 0 4.25V17.75C0 19.8594 1.64062 21.5 3.75 21.5H20.25C22.3125 21.5 24 19.8594 24 17.75V7.25C24 5.1875 22.3125 3.5 20.25 3.5H5.25C4.82812 3.5 4.5 3.875 4.5 4.25C4.5 4.67188 4.82812 5 5.25 5H20.25C21.4688 5 22.5 6.03125 22.5 7.25V17.75C22.5 19.0156 21.4688 20 20.25 20H3.75C2.48438 20 1.5 19.0156 1.5 17.75V4.25C1.5 3.03125 2.48438 2 3.75 2H21.75C22.125 2 22.5 1.67188 22.5 1.25C22.5 0.875 22.125 0.5 21.75 0.5ZM16.8647 12.49L19.8444 9.05421C20.0736 8.77292 20.0527 8.37108 19.761 8.15007C19.4693 7.92905 19.0526 7.94914 18.8234 8.23043L16.0104 11.4853L13.1766 8.23043C12.9474 7.94914 12.5307 7.92905 12.239 8.15007C11.9473 8.37108 11.9264 8.77292 12.1556 9.05421L15.1353 12.49L12.1556 15.9458C11.9264 16.2271 11.9473 16.6289 12.239 16.8499C12.5307 17.0709 12.9474 17.0509 13.1766 16.7696L16.0104 13.4946L18.8234 16.7696C19.0526 17.0509 19.4693 17.0709 19.761 16.8499C20.0527 16.6289 20.0736 16.2271 19.8444 15.9458L16.8647 12.49Z"
            fill="white"/>
    </svg>
  </div>
</template>

<script>
import {formatUnits} from "ethers";
import {Wallet} from "@/services/wallet.js";

export default {
  name: "DisconnectWallet",


  methods: {
    disconnectWallet() {
      Wallet.modal.adapter.disconnect();
      this.$store.commit('wallet/resetState');
      this.$store.commit('presale/resetState');
      this.$store.commit('general/resetState');
    }
  }
};
</script>

<style lang='scss'>
@import "DisconnectWallet";
</style>
