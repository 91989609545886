<template>
  <div class="connect-wallet-init">

    <div class="top-content">

      <div class="content">

        <div class="number">
          <div class="circle body-s-medium">1</div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="48" viewBox="0 0 56 48" fill="none">
          <path
            d="M7.875 0H51.625C52.0625 0 52.5 0.428571 52.5 0.857143C52.5 1.39286 52.0625 1.71429 51.625 1.71429H7.875C4.48438 1.71429 1.75 4.5 1.75 7.71429V40.2857C1.75 43.6071 4.48438 46.2857 7.875 46.2857H48.125C51.4062 46.2857 54.25 43.6071 54.25 40.2857V16.2857C54.25 13.0714 51.4062 10.2857 48.125 10.2857H7.875C7.32812 10.2857 7 9.96429 7 9.42857C7 9 7.32812 8.57143 7.875 8.57143H48.125C52.3906 8.57143 56 12.1071 56 16.2857V40.2857C56 44.5714 52.3906 48 48.125 48H7.875C3.5 48 0 44.5714 0 40.2857V7.71429C0 3.53571 3.5 0 7.875 0ZM41.125 28.2857C41.125 29.7857 42.2188 30.8571 43.75 30.8571C45.1719 30.8571 46.375 29.7857 46.375 28.2857C46.375 26.8929 45.1719 25.7143 43.75 25.7143C42.2188 25.7143 41.125 26.8929 41.125 28.2857ZM48.125 28.2857C48.125 29.8929 47.25 31.2857 45.9375 32.0357C44.5156 32.7857 42.875 32.7857 41.5625 32.0357C40.1406 31.2857 39.375 29.8929 39.375 28.2857C39.375 26.7857 40.1406 25.3929 41.5625 24.6429C42.875 23.8929 44.5156 23.8929 45.9375 24.6429C47.25 25.3929 48.125 26.7857 48.125 28.2857Z"
            fill="#00BDD6"/>
        </svg>

        <div class="text-wrapper">

          <div class="body-s-regular m-caption-l-regular">connect</div>

          <div class="title heading-m-regular m-heading-s-regular">WALLET</div>

        </div>

      </div>

      <div class="central-divider">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="156" viewBox="0 0 10 156"
             fill="none">
          <path d="M1 0L1 70.2L9 78L1 85.8L1 156" stroke="#414548"/>
        </svg>
      </div>

      <div class="content">

        <div class="number">
          <div class="circle body-s-medium">2</div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" width="62" height="48" viewBox="0 0 62 48" fill="none">
          <path
            d="M55.1111 1.71429H6.88889C3.98264 1.71429 1.72222 4.07143 1.72222 6.85714V41.1429C1.72222 44.0357 3.98264 46.2857 6.88889 46.2857H55.1111C57.9097 46.2857 60.2778 44.0357 60.2778 41.1429V6.85714C60.2778 4.07143 57.9097 1.71429 55.1111 1.71429ZM6.88889 0H55.1111C58.8785 0 62 3.10714 62 6.85714V41.1429C62 45 58.8785 48 55.1111 48H6.88889C3.01389 48 0 45 0 41.1429V6.85714C0 3.10714 3.01389 0 6.88889 0ZM15.5 17.1429C15.5 19.0714 16.4688 20.6786 18.0833 21.6429C19.5903 22.6071 21.6354 22.6071 23.25 21.6429C24.7569 20.6786 25.8333 19.0714 25.8333 17.1429C25.8333 15.3214 24.7569 13.7143 23.25 12.75C21.6354 11.7857 19.5903 11.7857 18.0833 12.75C16.4688 13.7143 15.5 15.3214 15.5 17.1429ZM27.5556 17.1429C27.5556 19.6071 26.1562 21.8571 24.1111 23.1429C21.9583 24.3214 19.2674 24.3214 17.2222 23.1429C15.0694 21.8571 13.7778 19.6071 13.7778 17.1429C13.7778 14.7857 15.0694 12.5357 17.2222 11.25C19.2674 10.0714 21.9583 10.0714 24.1111 11.25C26.1562 12.5357 27.5556 14.7857 27.5556 17.1429ZM10.3333 35.1429C10.3333 35.6786 9.90278 36 9.47222 36C8.93403 36 8.61111 35.6786 8.61111 35.1429C8.61111 30.9643 12.0556 27.4286 16.3611 27.4286H24.9722C29.1701 27.4286 32.7222 30.9643 32.7222 35.1429C32.7222 35.6786 32.2917 36 31.8611 36C31.3229 36 31 35.6786 31 35.1429C31 31.9286 28.2014 29.1429 24.9722 29.1429H16.3611C13.0243 29.1429 10.3333 31.9286 10.3333 35.1429ZM38.75 15.4286H54.25C54.6806 15.4286 55.1111 15.8571 55.1111 16.2857C55.1111 16.8214 54.6806 17.1429 54.25 17.1429H38.75C38.2118 17.1429 37.8889 16.8214 37.8889 16.2857C37.8889 15.8571 38.2118 15.4286 38.75 15.4286ZM38.75 22.2857H54.25C54.6806 22.2857 55.1111 22.7143 55.1111 23.1429C55.1111 23.6786 54.6806 24 54.25 24H38.75C38.2118 24 37.8889 23.6786 37.8889 23.1429C37.8889 22.7143 38.2118 22.2857 38.75 22.2857ZM38.75 29.1429H54.25C54.6806 29.1429 55.1111 29.5714 55.1111 30C55.1111 30.5357 54.6806 30.8571 54.25 30.8571H38.75C38.2118 30.8571 37.8889 30.5357 37.8889 30C37.8889 29.5714 38.2118 29.1429 38.75 29.1429Z"
            fill="#00BDD6"/>
        </svg>

        <div class="text-wrapper">

          <div class="body-s-regular m-caption-l-regular">start</div>

          <div class="title heading-m-regular m-heading-s-regular">KYC</div>

        </div>

      </div>

    </div>

    <div class="middle-content">
      <div class="title body-l-regular m-body-l-regular">Connect your wallet and do the KYC to participate in our
        pre-sale
      </div>
      <div class="caption-l-regular m-caption-l-regular">By clicking continue you're accepting our <a
        href="/terms-and-conditions.pdf"
        target="_blank" class="text-white">Terms
        and Conditions</a>
        and our
        <a target="_blank" href="/privacy-policy.pdf" class="text-white">Privacy Policy</a>.
      </div>
    </div>

    <div class="bottom-content">

      <div class="button-container">

        <sbc-button
          button-label="Continue"
          :button-medium="true"
          :is-blue-gradient="true"
          @click="$emit('continue')"
        />

        <sbc-button
          class="hide-mobile"
          button-label="Decline"
          :button-medium="true"
          :is-black="true"
          @click="$emit('decline')"
        />

        <sbc-button
          class="show-mobile"
          button-label="Decline"
          :button-medium="true"
          :is-dark-gray="true"
          @click="$emit('decline')"
        />
      </div>

      <div class="supported-container">
        <div class="caps-s-regular m-caps-s-regular">KYC POWERED BY</div>

        <a href="https://sumsub.com/" target="_blank"><img src="/assets/support/sum-sub-logo.svg" alt=""></a>
      </div>

    </div>

  </div>

</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: "WalletConnectInit",
  components: {SbcButton},
};
</script>

<style lang='scss'>
@import "ConnectWalletInit";
</style>
