<template>
  <div v-focus-in-viewport="0.2" class="member-box">

    <div class="team-member-info team-box" :class="{'show-details': showDetails}">

      <div class="links-wrapper">
        <div class="member-picture-container">
          <a :href="teamBox.link" target="_blank">
            <img class="member-picture" :src="teamBox.image" alt="">
          </a>
        </div>

      </div>

      <div class="name-container">
        <div class="member-name body-l-medium m-body-l-medium">{{ teamBox.name }}</div>
        <div class="member-position caps-s-regular m-caps-s-regular">{{ teamBox.position }}</div>
      </div>

      <div class="button-container">

        <div class="social-media-button">
          <a class="social-media" :href="teamBox.link" target="_blank">
            <img :src="teamBox.socialMedia" alt="">
          </a>
        </div>

        <sbc-button
          v-if="!teamBox.hideButton"
          button-label="details"
          @click.stop="flipCardDetails"
          :button-extra-small="true"
          :has-border="true"
        />

      </div>
    </div>

    <div class="team-member-details team-box"
         :class="{'show-details-1': showDetails1, 'show-details-scroll': showDetailsScroll}">
      <div class="list-wrapper">
        <ul>
          <li v-for="item in teamBox.workHistory">{{ item }}</li>
        </ul>
      </div>

      <sbc-button
        button-label="back"
        @click.stop="flipCardDetails"
        :button-extra-small="true"
        :has-border="true"
      />

    </div>

  </div>

</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";
import focusInViewport from "@/directives/focusInViewport.js";

export default {
  name: "MemberBox",
  emits: ['flip'],
  components: {SbcButton},
  directives: {focusInViewport},
  props: {
    teamBox: {
      type: Object,
    },
  },

  data: () => ({
    showDetails: false,
    showDetails1: false,
    showDetailsScroll: false,
  }),

  methods: {
    flipCardDetails() {
      this.showDetails = !this.showDetails;
      this.showDetails1 = !this.showDetails1;
      this.$emit('flip', this.showDetails)
      if (this.showDetails1) {
        setTimeout(() => {
          this.showDetailsScroll = true
        }, 800)
      } else {
        this.showDetailsScroll = false
      }
    },

    resetCardDetails() {
      this.showDetails = false;
      this.showDetails1 = false;
      this.showDetailsScroll = false
    }
  },
};
</script>

<style lang='scss'>
@import "MemberBox";
</style>
