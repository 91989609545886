<template>
  <div class="time-countdown-2">

    <countdown-timer
        :date-time="time"
        v-slot="{ days, hours, minutes, seconds }"
    >

      <div class="time-box">
        <div class="timer-value heading-m-regular m-heading-s-regular">{{ days }}</div>
        <div class="timer-title body-s-regular m-caption-s-regular">days</div>
      </div>

      <div class="time-box">
        <div class="timer-value heading-m-regular m-heading-s-regular">{{ hours }}</div>
        <div class="timer-title body-s-regular m-caption-s-regular">hours</div>
      </div>

      <div class="time-box">
        <div class="timer-value heading-m-regular m-heading-s-regular">{{ minutes }}</div>
        <div class="timer-title body-s-regular m-caption-s-regular">minutes</div>
      </div>

      <div class="time-box">
        <div class="timer-value heading-m-regular m-heading-s-regular">{{ seconds }}</div>
        <div class="timer-title body-s-regular m-caption-s-regular">seconds</div>
      </div>

    </countdown-timer>

  </div>
</template>

<script>
import CountdownTimer from "@/components/general/CountdownTimer.vue";
import {mapGetters} from "vuex";

export default {
  name: "TimeCountdown2",
  components: {CountdownTimer},
  props: {
    time: {
      type: String, required: true
    }
  },

};
</script>

<style lang='scss'>
@import "TimeCountdown2";
</style>
