import {Wallet} from "@/services/wallet.js"
import {getNonce, walletAuthenticate, updateSumsubKycAccessToken} from "@/graphql/index.js";
import {SiweMessage} from "siwe";
import store from '../store'

let uri = window.location.href;
const domain = window.location.host;

async function createSiweMessage(address, statement, referralCode = null) {
  const res = await getNonce();
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri,
    version: '1',
    chainId: store.getters['presale/getActiveContract'].chainId,
    nonce: res.nonce
  });
  return {message: message.prepareMessage(), preSignedToken: res.preSignedToken, nonce: res.nonce};
}

const signInWithEthereum = async () => {
  if(Wallet.isNuls) {
    let provider = await Wallet.getProvider()
    let address = Wallet.getCurrentAddress()
    const res = await getNonce();
    let message = res.nonce + address
    const resSignature = await provider.signMessage([message, address])

    return {message, signature: resSignature, preSignedToken: res.preSignedToken, address: address, pubKey: Wallet.pubKey, isNuls: true}
  }
  let provider = await Wallet.getProvider()
  let signer = await provider.getSigner();

  const {
    message, preSignedToken, nonce
  } = await createSiweMessage(await signer.address, 'Sign in with Ethereum to the app.');

  const signature = await signer.signMessage(message);
  return {message, signature, preSignedToken, address: signer.address, isNuls: false}
}

const sendForVerification = async ({message, signature, preSignedToken, pubKey, isNuls, address}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get('referral');
  const whitelistCode = urlParams.get('whitelistCode');

  console.log("CREATE ACCOUNT WITH REFERRAL CODE::", referralCode)
  return walletAuthenticate({
    message, signature, preSignedToken, isNuls, pubKey, address, referralCode,whitelistCode
  })
    .then((auth) => {
      console.log("sendForVerification: ", auth)
      return auth
    })
}

const updateKycAccessToken = async ({accuntId}) => {
  return updateSumsubKycAccessToken({accuntId})
    .then(kycAccessToken => kycAccessToken)
}

export {createSiweMessage, signInWithEthereum, sendForVerification, updateKycAccessToken}
