<template>
  <div class="team-section spacing">

    <sbc-carousel :items="teamContainer" :min-width="1300" :auto-scroll="true" class="hide-mobile">

      <template #listItem="{item, index}" :key="index">

        <team-container
            :ref="`teams${index}`"
            :team-container="item"
            @flip="onFlip(index)"
        />

      </template>

    </sbc-carousel>

    <sbc-carousel :items="teamContainer" :min-width="1300" :auto-scroll="false" class="show-mobile">

      <template #listItem="{item, index}" :key="index">

        <team-container
            ref="teamsMobile"
            :team-container="item"
            @flip="onFlip(index)"
        />

      </template>

    </sbc-carousel>

  </div>
</template>

<script>
import TeamBox from "@/components/MemberBox.vue";
import SbcCarousel from "@/components/general/SbcCarousel.vue";
import BenefitBox from "@/components/BenefitBox.vue";
import TeamContainer from "@/components/TeamContainer.vue";
import {teamContainer} from "@/services/teamContainer.js";

export default {
  name: "TeamSection",
  components: {TeamContainer, BenefitBox, SbcCarousel, TeamBox},

  data() {
    return {
      teamContainer
    }
  },

  methods: {
    onFlip(index) {
      console.log('this.$refs', this.$refs)
      this.teamContainer.forEach((t, i) => {
        if(i !== index) {
          this.$refs[`teams${i}`].resetCardDetails()
        }
      })
    }
  }
};
</script>

<style lang='scss'>
@import "TeamSection";
</style>