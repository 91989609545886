import {createWeb3Modal, defaultConfig} from '@web3modal/ethers'
import {
  BrowserProvider, Contract, formatUnits, parseEther, parseUnits, formatEther, JsonRpcProvider,
} from "ethers";
import presale from '@/contact/presale'
import token from '@/contact/token'
import {wait} from "@/services/utils.js";
import {ErrorDecoder} from "ethers-decode-error";
import {getNulsBalance, getNulsPrice} from "@/services/nuls.js";
import {logError} from "@/graphql/index.js";

const ERC20TOKENABI = ['function name() view returns (string)', 'function symbol() view returns (string)', 'function balanceOf(address) view returns (uint)', 'function transfer(address to, uint amount)', 'event Transfer(address indexed from, address indexed to, uint amount)']

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = 'ad0a8438a4d4edc04294ac84ee0f8390'
const testContractAddress = "0x742489F22807ebB4C36ca6cD95c3e1C044B7B6c8"

// 2. Set chains
const devNet = {
  chainId: 31337,
  name: 'Hardhat',
  currency: 'ETH',
  explorerUrl: 'https://blockchain-dev.suisseblockchain.app/',
  rpcUrl: 'https://blockchain-dev.suisseblockchain.app/'
}
// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

const binance = {
  chainId: 56,
  name: 'Binance',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com/',
  rpcUrl: 'https://bsc-dataseed.binance.org'
}

// 3. Create your application's metadata object
const metadata = {
  name: 'SUISSE Pad', description: 'SUISSE Pad', url: 'https://presale.suisseblockchain.io', // url must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  defaultChainId: 1, // used for the Coinbase SDK
})



const Wallet = {
  modal: null,
  nabox: null,
  naboxInfo: null,
  pubKey: null,
  isNabox: false,
  isNuls: false,
  isConnected() {
    if(this.isNuls) {
      return !!this.naboxInfo?.length
    }
    console.log(this.modal)
    return this.modal.getIsConnected()
  },
  disconnect() {
    if(this.isNuls) {
      this.naboxInfo = null
      this.nabox = null;
      this.pubKey = null
    } else {
      this.modal.adapter.disconnect()
    }
  },
  setNetworkType(contract) {
    this.isNuls = contract.networkType === 'NULS'
  },
  open() {
    if(!this.isNuls) {
      this.modal.open();
      return Promise.resolve(false)
    } else {
      if (typeof window.nabox !== "undefined") {
        console.log('CREATE SESSION')
        return window.nabox.createSession()
          .then((naboxInfo) => Promise.all([
            naboxInfo,
            window.nabox.getPub({address: naboxInfo[0]})
          ]))
          .then(([naboxInfo, pubKey]) => {
            console.log('naboxInfo, pubKey', naboxInfo, pubKey)
            this.naboxInfo = naboxInfo
            this.nabox = window.nabox;
            this.pubKey = pubKey
            return true;
          });
      } else {
       return Promise.reject("You must install nabox wallet")
      }

    }

  },
  async getProvider(retries = 50) {

    if(this.isNuls) {
      return this.nabox;
    }

    let provider = Wallet.modal.getWalletProvider();
    if (!provider && retries > 1) {
      await wait(300)
      return this.getProvider(retries - 1)
    }
    Wallet.isNabox = provider?.isNabox; //maybe a better way?
    return new BrowserProvider(provider);
  },
  initilizeModal(presaleContracts) {
    console.log('presaleContracts', presaleContracts)
    let evmContracts = Object.values(presaleContracts).filter(pc => pc.networkType === 'EVM')
    this.modal = createWeb3Modal({
      ethersConfig, chains: Object.values(evmContracts), projectId, enableAnalytics: true, // Optional - defaults to your Cloud configuration

      enableOnramp: true // Optional - false as default
    })
    return this.modal.adapter.walletConnectProviderInitPromise
  },
  getCurrentNetwork() {
    if(this.isNuls) {
      return Promise.resolve({
        chainId: 0
      })
    }
    return this.getProvider().then(provider => provider.getNetwork());
  },
  checkValidNetwork() {
    if(this.isNuls) {
      console.log('CHECK VALID NETWORK')
      //todo get from store contract
      // if(this.nabox && this.nabox.chainId === 1) {
      //   return Promise.resolve(true)
      // }
      // return false;
      return this.getProvider().then(provider => provider.switchChain({chainId: 1}))
          .then(() => true)
          .catch((err) => {
            console.log(err)
            return this.nabox.chainId === 1
          })
      // return Promise.resolve(false)
    }
    return this.getCurrentNetwork()
      .then(network => {
        console.log(this.modal)
        return network && this.modal?.adapter.chains.find(c => parseInt(c.chainId) === parseInt(network.chainId))
      })
      .catch(() => false);
  },
  getCurrentAddress() {
    if(this.isNuls) {
      return this.naboxInfo[0];
    }
    return this.modal?.getAddress()
  },
  async setStage(data) {
    let provider = await this.getProvider()
    const signer = await provider.getSigner()

    let contract = new Contract(testContractAddress, presale.abi, signer);
    let price = parseUnits("0.2", 8);
    let total = parseUnits("2500000", 0);
    let tx = await contract.setStage(price, total, total);
    const receipt = await tx.wait();
  },
  async numberOfStages(data) {

    let provider = await this.getProvider()
    const signer = await provider.getSigner()

    let abi = ["function numberOfStages() external view returns (uint256)"];
    let contract = new Contract(testContractAddress, presale.abi, signer);
    let tx = await contract.numberOfStages();
  },
  async depositCoin(contractAddress, amountOfTokens, signature, nulsSignature) {
    let provider = await this.getProvider()
    let address = this.getCurrentAddress()
    let resultAmountOfTokens = parseFloat(amountOfTokens).toFixed(6)
    if(this.isNuls) {
      const data = {
        from: address,
        value: resultAmountOfTokens,
        contractAddress: contractAddress,
        methodName: "depositCoin2",
        args: [nulsSignature]
        // methodDesc: "(String signature) return void",
      }
      const res = await provider.contractCall(data)
      return {
        receipt: {
          hash: res
        }
      }
    }
    const signer = await provider.getSigner()
    let abi = ["function depositCoin(address referrer, bytes memory signature) public payable"];
    let contract = new Contract(contractAddress, abi, signer);
    try {
      const depositAmount = parseEther(resultAmountOfTokens.toString()); // 3 ETH
      let tx = await contract.depositCoin(signer, signature, {value: depositAmount});
      await logError(JSON.stringify(tx))
      const receipt = await tx.wait();
      await logError(JSON.stringify(receipt))
      return {receipt}
    } catch (ex) {
      await logError(ex.message)
      return this.decodeContractError(contract.interface, ex)
    }
  }, async depositCoinUSDC(contractAddress, stableCoinAddress, amountOfTokens, signature, activeContract) {
    let provider = await this.getProvider()
    let tokenPrecision =  activeContract.tokenPrecision || 18
    const signer = await provider.getSigner()
    const depositAmount = parseUnits(amountOfTokens.toString(), tokenPrecision);
    let contract = new Contract(contractAddress, presale.abi, signer);
    try {
      let usdcToken = new Contract(stableCoinAddress, token.abi, signer);
      let address = this.getCurrentAddress()
      let allowance =  await usdcToken.allowance(address, contractAddress)

      try {
        let txApprove = await usdcToken.approve(contractAddress, depositAmount)
        await logError(JSON.stringify(txApprove))
        await txApprove.wait();
        await logError("RECEPIpt APPROVE")
      } catch (ex) {
        await logError(ex.message)
        if(allowance > 0n && activeContract.chainId === 1) {
          let txRevoke = await usdcToken.approve(contractAddress, 0n)
          await txRevoke.wait();
          let txApprove = await usdcToken.approve(contractAddress, depositAmount)
          await txApprove.wait();
        }
      }
      let tx = await contract.depositUSDT(depositAmount, signer, signature);
      const receipt = await tx.wait();
      return {depositAmount, receipt}
    } catch (ex) {
      await logError(ex.message)
      return this.decodeContractError(contract.interface, ex)
    }

  }, async getBalance() {
    let abi = ["function getBalance(address addr) public view returns (uint256)"]
    let provider = await this.getProvider()
    const signer = await provider.getSigner()
    let contract = new Contract(testContractAddress, abi, signer);
    let tx = await contract.getBalance(signer);
    console.log('TX', tx)
    // const receipt = await tx.wait();
    // console.log('receipt', receipt)

  },

  async getTokenPrice(priceFeedAddress, rpcUrl) {
    console.log('priceFeedAddress', priceFeedAddress, rpcUrl)
    if(this.isNuls) {

      return getNulsPrice(priceFeedAddress)
    }
    let abi = ["function latestRoundData() external view returns (uint80 roundId, int256 answer, uint256 startedAt, uint256 updatedAt, uint80 answeredInRound)"]
    const provider = new JsonRpcProvider(rpcUrl)
    let contract = new Contract(priceFeedAddress, abi, provider);
    let [roundId, amount] = await contract.latestRoundData();

    return amount;

  }, async unpause() {
    let provider = await this.getProvider()
    const signer = await provider.getSigner()
    let abi = ["function unpause() external"];
    let contract = new Contract(testContractAddress, abi, signer);
    let tx = await contract.unpause();
    const receipt = await tx.wait();

  }, async getCurrentBalance() {
    try {
      let currentAddress = await this.getCurrentAddress()
      let provider = await this.getProvider()
      if(this.isNuls) {
        let balance = await getNulsBalance(currentAddress)
        console.log('BALANCE', balance)
        return formatUnits(balance, 8)

        // return formatUnits(9999287815788448832194n, 18)

        // const data = {
        //   contractAddress: currentAddress,
        //   methodName: "balanceOf",
        //   methodDesc: "(owner) return BigInteger",
        //   args: [currentAddress]
        // }
        // const res = await provider.invokeView(data)
        // console.log('RES', res)
      } else {
        const balance = await provider.getBalance(currentAddress)
        console.log('BALANCE', balance)
        return formatUnits(balance, 18)
      }
    } catch (ex) {
      return 0
    }

  }, async getTokenBalance(contractAddress, userAddress, contract) {
    if(this.isNuls) {
      return formatUnits(9999287815788448832194n, 18)
    }
    let precision = contract?.tokenPrecision || 18;
    let provider = await this.getProvider()
    const signer = await provider.getSigner()
    console.log(contractAddress)
    const TokenContract = new Contract(contractAddress, ERC20TOKENABI, signer)
    const TokenBalance = await TokenContract.balanceOf(userAddress)
    return formatUnits(TokenBalance, precision);
  },

  async decodeContractError(contract, error) {
    console.log('ERR', error)
    let errorDecoder = ErrorDecoder.create([contract])
    let errorDecoded = await errorDecoder.decode(error)
    return Promise.reject(errorDecoded)
  },

  contractErrorMapping(err) {
    console.log('ERR', err)
    switch (err.reason) {
      case "Pausable: paused":
        return "Purchasing is currently paused for this stage."
      case "ethers-user-denied: MetaMask Tx Signature: User denied transaction signature.":
        return "The transaction has been cancelled."
      default:
        return "Something went wrong. Please try again later.";
    }
  }
}

export {Wallet}
