<template>
  <div class="k-y-c-in-progress">

    <div class="top-content">

      <div class="content">

       <sbc-loading/>

        <div class="title heading-m-regular m-heading-m-regular">KYC <span class="basic">in Progress</span></div>

      </div>

    </div>

    <div class="middle-content">
      <div class="title body-l-regular m-body-l-regular">Please wait for the KYC to complete.</div>
      <div class="caption-l-regular m-caption-l-regular">The process can take from 5 minutes, up to 2 days.
      </div>
    </div>
    <div class="bottom-content">

      <div class="button-container">

        <sbc-button
          class="hide-mobile"
          button-label="Back to Dashboard"
          :button-medium="true"
          :is-black="true"
          @click="$emit('back')"
        />

        <sbc-button
          class="show-mobile"
          button-label="Back to Dashboard"
          :button-medium="true"
          :is-dark-gray="true"
          @click="$emit('back')"
        />

      </div>

      <div class="supported-container">
        <div class="caps-s-regular m-caps-s-regular">KYC POWERED BY</div>

        <a href="https://sumsub.com/" target="_blank"><img src="/assets/support/sum-sub-logo.svg" alt=""></a>
      </div>


    </div>

  </div>

</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";
import SbcLoading from "@/components/SbcLoading.vue";

export default {
  name: "KYCInProgress",
  components: {SbcLoading, SbcButton},
};
</script>

<style lang='scss'>
@import "KYCInProgress";
</style>
