<template>
  <div class="token-limit-reached">

    <div class="top-content">

      <div class="content">

        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#414548"/>
          <path
            d="M21.5 18H42.5C43.3125 18 44 18.6875 44 19.5C44 20.375 43.3125 21 42.5 21H21.5C20.625 21 20 20.375 20 19.5C20 18.6875 20.625 18 21.5 18ZM33.0625 26.5L41.0625 35C41.625 35.625 41.625 36.5625 41 37.125C40.375 37.6875 39.4375 37.6875 38.875 37.0625L33.5 31.3125V36.5V44.5C33.5 45.375 32.8125 46 32 46C31.125 46 30.5 45.375 30.5 44.5V36.5V31.3125L25.0625 37.0625C24.5 37.6875 23.5625 37.6875 22.9375 37.125C22.3125 36.5625 22.3125 35.625 22.875 35L30.875 26.5C31.1875 26.1875 31.5625 26 32 26C32.375 26 32.8125 26.1875 33.0625 26.5Z"
            fill="#D7001D"/>
        </svg>

        <div class="title heading-m-regular m-heading-m-regular">TOKEN LIMIT REACHED</div>

      </div>

    </div>

    <div class="middle-content">
      <div class="title body-l-regular m-body-l-regular">You’ve purchased all available tokens for your account in this
        stage.
      </div>
      <div class="caption-l-regular m-caption-l-regular">More will be available in the upcoming stages.<br>
      </div>
    </div>

    <div class="bottom-content">

      <div class="button-container">

        <sbc-button
          button-label="Back to Dashboard"
          :button-medium="true"
          :is-black="true"
          @click="$emit('goToDashboard')"
        />

      </div>

    </div>
  </div>
</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: "TokenLimitReached",
  components: {SbcButton},
};
</script>

<style lang='scss'>
@import "TokenLimitReached";
</style>
