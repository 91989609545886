<template>
  <div class="agree-checkbox body-s-regular">
    <input id="agree" type="checkbox"
           :checked="modelValue"
           @change="updateValue($event.target.checked)">
    <label for="agree">I agree to the
      <a href="/terms-and-conditions.pdf" target="_blank" class="text-white">Terms of Use</a>
      and
      <a href="/privacy-policy.pdf" target="_blank" class="text-white">Privacy Policy</a>
    </label>
  </div>
</template>

<script>
export default {
  name: "AgreeCheckbox",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    updateValue(value) {
      this.$emit("update:modelValue", value);
    }
  },

  beforeUnmount() {
    this.$emit("update:modelValue", false);
  }
};
</script>

<style lang='scss'>
@import "AgreeCheckbox";
</style>
