<template>
  <div class="rocket-fuel-log">

    <div class="top-bar">

      <div class="title">
        <div class="body-s-regular"><span class="text-white">Rocket Fuel </span>Log</div>
      </div>

    </div>

    <div class="transaction-wrapper">

      <div class="rocket-row-wrapper" v-if="myRocketFuelPresaleTransactions.length || myRocketFuelPresaleTransactions.length">

        <template v-if="myRocketFuelPresaleTransactions.length"
             v-for="transaction in myRocketFuelPresaleTransactions">
          <rocket-fuel-row v-if="transaction.basePoints && transaction.basePoints > 0" :transaction="transaction" points="BASE"/>
          <rocket-fuel-row v-if="transaction.nulsPoints && transaction.nulsPoints > 0" :transaction="transaction" points="NULS"/>
          <rocket-fuel-row v-if="transaction.naboxPoints && transaction.naboxPoints > 0" :transaction="transaction" points="NABOX"/>
          <rocket-fuel-row v-if="transaction.whitelistReferralPoints && transaction.whitelistReferralPoints > 0" :transaction="transaction" points="WHITELIST"/>
          <rocket-fuel-row  v-if="!transaction.isReferral && transaction.referralPoints && transaction.referralPoints > 0" :transaction="transaction" points="MY_REFERRAL" />
          <rocket-fuel-row v-if="transaction.isReferral && transaction.referralPoints && transaction.referralPoints > 0"
                           :transaction="transaction" points="REFERRAL"/>
        </template>

<!--        <div class="rocket-row-container" v-if="myRocketFuelPresaleTransactions.length"-->
<!--             v-for="transaction in myRocketFuelPresaleTransactions">-->
<!--          <rocket-fuel-row v-if="transaction.referralPoints && transaction.referralPoints > 0"-->
<!--                           :transaction="transaction" points="REFERRAL"/>-->
<!--        </div>-->
      </div>

      <div v-else class="error-wrapper">
        <div class="error caption-l-regular">There are no rocket fuel transactions yet.
          Once you purchase SUISSE tokens, your rocket fuel transactions will appear on this list.
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import TransactionRow from "@/components/hero-components/TransactionRow.vue";
import {mapState} from 'vuex';
import RocketFuelRow from "@/components/hero-components/RocketFuelRow.vue";

export default {
  name: "RocketFuelLog",
  components: {RocketFuelRow, TransactionRow},

  computed: {
    ...mapState({
      myRocketFuelPresaleTransactions: state => state.presale.myRocketFuelPresaleTransactions
    })
  }
};
</script>

<style lang='scss'>
@import "RocketFuelLog";
</style>
