import {gql} from '@apollo/client/core';

export default gql`
  query getNotificationRecipient($token:String, $includeLists: Boolean=false) {
    notificationRecipient: getNotificationRecipient(token:$token) {

      id
      subscriptionLists @include(if: $includeLists) {
        id
        referenceId
        title
        type
      }
    }
  }
`;
