<template>
  <div class="solution-section spacing">

    <div class="main-container-2">

      <div class="text-highlight display-s-regular m-heading-m-regular">Trusted Projects</div>

      <div class="display-s-regular m-heading-m-regular">Opportunities for Everyone</div>

      <div class="text-container">
        <div class="body-s-regular m-body-s-regular text-left">Blockchain industry suffers from a lack of regulatory
          oversight and
          quality control. <br>
          It is a chaotic landscape that leads to rug pull projects still avoiding countermeasures.
        </div>
        <div class="body-s-regular m-body-s-regular text-right">SuissePad is a platform that through Swiss regulatory
          practices and
          a detailed
          vetting process helps the
          industry grow with new projects and users.
        </div>
      </div>

      <div class="stats-container">

        <a href="https://docs.suisseblockchain.io/suissepad/overview/a-new-solution" target="_blank"
           class="single-stat">
          <div class="icon-container">
            <img src="/assets/icons/swiss-box.svg" alt="">
          </div>
          <div class="stat-title caps-s-medium m-caps-s-medium">swiss regulatory compliant</div>
        </a>

        <a href="https://docs.suisseblockchain.io/suissepad/overview/a-new-solution" target="_blank"
           class="single-stat">
          <div class="icon-container">
            <img src="/assets/icons/paper-search-icon.svg" alt="">
          </div>
          <div class="stat-title caps-s-medium m-caps-s-medium">Audited Business Stack</div>
        </a>

        <a href="https://docs.suisseblockchain.io/suissepad/product/supported-blockchains" target="_blank"
           class="single-stat">
          <div class="icon-container">
            <img src="/assets/icons/chain-icon.svg" alt="">
          </div>
          <div class="stat-title caps-s-medium m-caps-s-medium">40+ Blockchain Support</div>
        </a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "SolutionSection",
};
</script>

<style lang='scss'>
@import "SolutionSection";
</style>