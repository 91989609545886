const faqBoxes = {
    q1: {
        id: 'q1',
        question: 'Where can I find your whitepaper?',
        answer: 'Our whitepaper is available in our <a href="https://docs.suisseblockchain.io/suissepad" target="_blank">Docs</a>.',
    }, q2: {
        id: 'q2',
        question: 'Is KYC required to participate in project launches on the platform?',
        answer: 'Yes, KYC is necessary to participate in project IDOs. Due to regulatory compliance, we\'re required to use the KYC process to ensure safety of platform funds and transfers. Thanks to <a href="https://sumsub.com/" target="_blank">SumSub</a>, the process is as seamless as possible.',
    }, q3: {
        id: 'q3',
        question: 'When is the $SUISSE TGE?',
        answer: 'It is expected in Q1/Q2 2025. More info in our <a href="https://docs.suisseblockchain.io/suissepad/token/tokenomics" target="_blank">Docs</a>.',
    }, q4: {
        id: 'q4',
        question: 'SUISSE Token - What can I do with it?',
        answer: 'The utilities of the SUISSE Token are: Access to the platform, Staking, Governance. More details are listed in our <a href="https://docs.suisseblockchain.io/suissepad/token/tokenomics/token-utility" target="_blank">Docs</a>.',
    }, q5: {
        id: 'q5',
        question: 'What is SUISSE Power (SUISSEP)?',
        answer: 'SUISSE Power is a score that determines your allocation in IDOs on SuissePad. More on that in <a href="https://docs.suisseblockchain.io/suissepad/token/tokenomics" target="_blank">Docs</a>.',
    },
};

export {
    faqBoxes
};
