<template>
  <div class="total-values">

    <div class="top-bar">

      <div class="title m-heading-s-regular">
        <div class="heading-s-regular text-white">Stats</div>
      </div>

    </div>

    <div class="total-boxes">

      <div class="top-wrapper">
        <total-box
            class="amount-raised"
            title="TOTAL AMOUNT RAISED"
            :main-value="$formatPrice($formatUnits(totalRaised.toString(), 8), 0)"
        />

        <total-box
            title="TOTAL TOKENS SOLD"
            :is-tokens="true"
            :main-value="$formatAmount(totalTokensSold, 0)"
        />
      </div>

      <total-box
          class="single-row-box"
          title="$SUISSE LISTING PRICE"
          main-value="$0.3"
      />

    </div>

  </div>
</template>

<script>
import TotalBox from "@/components/hero-components/TotalBox.vue";
import {mapGetters} from "vuex";

export default {
  name: "TotalValues",
  components: {TotalBox},

  computed: {
    ...mapGetters('presale', ["getTotalRaised"]),
    ...mapGetters('presale', ["getTotalTokensSold"]),
    totalRaised() {
      return this.getTotalRaised;
    },
    totalTokensSold() {
      return this.getTotalTokensSold;
    },
  }
};
</script>

<style lang='scss'>
@import "TotalValues";
</style>
