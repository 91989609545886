<template>
  <div class="select-payment">

    <div class="title-wrapper">
      <div class="dialog-title caps-l-regular m-caps-l-regular">Select <span class="text-white">payment method</span>
      </div>
      <div @click="$emit('close')" class="close-popup">
        <img src="../../../public/assets/close-icon.svg" alt="">
      </div>
    </div>

    <template
      v-for="(presaleContract, index) in presaleContractsArr"
      :key="index"
    >

      <div class="payment-option" :class="{'one-token' : presaleContract.networkType==='NULS'}"
           v-if=" isWalletConnected && getActiveContract.networkType=== presaleContract?.networkType || !isWalletConnected">

        <div class="top-bar">
          <div class="title body-s-regular m-body-s-regular">
            <img :src="chainIcons[presaleContract.chainId]" alt="">
            {{ presaleContract.name }}
          </div>
        </div>


        <div class="value-option"
             v-if="isWalletConnected && getActiveContract.networkType=== presaleContract?.networkType || !isWalletConnected">

          <div class="box" :class="{'active': chosenToken.currency === presaleContract.currency}"
               @click="selectTokenAndNetwork(presaleContract, presaleContract.address, presaleContract.currency)">
            <img :src="tokenIcons[presaleContract.currency]" alt="">
            <div class="heading-s-regular m-heading-s-regular">{{ presaleContract.currency }}</div>
          </div>

          <div class="box box-right"
               v-if="presaleContract.networkType!=='NULS'"
               :class="{'active': chosenToken.currency === presaleContract.stableCoinCurrency && chosenChainId === presaleContract.chainId}"
               @click="selectTokenAndNetwork(presaleContract, presaleContract.stableCoinAddress, presaleContract.stableCoinCurrency)">
            <div class="heading-s-regular m-heading-s-regular">{{ presaleContract.stableCoinCurrency }}</div>
            <img :src="tokenIcons[presaleContract.stableCoinCurrency]" alt="">
          </div>

        </div>
      </div>

    </template>

    <div class="coming-soon-wrapper" v-if="defaultState">

      <div class="bank-card">

        <div class="title body-s-regular m-body-s-regular">
          <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.625 0.375H18.375C19.6055 0.375 20.625 1.39453 20.625 2.625V3.75H0.375V2.625C0.375 1.39453 1.35938 0.375 2.625 0.375ZM20.625 7.125V13.875C20.625 15.1406 19.6055 16.125 18.375 16.125H2.625C1.35938 16.125 0.375 15.1406 0.375 13.875V7.125H20.625ZM4.3125 11.625C3.99609 11.625 3.75 11.9062 3.75 12.1875C3.75 12.5039 3.99609 12.75 4.3125 12.75H6.5625C6.84375 12.75 7.125 12.5039 7.125 12.1875C7.125 11.9062 6.84375 11.625 6.5625 11.625H4.3125ZM8.25 12.1875C8.25 12.5039 8.49609 12.75 8.8125 12.75H13.3125C13.5938 12.75 13.875 12.5039 13.875 12.1875C13.875 11.9062 13.5938 11.625 13.3125 11.625H8.8125C8.49609 11.625 8.25 11.9062 8.25 12.1875Z"
              fill="white"/>
          </svg>
          Bank Card
        </div>
      </div>

      <sbc-button
        button-label="COMING SOON"
        :is-black="true"
      />

      <div style="color: white">

      </div>

    </div>

    <div class="statement" v-if="!defaultState">
      <div class="caption-l-regular">To buy $SUISSE with NULS, you need to have the <a
        href="https://nabox.io/"
        target="_blank"
        class="nabox-link">Nabox</a> wallet installed.

        <!--        You get additional <a-->
        <!--        href="https://docs.suisseblockchain.io/suissepad/token/loyalty-program"-->
        <!--        target="_blank"-->
        <!--        class="link">Rocket Fuel</a> points when using the <a-->
        <!--        href="https://nabox.io/"-->
        <!--        target="_blank"-->
        <!--        class="nabox-link">Nabox</a> wallet and purchasing with NULS.-->

      </div>

      <div class="caption-l-regular error" v-if="err">{{ err }}</div>
    </div>



    <div class="buttons-wrapper" v-if="!defaultState">

      <sbc-button
        button-label="Continue"
        :button-medium="true"
        :is-blue-gradient="true"
        @click="onContinue"
      />

      <sbc-button
        button-label="Cancel"
        :button-medium="true"
        :is-black="true"
        @click="$emit('close')"
      />

    </div>

  </div>
</template>

<script>

import SbcButton from "@/components/general/SbcButton.vue";
import {mapGetters, mapState} from "vuex";
import {chainIcons, tokenIcons} from '@/services/chainAndTokenIcons.js'
import {Wallet} from "@/services/wallet.js";
import {isMobile} from "@/services/utils.js";

export default {
  name: "SelectPayment",
  components: {SbcButton},
  emits: ['initWallet', 'close', 'chosePaymentMethod', 'networkChanged', 'updateChosenTokenAndNetwork'],
  props: {
    defaultState: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    chainIcons,
    tokenIcons,
    selected: null,
    err: null
  }),

  computed: {
    ...mapState({
      presaleContracts: state => state.presale.presaleContracts,
      presaleContractsArr: state => state.presale.presaleContractsArr,
      presaleTransaction: state => state.presale.presaleTransaction,
      chosenToken: state => state.wallet.chosenToken,
      chosenChainId: state => state.wallet.chosenChainId,
      isWalletConnected: state => state.wallet.isWalletConnected,
    }),
    ...mapGetters({
      getActiveContract: 'presale/getActiveContract'
    })
  },

  methods: {
    onContinue() {
      if (window.NaboxWallet === undefined && this.chosenToken.currency === 'NULS' && isMobile()) {
        this.err = 'Please use the Nabox app\'s internal browser to purchase $SUISSE tokens using NULS.'
        return;
      }
      if (window.NaboxWallet === undefined && this.chosenToken.currency === 'NULS') {
        this.err = 'To buy $SUISSE using NULS, please install Nabox wallet and refresh the page.'
        return;
      }
      this.$emit('networkChanged');
    },
    selectTokenAndNetwork(contract, tokenAddress, tokenName) {
      this.err = false;
      if (this.isWalletConnected) {
        this.$emit('networkChanged', {contract, tokenAddress, tokenName})
        this.$emit('updateChosenTokenAndNetwork')
        return;
      }
      Wallet.setNetworkType(contract)
      return this.$store.dispatch('wallet/updateChosenTokenAndNetwork', {
        chainId: contract.chainId,
        chosenToken: {
          address: tokenAddress,
          currency: tokenName,
        },
        shouldExecute: false
      })
        .then(() => {
          // if (this.isWalletConnected) {
          //   this.$emit('networkChanged')
          // }
          this.$emit('updateChosenTokenAndNetwork')
        })
    },

  },

  created() {
    let contract = this.getActiveContract;
    console.log('this.chosenToke', this.chosenToken)
    this.selected = {
      contract,
      tokenAddress: this.chosenToken.address,
      tokenName: this.chosenToken.currency,
    }

  },

  beforeUnmount() {
    this.$emit('close')
  }
};
</script>

<style lang='scss'>
@import "SelectPayment";
</style>
