<template>
  <div class="logo-slider-section">

    <sbc-carousel :items="LogoSliderItems" :min-width="95" :auto-scroll="true" :auto-scroll-delay="3000">
      <template #listItem="{item, index}" :key="index">
        <logo-box
            :logo-box="item"
        />
      </template>
    </sbc-carousel>

  </div>
</template>

<script>
import SbcCarousel from "../../src/components/general/SbcCarousel.vue";
import LogoBox from "@/components/LogoBox.vue";
import LogoSliderItems from "../services/logoSliderItems";

export default {
  name: "LogoSliderSection",
  components: {LogoBox, SbcCarousel},
  data() {
    return {
      LogoSliderItems
    }
  },

};
</script>

<style lang='scss'>
@import "LogoSliderSection";
</style>