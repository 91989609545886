<template>
  <div class="sbc-loading">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "SbcLoading"
}
</script>

<style lang="scss">
@import "SbcLoading";
</style>
