<template>
  <div class="token-section spacing">

    <div class="main-container">

      <info-split-box
          :background="background"
          :secondary="true"
      >
        <template v-slot:content-left>

          <div class="title display-s-regular m-heading-m-regular">SUISSE <span
              class="text-highlight">Token</span>
          </div>

          <div class="list-wrapper">

            <div class="list-row body-s-regular  m-body-s-regular">
              <div class="row-title text-highlight">TOKEN</div>
              <div class="row-content">$SUISSE</div>
            </div>

            <div class="list-row body-s-regular  m-body-s-regular">
              <div class="row-title text-highlight">TYPE</div>
              <div class="row-content">Utility</div>
            </div>

            <div class="list-row body-s-regular m-body-s-regular">
              <div class="row-title text-highlight">ISSUER</div>
              <div class="row-content"><a href="https://www.zefix.ch/en/search/entity/list/firm/1545328" target="_blank"
                                          class="suisse-link">SBC Suisse Blockchain AG<br></a>
                a company based in Switzerland
              </div>
            </div>

            <div class="list-row body-s-regular m-body-s-regular">
              <div class="row-title text-highlight">RIGHTS</div>
              <div class="row-content">Access to SuissePad, staking, governance and more</div>
            </div>
<!--            <div class="list-row body-s-regular m-body-s-regular">-->
<!--              <div class="row-title text-highlight">REFUNDS</div>-->
<!--              <div class="row-content">No refunds</div>-->
<!--            </div>-->
          </div>
        </template>

        <template v-slot:content-right>

        </template>

      </info-split-box>

    </div>

  </div>
</template>

<script>
import InfoSplitBox from "@/components/InfoSplitBox.vue";

export default {
  name: "TokenSection",
  components: {InfoSplitBox},
  data() {
    return {
      background: '/assets/SUISSE_Token.jpg',
    };
  }
};
</script>

<style lang='scss'>
@import "TokenSection";
</style>
