import {
  createPresaleTransaction,
  createUSDCPresaleTransaction,
  listPresaleStages,
  listPresaleContracts,
  listPresaleTransactions,
  selfPresale
} from "@/graphql/index.js";
import {formatEther, formatUnits, parseEther, parseUnits} from "ethers";

const defaultState = () => ({
  presaleStages: [], presaleContracts: {},  presaleContractsArr: [],presaleTransaction: {
    amountOfTokensToPay: null,
    amountInDollars: 0,
    amountOfSuisseToReceive: null,
    basePoints: null,
    referralPoints: null,
    whitelistPoints: null,
    naboxPoints: null,
    nulsPoints: null,
    errors: {},
    errorMessages: [],
  },
  myPresaleTransactions: [], myRocketFuelPresaleTransactions: [],
  self: null,
  isDirty: false
})


export default {
  namespaced: true, actions: {
    selfPresale({commit, state, rootState},) {
      let addr = rootState.wallet?.chosenChainId === 0 ? rootState.wallet.currentAddress: null
      return selfPresale(addr)
        .then((self) => {
          commit('setSelf', self);
          return self;
        })
    }, createPresaleTransaction({commit}, input) {
      return createPresaleTransaction(input)
    }, createUSDCPresaleTransaction({commit}, input) {
      return createUSDCPresaleTransaction(input)
    }, listPresaleStages({commit},) {
      return listPresaleStages()
        .then(stages => {
          commit('setPresaleStages', stages)
        })
    }, listPresaleContracts({commit}, input) {
      return listPresaleContracts(input)
        .then(contracts => {
          commit('setPresaleContracts', contracts)
          return contracts;
        })
    }, updatePresaleTransaction({commit, rootState, getters, state, dispatch}, amountOfTokensToPay = 0) {
      commit('setIsDirty', false)
            console.log('amountOfTokensToPay',amountOfTokensToPay)
      let currentTokenPrice = rootState.wallet.currentTokenPrice;
      let amountInDollars = amountOfTokensToPay ? amountOfTokensToPay * parseInt(currentTokenPrice) : 0;
      let activePresaleStage = getters.getActivePresaleStage;
      let amountOfSuisseToReceive = Math.floor(amountInDollars / activePresaleStage?.price);

      let basePoints = amountOfSuisseToReceive * 10;

      let referralPoints = null;
      if (state.self?.referredBy) {
        referralPoints = amountOfSuisseToReceive * 3;
      }

      let naboxPoints = null;
      if (rootState.wallet.isNabox) {
        naboxPoints = amountOfSuisseToReceive * 10;
      }

      let whitelistPoints = null;
      if (state.self?.isWhitelisted && !state.myPresaleTransactions?.length) {
        whitelistPoints = amountOfSuisseToReceive * 10;
      }

      let nulsPoints = null;
      if (rootState.wallet.chosenToken.currency === 'NULS') {
        nulsPoints = amountOfSuisseToReceive * 10;
      }

      commit('setPresaleTransaction', {
        amountOfTokensToPay,
        amountInDollars,
        amountOfSuisseToReceive,
        basePoints,
        referralPoints,
        whitelistPoints,
        naboxPoints,
        nulsPoints
      })
      dispatch('syncTransactionErrors')
    },
    syncTransactionErrors({commit, state, rootState, getters}, err = {}) {
      let activePresaleStage = getters.getActivePresaleStage;
      let totalLeftForStage = activePresaleStage? activePresaleStage?.total - activePresaleStage?.totalPurchased:0
      let errors = {
        'no_balance': rootState.wallet.currentTokenBalance === 0n || (parseInt(rootState.wallet.currentTokenBalance) / 10 ** 18 < state.presaleTransaction.amountOfTokensToPay) || (getters.getMaxPerUser < getters.getMinPerUser),
        'no_amount': !parseFloat(state.presaleTransaction.amountOfTokensToPay),
        'err_mix_max': state.presaleTransaction.amountOfSuisseToReceive && (state.presaleTransaction.amountOfSuisseToReceive < (activePresaleStage?.minPerUser)
            || state.presaleTransaction.amountOfSuisseToReceive > (Math.min(activePresaleStage.maxPerUser - getters.currentStateAmountSuisse, totalLeftForStage)))
            || (state.presaleTransaction.amountOfTokensToPay !== null && state.presaleTransaction.amountOfSuisseToReceive === 0),
        'max_reached': (activePresaleStage?.maxPerUser === getters.currentStateAmountSuisse),
      };

      commit('setPresaleTransaction', {errors, errorMessages: []})
    },

    listPresaleTransactions({commit}, input) {
      return listPresaleTransactions(input)
        .then(({result}) => {
          commit('setMyPresaleTransactions', result)
        })
    },
    listRocketFuelPresaleTransactions({commit}, input) {
      return listPresaleTransactions(input)
        .then(({result}) => {
          commit('setMyRocketFuelPresaleTransactions', result)
        })
    }
  }, mutations: {
    setPresaleStages(state, stages) {
      state.presaleStages = stages;
    }, setPresaleContracts(state, contracts) {
      state.presaleContractsArr = contracts;
      state.presaleContracts = contracts.reduce((map, contract) => {
        map[contract.chainId] = contract;
        return map;
      }, {});
    }, setPresaleTransaction(state, presaleTransaction) {
      state.presaleTransaction = {
        ...state.presaleTransaction, ...presaleTransaction
      }
    }, resetState(state,) {
      this.commit('presale/resetPresaleTransaction');
      state.myPresaleTransactions = [];
      this.commit('presale/setSelf', null);
      state.isDirty = false;
    }, resetPresaleTransaction(state) {
      state.presaleTransaction = {
        amountOfTokensToPay: null,
        amountInDollars: null,
        amountOfSuisseToReceive: null,
        basePoints: null,
        referralPoints: null,
        whitelistPoints: null,
        naboxPoints: null,
        nulsPoints: null,
        errors: {},
        errorMessages: []
      };
    }, setMyPresaleTransactions(state, presaleTransactions) {
      state.myPresaleTransactions = presaleTransactions;
    }, setMyRocketFuelPresaleTransactions(state, presaleTransactions) {
      state.myRocketFuelPresaleTransactions = presaleTransactions
    }, setSelf(state, self) {
      state.self = self;
    }, setTransactionErrorMessages(state, message) {
      state.presaleTransaction = {
        ...state.presaleTransaction, errorMessages: [...state.presaleTransaction.errorMessages, message]
      }
    },
    setIsDirty(state, isDirty) {
      state.isDirty = isDirty;
    }
  }, getters: {
    getIsActiveStageStarted: (state, getters) => getters.getActivePresaleStage
        && ((getters.getActivePresaleStage.total - getters.getActivePresaleStage.totalPurchased) > getters.getActivePresaleStage.minPerUser)
        && parseInt(getters.getActivePresaleStage?.startsAt) <= Date.now()
        && (!getters.getActivePresaleStage?.endsAt || parseInt(getters.getActivePresaleStage?.endsAt) >= Date.now()),
    getActivePresaleStage: (state) => state.presaleStages.find(stage => stage.status === 'ACTIVE'), // getMinPerUserInDollars: (state, getters) => getters.getActivePresaleStage.
    getTotalStagesCount: (state) => state.presaleStages.length,
    getNextStage: (state, getters) =>
        state.presaleStages.filter(stage => stage.status === 'ACTIVE' || stage.status === 'DRAFT').sort((a,b) => {
          if(a.startsAt && b.startsAt) {
            return parseInt(a.startsAt) - parseInt(b.startsAt)
          }
          return a.number - b.number
        })[0],
    getPastStage: (state, getters) => state.presaleStages.sort((a,b) => parseInt(b.startsAt) - parseInt(a.startsAt)).find(stage => stage.status === 'ACTIVE' && (getters.getActivePresaleStage.total - getters.getActivePresaleStage.totalPurchased) <= getters.getActivePresaleStage.minPerUser || stage.status === 'COMPLETED'),
    getTotalRaised: (state) => state.presaleStages.reduce((sum, item) => {
      return sum + (item.totalPurchased * item.price);
    }, 0),
    getTotalTokensSold: (state) => state.presaleStages.reduce((sum, item) => {
      return sum + item.totalPurchased;
    }, 0),
    getTotalTokensInAllStages: (state) => state.presaleStages.reduce((sum, item) => {
      return sum + item.total;
    }, 0),
    getContractByChainId: (state) => (id) => state.presaleContracts[id],
    getActiveContract: (state, getters, rootState,) => {
      return getters.getContractByChainId(rootState.wallet.chosenChainId || Object.values(state.presaleContracts)[0]?.chainId)
    },
    getRocketFuel: (state) => {
      return state.presaleTransaction.basePoints + state.presaleTransaction.referralPoints + state.presaleTransaction.whitelistPoints + state.presaleTransaction.naboxPoints + state.presaleTransaction.nulsPoints
    },
    hasTransactionErrors(state) {
      return Object.values(state.presaleTransaction.errors).some(value => value === true);
    },
    currentStateAmountSuisse: (state, getters) => {
      let activeStage = getters.getActivePresaleStage;
      return state.myPresaleTransactions?.filter(t => t.stageId === activeStage?.id).reduce((acc, t) => acc + parseInt(t.amount), 0)
    },
    getMinPerStage(state, getters, rootState, rootGetters) {
      return getters.getActivePresaleStage?.minPerUser * getters.getActivePresaleStage?.price / 10 ** 8;
    },
    getMaxPerStage(state, getters, rootState, rootGetters) {
      return getters.getActivePresaleStage?.maxPerUser * getters.getActivePresaleStage?.price / 10 ** 8;
    },
    getMinPerUser(state, getters, rootState, rootGetters) {
      let currentTokenPrice = rootState.wallet.currentTokenPrice || BigInt(10 ** 8)
      if (getters.getMaxPerUserTokens === 0) return 0;
      return getters.getActivePresaleStage?.minPerUser * getters.getActivePresaleStage?.price / parseInt(currentTokenPrice);

    },
    getMaxPerUser(state, getters, rootState, rootGetters) {
      let activeStage = getters.getActivePresaleStage;
      let totalLeftForStage = activeStage? activeStage?.total - activeStage?.totalPurchased:0
      let currentTokenPrice = rootState.wallet.currentTokenPrice || BigInt(10 ** 8)
      let totalLeftForStageConverted = totalLeftForStage * getters.getActivePresaleStage?.price / parseInt(currentTokenPrice)
      let maxPerUser = getters.getMaxPerUserTokens * getters.getActivePresaleStage?.price / parseInt(currentTokenPrice)
      return Math.min(totalLeftForStageConverted, maxPerUser)
    },
    getMaxPerUserTokens(state, getters, rootState, rootGetters) {
      let max = getters.getActivePresaleStage?.maxPerUser - getters.currentStateAmountSuisse;
      if (rootState.wallet.isWalletConnected) {
        return Math.min(max, rootGetters['wallet/getAvailableTokensToBuyWithWallet'])
      } else return max;
    },
    blockchainFee(state, getters, rootState, rootGetters) {
      let contract = getters.getActiveContract
      if(!contract) {
        return 0
      }
      let chosenToken = rootState.wallet.chosenToken;

      if(!chosenToken) {
        return 0
      }

      if(chosenToken.address === contract.stableCoinAddress && contract.networkType === 'EVM') {
        return 0
      }

      if(contract.currency === 'ETH') {
        return 50
      }

      return 5;
    }
  },

  state: defaultState()
}
