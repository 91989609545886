const Statistics = [
    {
        value: '4.000.000',
        title: 'PRIVATE ROUND',
        percentLabel: '2%',
        barWidth: '25%'
    },
    {
        value: '28.000.000',
        title: 'PRE-SALE',
        percentLabel: '14%',
        barWidth: '80%'
    },
    {
        value: '4.000.000',
        title: 'public',
        percentLabel: '2%',
        barWidth: '25%'
    },
    {
        value: '20.000.000',
        title: 'team',
        percentLabel: '10%',
        barWidth: '60%'
    },
    {
        value: '16.000.000',
        title: 'advisors + partners',
        percentLabel: '8%',
        barWidth: '50%'
    },
    {
        value: '22.000.000',
        title: 'staking',
        percentLabel: '11%',
        barWidth: '65%'
    },
    {
        value: '8.000.000',
        title: 'airdrops + rewards',
        percentLabel: '4%',
        barWidth: '35%'
    },
    {
        value: '22.000.000',
        title: 'marketing',
        percentLabel: '11%',
        barWidth: '65%'
    },
    {
        value: '24.000.000',
        title: 'ecosystem fund',
        percentLabel: '12%',
        barWidth: '70%'
    },
    {
        value: '16.000.000',
        title: 'liquidity',
        percentLabel: '8%',
        barWidth: '50%'
    },
    {
        value: '20.000.000',
        title: 'research + development',
        percentLabel: '10%',
        barWidth: '60%'
    },
    {
        value: '16.000.000',
        title: 'reserves',
        percentLabel: '8%',
        barWidth: '50%'
    },

];

export default Statistics;
