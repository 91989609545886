<template>
  <div class="partnership-section">

    <div class="main-container">

      <div class="content">

        <div class="text-container">

          <div class="title">
            <div class="heading-l-regular m-heading-m-regular">Launching</div>

            <div class="heading-l-regular m-heading-m-regular">The Future of Web3</div>
          </div>

          <div class="description body-l-regular m-caption-l-regular">Gain early access to high-potential projects
            with
            transparent token distributions and enjoy a secure platform backed by our top industry partnerships.
          </div>

        </div>

        <div class="boxes-container">

          <div class="box-wrapper">

            <div class="boxes">

              <support-box
                label="KYC by"
                :logo="sumSub"
                link="https://sumsub.com/"
              />

              <support-box
                class="fix-width"
                label="Financial Audit by"
                :logo="grantThornton"
                link="https://www.grantthornton.com/"
              />

              <support-box
                class="extended"
                label="Backed by"
                :logo="digitalTwins"
                :logo2="ecoSystem"
                link="https://www.digitaltwinscapital.com/"
                link2="https://ecosystemventures-ice.com/"
              />

            </div>


          </div>

        </div>

      </div>
      
    </div>

  </div>
</template>

<script>
import SupportBox from "@/components/SupportBox.vue";

export default {
  name: "PartnershipSection",
  components: {SupportBox},

  data: () => ({
    grantThornton: "/assets/support/grant-thornton-logo.svg",
    digitalTwins: "/assets/support/digital-twins-logo.svg",
    sumSub: "/assets/support/sum-sub-logo.svg",
    ecoSystem: "/assets/support/eco-system.svg",
  }),

};
</script>

<style lang='scss'>
@import "PartnershipSection";
</style>
