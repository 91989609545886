<template>
  <div class="transaction-complete">

    <div class="top-content">

      <div class="content">

        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#6F757B"/>
          <path
            d="M45.6875 22.3125C46.0625 22.6875 46.0625 23.375 45.6875 23.75L28.6875 40.75C28.3125 41.125 27.625 41.125 27.25 40.75L18.25 31.75C17.875 31.375 17.875 30.6875 18.25 30.3125C18.625 29.9375 19.3125 29.9375 19.6875 30.3125L28 38.625L44.25 22.3125C44.625 21.9375 45.3125 21.9375 45.6875 22.3125Z"
            fill="#00BDD6"/>
        </svg>

        <div class="title heading-m-regular m-heading-m-regular">SUCCESS</div>

      </div>

    </div>

    <div class="middle-content">
      <div class="title body-l-regular m-body-l-regular">The transaction was completed successfully.</div>
      <div class="caption-l-regular m-caption-l-regular">Tokens will appear in your dashboard shortly,<br>
        typically within 1-5 minutes.
      </div>
    </div>

    <div class="bottom-content">

      <div class="button-container">

        <sbc-button
          class="hide-mobile"
          button-label="Dashboard"
          :button-medium="true"
          :is-black="true"
          @click="$emit('goToDashboard')"
        />

        <sbc-button
          class="show-mobile"
          button-label="Dashboard"
          :button-medium="true"
          :is-dark-gray="true"
          @click="$emit('goToDashboard')"
        />

        <sbc-button
          button-label="Details"
          :button-medium="true"
          :is-black="true"
          icon-right="fa-arrow-up-right-from-square"
          icon-type="light"
          :href="transactionDetailsUrl"
        />

      </div>

    </div>

  </div>

</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: "TransactionComplete",
  components: {SbcButton},
  props: {
    transaction: Object
  },
  computed: {
    transactionDetailsUrl() {
      return this.transaction?.contract?.explorerUrl + this.transaction?.transactionHash
    }
  }
};
</script>

<style lang='scss'>
@import "TransactionComplete";
</style>
