<template>

  <div class="transaction-failed">

    <div class="top-content">

      <div class="content">

        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#6F757B"/>
          <path
            d="M43.75 19.6875L33.3125 32L43.75 44.375C44.125 44.8125 44.0625 45.4375 43.625 45.8125C43.1875 46.125 42.5625 46.0625 42.1875 45.6875L32 33.5625L21.75 45.6875C21.375 46.125 20.75 46.1875 20.3125 45.8125C19.875 45.4375 19.875 44.8125 20.1875 44.375L30.6875 32L20.1875 19.6875C19.875 19.25 19.9375 18.625 20.3125 18.25C20.75 17.9375 21.375 17.9375 21.75 18.375L32 30.5L42.1875 18.375C42.5625 17.9375 43.1875 17.9375 43.625 18.25C44.0625 18.625 44.0625 19.25 43.75 19.6875Z"
            fill="#D7001D"/>
        </svg>

        <div class="title heading-m-regular m-heading-m-regular">FAIL</div>

      </div>

    </div>

    <div class="middle-content">
      <div class="title body-l-regular m-body-l-regular">The transaction was unsuccessful.</div>
      <div class="caption-l-regular m-caption-l-regular">There was an error processing your transaction.<br>
        Please try again.
      </div>
    </div>

    <div class="bottom-content">

      <div class="button-container">

        <sbc-button
          button-label="Back to Dashboard"
          :button-medium="true"
          :is-black="true"
          @click="$emit('goToDashboard')"
        />

      </div>

    </div>

  </div>

</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: "TransactionFailed",
  components: {SbcButton},
};
</script>

<style lang='scss'>
@import "TransactionFailed";
</style>
