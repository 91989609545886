<template>
  <div class="blueprint-section">

    <div class="main-container-2 spacing">

      <div class="display-s-regular text-highlight m-heading-m-regular">Blueprint</div>
      <div class="display-s-regular m-heading-m-regular">for Our Success</div>

      <sbc-carousel :items="blueprintBoxes" :min-width="508" :enable-scroll-bar="true">

        <template #listItem="{item, index}" :key="index">

          <blueprint-box
              :blueprint-box="item"
          />

        </template>

      </sbc-carousel>
    </div>

  </div>
</template>

<script>
import SbcCarousel from "@/components/general/SbcCarousel.vue";
import BenefitBox from "@/components/BenefitBox.vue";
import BlueprintBox from "@/components/BlueprintBox.vue";
import blueprintBoxes from "@/services/blueprintBoxes.js";

export default {
  name: "BlueprintSection",
  components: {BlueprintBox, BenefitBox, SbcCarousel},
  data() {
    return {
      blueprintBoxes,
    }
  }
};
</script>

<style lang='scss'>
@import "BlueprintSection";
</style>