export default {

    mounted(el, binding, vnode) {
        // console.log('BINDING', binding)
        let scaling = binding.value || 0.1;
        let prevFocusScale = null
        el.trackScrollTrigger = () => {
            let rect = el.getBoundingClientRect()
            let offset = 0;
            let windowWidth = window.innerWidth || document.documentElement.clientWidth;
            let inView = (
                rect.width > 0 &&
                rect.left > (-rect.width) &&
                rect.left + (rect.width * 0.1) <= windowWidth &&
                rect.height > 0 &&
                rect.top >= 0 &&
                (rect.bottom + offset - ( rect.height * 0.4)) <= (window.innerHeight || document.documentElement.clientHeight)
            )
            if (inView) {
                let focusScale = 1 - (Math.abs((windowWidth/2) - (rect.left + rect.width/2)) / (windowWidth/2))
                // if(focusScale < 0) {
                //     focusScale = 0
                // }
                if(focusScale > 1) {
                    focusScale = 1
                }
                if(focusScale !== prevFocusScale) {
                    prevFocusScale = focusScale;
                    el.style['transform'] = `scale(${1 + (scaling * focusScale)})`
                    el.style['opacity'] = `${0.5 + (0.5 * focusScale)}`
                }

            } else {
                el.classList.remove('el-focused')
            }
        }

        el.interval = setInterval(() => {
            el.trackScrollTrigger(el)
        }, 35)

    },
    beforeUnmount(el, binding, vnode) {
        clearInterval(el.interval)
    },
}