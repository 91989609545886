<template>
  <div class="user-dashboard">

    <user-total
        :text="currentAddress"
    />

    <transactions-list/>

    <rocket-fuel-log/>

  </div>

</template>

<script>
import DividerText from "@/components/hero-components/DividerText.vue";
import TransactionsList from "@/components/hero-components/TransactionsList.vue";
import SbcButton from "@/components/general/SbcButton.vue";
import KYCBox from "@/components/hero-components/KYCBox.vue";
import TotalBox2 from "@/components/hero-components/TotalBox2.vue";
import AddressBox from "./AddressBox.vue";
import {mapGetters, mapState} from "vuex";
import {Wallet} from "@/services/wallet.js";
import {formatUnits} from "ethers";
import UserTotal from "@/components/hero-components/UserTotal.vue";
import addressBox from "./AddressBox.vue";
import RocketFuelLog from "@/components/hero-components/RocketFuelLog.vue";

export default {
  name: "UserDashboard",
  components: {RocketFuelLog, UserTotal, AddressBox, TotalBox2, KYCBox, SbcButton, TransactionsList, DividerText},

  computed: {
    ...mapState({
      isWalletConnected: state => state.wallet.isWalletConnected,
      currentAddress: state => state.wallet.currentAddress,
      self: state => state.presale.self,
    }),
    ...mapGetters('wallet', ["isKYCApproved"]),
  },

  methods: {
    formatUnits,
  }
}
</script>

<style lang="scss">
@import "UserDashboard";
</style>
