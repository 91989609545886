<template>
  <div v-if="!showCountdownTimer" class="secondary-menu show-mobile">

    <div class="tabs-wrapper" :class="{'is-active' : showUserDashboard}">

      <div
        v-if="!isWalletConnected"
        class="buy-suisse-1 action-button m-caps-s-medium"
        @click="navigateToConnectWallet"
      >
        <sbc-icon
          icon="fa-coins"
          type="solid"
        />
        <div>BUY SUISSE</div>
      </div>

      <div
        v-if="isWalletConnected"
        class="buy-suisse-2 action-button m-caps-s-medium"
        @click="goToPayBox"
      >
        <sbc-icon
          icon="fa-coins"
          type="solid"
        />
        <div class="text">BUY SUISSE</div>
      </div>

      <div
        v-if="isWalletConnected"
        class="user-profile action-button m-caps-s-medium"
        @click="goToUserDetails"
      >
        <sbc-icon
          icon="fa-user"
          type="solid"
        />
        <div class="text">User Profile</div>
      </div>

      <div v-if="isWalletConnected" class="active-bar" :class="{'active' : showUserDashboard}">
        <svg xmlns="http://www.w3.org/2000/svg" width="184" height="9" viewBox="0 0 184 9" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M184 0H0V1H84L92 9L100 1H184V0Z" fill="#00BDD6"/>
        </svg>
      </div>

    </div>

  </div>

</template>

<script>
import SbcIcon from "@/components/general/SbcIcon.vue";
import SbcButton from "@/components/general/SbcButton.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "SecondaryMenu",
  components: {SbcButton, SbcIcon},

  computed: {
    ...mapState({
      isWalletConnected: state => state.wallet.isWalletConnected,
      showUserDashboard: state => state.general.showUserDashboard
    }),
    ...mapGetters('presale', ["getNextStage"]),
    ...mapGetters('presale', ["getActivePresaleStage"]),
    showCountdownTimer() {
      return false
    }
  },

  methods: {
    navigateToConnectWallet() {
      this.$router.push({name: 'HomePage', hash: '#connect-wallet'});
    },

    goToPayBox() {
      this.$router.push({name: 'HomePage', hash: '#pay-box'});
      this.$store.dispatch('general/showUserDashboard', false)
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },

    goToUserDetails() {
      this.$store.dispatch('general/showUserDashboard', true)
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },

  beforeUnmount() {
    console.log('unmount')
  },

  created() {
    console.log('CREATED')
  }
};
</script>

<style lang='scss'>
@import "SecondaryMenu";
</style>
