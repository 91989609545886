function throttle(cb, delay = 250) {
  let shouldWait = false

  return (...args) => {
    if (shouldWait) return

    cb(...args)
    shouldWait = true
    setTimeout(() => {
      shouldWait = false
    }, delay)
  }
}

function wait(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

function isMobile() {
  return (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i))
}


/**
 *
 * @param number
 * @param minimumFractionDigits
 * @returns {string|null}
 * Intl.DateTimeFormat().resolvedOptions().locale
 */
function formatAmount(number, minimumFractionDigits = 2) {
  if (!number) return null;
  let nf = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: minimumFractionDigits,
  });
  return nf.format(number)
}

function formatTokenAmount(number, tokenName) {
  if (!number) return null;
  let minimumFractionDigits = 2;
  switch (tokenName){
    case 'ETH':
      minimumFractionDigits = 4;
      break;
    case 'BSC':
    case 'BNB':
      minimumFractionDigits = 2;
      break;
    case 'NULS':
      minimumFractionDigits = 2;
      break;
    default:
      minimumFractionDigits = 2;
  }

  let nf = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: minimumFractionDigits,
  });
  return nf.format(number)
}

function formatPrice(price, minimumFractionDigits = 2) {

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency', currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: minimumFractionDigits,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(Number(price));
}

const dateFormatter = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
})

const timeFormatter = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, {
  hour: "numeric", minute: "numeric", second: "numeric",
})


const dateTimeFormatter = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour: "numeric", minute: "numeric",
})

function formatDate(date) {
  if (!date) return null;
  return dateFormatter.format(date)
}

function formatTime(date) {
  if (!date) return null;
  return timeFormatter.format(date)
}

function formatDateTime(date) {
  if (!date) return null;
  return dateTimeFormatter.format(date);
}


const getEllipsisStr = (str, charactersFront = 6, charactersLength = 14, charactersBack = null) => {
  charactersBack = charactersBack ?? charactersFront;
  if (str?.length > charactersLength) {
    return str.slice(0, charactersFront) + '...' + str.slice(-charactersBack)
  }
  return str
}

function isObject(A) {
  return (typeof A === "object" || typeof A === 'function') && (A !== null);
}


const uppercaseFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

const getAssetLocation = (assetUrl) => {
  if (!assetUrl) return null;
  if (assetUrl.startsWith('http')) return assetUrl;

  return import.meta.env.VITE_DESK_ASSETS_URL + assetUrl;
  // return location.replaceAll("//", '/')
}


export {
  throttle,
  isMobile,
  formatPrice,
  formatDate,
  formatTime,
  formatDateTime,
  formatAmount,
  formatTokenAmount,
  getEllipsisStr,
  isObject,
  wait,
  uppercaseFirstLetter,
  getAssetLocation,

}
