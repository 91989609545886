import {createStore} from 'vuex'
import createPersistedState from "vuex-plugin-persistedstate";
import wallet from './wallet';
import presale from './presale';
import general from './general';

const Store = createStore({
  modules: {
    wallet, presale,
    general
  }, plugins: [createPersistedState({
    key: 'sbc-landing', paths: ["presale"]
  })],
  mutations: {
    updateTime(state) {
      state.currentTime = Date.now()
    },
  },

  state: {
    currentTime: Date.now(),
  }
})

export default Store

export {Store}
