<template>
  <div class="transactions-list">

    <div class="top-bar">

      <div class="title">
        <div class="body-s-regular">My <span class="text-white">Transactions</span></div>
      </div>

    </div>

    <div class="transaction-wrapper">

      <transaction-row
        v-if="myPresaleTransactions.length"
        v-for="transaction in myPresaleTransactions"
        :transaction="transaction"
      />

      <div v-else class="error-wrapper">
        <div class="error caption-l-regular">There are no transactions from your account yet.
          Once you purchase SUISSE tokens, your transactions will appear on this list.
        </div>
      </div>


    </div>


  </div>
</template>

<script>
import TransactionRow from "@/components/hero-components/TransactionRow.vue";
import {mapState} from 'vuex';

export default {
  name: "TransactionsList",
  components: {TransactionRow},

  computed: {
    ...mapState({
      myPresaleTransactions: state => state.presale.myPresaleTransactions
    })
  }
};
</script>

<style lang='scss'>
@import "TransactionsList";
</style>
