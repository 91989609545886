<template>
  <div class="faq-section" id="purpose">

    <div class="main-container-2 spacing">

      <div class="display-s-regular hide-mobile">The Most Burning Questions</div>
      <div class="text-highlight display-s-regular hide-mobile">about SuissePad</div>

      <div class="display-s-regular m-heading-m-regular show-mobile">The Most Burning</div>
      <div class="title-mobile display-s-regular m-heading-m-regular show-mobile">Questions <span
          class="text-highlight">about</span>
      </div>
      <div class=" display-s-regular m-heading-m-regular show-mobile text-highlight">SuissePad</div>

      <div class="faq-boxes">
        <f-a-q-box
            v-for="(box, index) in faqBoxes"
            :key="index"
            :faq="box"
            :show-answer="activeBoxId === box.id"
            @setActiveBox="setActiveBox"
        />
      </div>

    </div>

  </div>
</template>

<script>
import FAQBox from "@/components/FAQBox.vue";
import {faqBoxes} from "@/services/faqBoxes.js";

export default {
  name: "FAQSection",
  components: {FAQBox},
  data() {
    return {
      faqBoxes,
      activeBoxId: null
    }
  },

  methods: {
    setActiveBox(id) {
      this.activeBoxId = id;
    }
  }
};
</script>

<style lang='scss'>
@import "FAQSection";
</style>