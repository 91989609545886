<template>
  <div class="unsubscribe-page">

    <br/>
    <br/>

    <template v-if="!isUnsubscribed">
      <div style="color: white; text-align: center" class="body-l-regular m-caption-l-regular">Are you sure you want to
        unsubscribe?
      </div>

      <!--    <div class="subscription-lists-container">-->
      <!--      <div v-for="subscriptionList in subscriptionLists" :key="subscriptionList.id">-->

      <!--        <label class="paragraph-1-regular">-->
      <!--          <input class="checkbox" type="checkbox"-->
      <!--                 v-model="unsubscribeIds"-->
      <!--                 :value="subscriptionList.id"-->
      <!--                 name="unsubscribe_list"-->
      <!--          > {{ subscriptionList.title }}-->
      <!--        </label>-->

      <!--      </div>-->
      <!--    </div>-->

      <br/>
      <br/>

      <sbc-button
        button-icon="fa-bell-slash"
        button-label="Unsubscribe"
        :is-primary="true"
        @click="unsubscribe"
      />

    </template>

    <div v-else style="color: white; text-align: center" class="body-l-regular m-caption-l-regular">
      You were successfully unsubscribed!
    </div>

  </div>
</template>

<script>
import {getNotificationRecipient, unsubscribeFromLists} from "../graphql/index.js";
import SbcButton from "../components/general/SbcButton.vue";

export default {
  name: "UnsubscribePage",
  components: {SbcButton},
  props: {
    token: {
      type: String,
      required: true,
    }
  },
  data: () => ({subscriptionLists: [], unsubscribeIds: [], isUnsubscribed: false}),
  methods: {
    listSubscriptionList() {
      getNotificationRecipient(this.token, {includeLists: true})
        .then(recipient => this.subscriptionLists = recipient.subscriptionLists)
    },
    unsubscribe() {
      return unsubscribeFromLists({token: this.token, listIds: this.unsubscribeIds})
        .then(() => {
          this.unsubscribeIds = []
          this.listSubscriptionList()
          this.isUnsubscribed = true;
        })
    }
  },
  created() {
    this.listSubscriptionList()
    if (this.$route.query.listId) {
      this.unsubscribeIds.push(this.$route.query.listId)
    }
  }
}
</script>

<style lang="scss">
@import "UnsubscribePage";
</style>
