import {gql} from '@apollo/client/core';

export default gql`
  mutation unsubscribeFromLists($input:UnsubscribeFromListsInput!) {
    unsubscribed: unsubscribeFromLists(input: $input)
  }
`;



