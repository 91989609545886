/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'


// ---regular icons----------------------------------------

import {
    faX as faX,
    faBars as faBars,
    faChevronsRight as faChevronsRight,
    faArrowUpRight as faArrowUpRight,
    faChevronsLeft as faChevronsLeft,
    faCheck as faCheck,

} from '@fortawesome/pro-regular-svg-icons'

// ---light icons----------------------------------------
import {
    faChevronsRight as faChevronsRightL,
    faLayerPlus as faLayerPlusL,
    faMagnifyingGlass as faMagnifyingGlassL,
    faCircleCheck as faCircleCheckL,
    faCode as faCodeL,
    faGlobeStand as faGlobeStandL,
    faLink as faLinkL,
    faPrintMagnifyingGlass as faPrintMagnifyingGlassL,
    faCircleInfo as faCircleInfoL,
    faCopy as faCopyL,
    faArrowUpRightFromSquare as faArrowUpRightFromSquareL,
    faCoins as faCoins,
    faUser as faUserL,
    faWallet as faWalletL,

} from '@fortawesome/pro-light-svg-icons'

// ---solid icons----------------------------------------
import {
    faChevronsRight as faChevronsRightS,
    faLocationDot as faLocationDotS,
    faEnvelope as faEnvelopeS,
    faCoins as faCoinsS,
    faUser as faUserS,


} from '@fortawesome/pro-solid-svg-icons'

// ---thin icons----------------------------------------
import {
    faChevronsRight as faChevronsRightT,
    faLayerPlus as faLayerPlusT,
    faMagnifyingGlass as faMagnifyingGlassT,
    faCircleCheck as faCircleCheckT,
    faCode as faCodeT,
    faGlobeStand as faGlobeStandT,
    faLink as faLinkT,
    faPrintMagnifyingGlass as faPrintMagnifyingGlassT,

} from '@fortawesome/pro-thin-svg-icons'

library.add(faChevronsRight)
library.add(faChevronsRightL)
library.add(faChevronsRightS)
library.add(faChevronsRightT)
library.add(faBars)
library.add(faX)
library.add(faArrowUpRight)
library.add(faLocationDotS)
library.add(faEnvelopeS)
library.add(faLayerPlusT)
library.add(faLayerPlusL)
library.add(faMagnifyingGlassL)
library.add(faMagnifyingGlassT)
library.add(faCircleCheckL)
library.add(faCircleCheckT)
library.add(faCodeL)
library.add(faCodeT)
library.add(faGlobeStandT)
library.add(faGlobeStandL)
library.add(faLinkL)
library.add(faLinkT)
library.add(faPrintMagnifyingGlassT)
library.add(faPrintMagnifyingGlassL)
library.add(faChevronsLeft)
library.add(faCheck)
library.add(faCircleInfoL)
library.add(faCopyL)
library.add(faArrowUpRightFromSquareL)
library.add(faCoins)
library.add(faUserL)
library.add(faWalletL)
library.add(faCoinsS)
library.add(faUserS)

export default FontAwesomeIcon;


