import benefitIcon1 from '/assets/icons/benefit/layer-plus.svg'
import benefitIcon2 from '/assets/icons/benefit/print-magnifying-glass.svg'
import benefitIcon3 from '/assets/icons/benefit/circle-check.svg'
import benefitIcon4 from '/assets/icons/benefit/code.svg'
import benefitIcon5 from '/assets/icons/benefit/globe-stand.svg'
import benefitIcon6 from '/assets/icons/benefit/link.svg'

const BlueprintBoxes = [
  {
    value: 'Q1',
    title: 'Legal + Setup',
    state: true,
    list: [
      "Trademark and Incorporation",
      "Regulatory Compliance",
      "Developed a Modular Protocol Framework",
      "Built the Launchpad MVP",
    ]
  },
  {
    value: 'Q2',
    title: 'Development',
    state: true,
    list: [
      "Private Beta Release",
      "Continuous Protocol Development",
      "Internal Testing",
      "Ecosystem Growth",
    ]
  },
  {
    value: 'Q3',
    title: 'Go-To-Market',
    state: true,
    list: [
      "Marketing Campaign",
      "Pre-Sale Whitelist",
      "Community Building Campaigns",
      "Project Screening",
    ]
  },
  {
    value: 'Q4',
    title: 'Launch + Acceleration',
    list: [
      "Token Pre-Sale",
      "Incubation Services",
      "Feedback-based Platform Upgrades",
      "Consistent Community Growth",
    ]
  },
  {
    value: '2025',
    title: 'Growth',
    list: [
      "Token Generation Event (TGE)",
      "AI Launchpad Assistant",
      "Innovation and Growth Accelerator",
      "Corporate Matchmaker",
    ]
  },
];

export default BlueprintBoxes;
