import Router from '../router'

function isObject(A) {
  return (typeof A === "object" || typeof A === 'function') && (A !== null);
}

export default {

  mounted(el, binding, vnode) {
    let value = binding.value;
    let id = binding.value
    let delay = 0;
    let offsetHeight = false
    let autoDisplay = false
    if (isObject(binding.value)) {
      value = binding.value.class
      id = binding.value.id
      delay = binding.value.delay || 0
      offsetHeight = !!binding.value.offsetHeight
      autoDisplay = !!binding.value.autoDisplay
    }

    if (delay) {
      el.style['transition-delay'] = `${delay}ms`
    }

    el.onScroll = () => {
      let rect = el?.getBoundingClientRect()
      if (!rect) {
        return;
      }
      let offset = 0;
      if (offsetHeight) {
        offset = rect.height;
      }
      // console.log('RECT->TOP', rect.top)
      // let inView = (
      //   rect.width > 0 &&
      //   rect.height > 0 &&
      //   rect.top >= 0 &&
      //   (rect.bottom + offset - (rect.height * 0.7)) <= (window.innerHeight || document.documentElement.clientHeight)
      // )
      let inView = rect.top < 0 ? (
          rect.top > -400 &&
          rect.top <= 0
      ) :(
        rect.top >= 0 &&
        rect.top < 300
      )
      let hash = id ? `#${id}` : '';
      let disableScrollTimeout = null;
      if (inView && !window.disableScroll) {
        if (Router.currentRoute.value.hash !== hash) {
          clearTimeout(disableScrollTimeout)

          window.disableScroll = true

          Router.replace({
            name: Router.currentRoute.value.name,
            params: Router.currentRoute.value.params,
            query: Router.currentRoute.value.query,
            hash: hash,
          }).finally(() => {
            disableScrollTimeout = setTimeout(() => {
              window.disableScroll = false
            }, 300)
          })

        }

        el.classList.add(id)
      } else {
        el.classList.remove(id)
      }
    };

    window.addEventListener('scroll', el.onScroll)
    setTimeout(() => {
      el.onScroll()
    })

    el.classList.add(`${value}-before-enter`)
  },

  beforeUnmount(el, binding, vnode) {
    window.removeEventListener('scroll', el.onScroll)
  }
}
