import {Wallet} from "@/services/wallet.js";
import {sendForVerification, signInWithEthereum} from "@/services/walletAuth.js";
import {parseEther, parseUnits} from "ethers";

const defaultState = () => ({
  walletKind: null,
  currentAddress: null,
  currentNetworkId: null,
  isWalletConnected: false,
  isNabox: false,
  transaction: null,
  currentTokenBalance: null,
  currentTokenPrice: null,
  chosenToken: null,
  chosenChainId: null,
  prevChosenToken: null,
  prevChosenChainId: null,
  siweAccessToken: null,
  isWalletLoading: false,
  isBuyingInProgress: false,
  kycAccessToken: null,
  showConnectWalletDialog: false
})


export default {
  namespaced: true, actions: {
    calculateCurrentTokenBalance({commit, dispatch, state, rootState, rootGetters},) {
      let promise = Promise.resolve();
      let activeContract = rootGetters["presale/getActiveContract"];
      if (!state.chosenToken) {
        return Promise.reject();
      }

      if (state.chosenToken.address === activeContract.address) {
        if (state.isWalletConnected) {
          // console.trace('getCurrentBalance', state.chosenToken)
          promise = Wallet.getCurrentBalance()
            .then((res) => {
              commit('setCurrentTokenBalance', parseUnits(res.toString(), 18))
            })
            .catch((err) => {
              console.log('ERRRRR', err)
            })
        }
        dispatch('calculateCurrentTokenPrice', activeContract);
      } else {
        if (state.isWalletConnected) {
          promise = Wallet.getTokenBalance(state.chosenToken.address, state.currentAddress, activeContract)
            .then((res) => {
              commit('setCurrentTokenBalance', parseUnits(res.toString(), 18));
            })
        }
        commit('setCurrentTokenPrice', 10 ** 8);
      }
      return promise.then(() => {
        return dispatch('presale/updatePresaleTransaction', rootState.presale.presaleTransaction.amountOfTokensToPay, {root: true});
      })

    }, calculateCurrentTokenPrice({commit}, contract) {
      return Wallet.getTokenPrice(contract.priceFeedAddress, contract.rpcUrl)
        .then((price) => {
          commit('setCurrentTokenPrice', price)
        })
    }, signInWithEthereum({commit}) {
      return signInWithEthereum()
        .then(({message, signature, preSignedToken, address, pubKey, isNuls}) => {
          return sendForVerification({message, signature, preSignedToken, address, pubKey, isNuls})
            .then(({siweAccessToken, kycAccessToken}) => {
              commit('setSiweAccessToken', siweAccessToken)
              commit('setKycAccessToken', kycAccessToken)
            })
        })
    },
    makeDeposit({dispatch, commit, state, rootState, rootGetters},) {
      if (!rootState.presale.presaleTransaction.amountOfTokensToPay) {
        commit('presale/updatePresaleTransactionErrors', {'err_no_amount': true}, {root: true})
      }


      let activeContract = rootGetters["presale/getActiveContract"];
      let presaleTransactionData = rootState.presale.presaleTransaction;
      let promise = Promise.resolve();
      if (state.isWalletConnected) {
        if (state.chosenToken.address === activeContract.address) {
          promise = Wallet.depositCoin(state.chosenToken.address, rootState.presale.presaleTransaction.amountOfTokensToPay, rootState.presale.self?.signature,  rootState.presale.self?.nulsSignature)
            .then(({receipt}) => {
              return dispatch('presale/createPresaleTransaction', {
                contractId: rootGetters["presale/getContractByChainId"](state.chosenChainId).id,
                currency: state.chosenToken.currency,
                isNabox: state.isNabox,
                transactionHash: receipt.hash,

              }, {root: true})
            })
        } else {
          promise = Wallet.depositCoinUSDC(activeContract.address, state.chosenToken.address, rootState.presale.presaleTransaction.amountOfTokensToPay, rootState.presale.self?.signature, activeContract)
            .then(({receipt}) => {
              return dispatch('presale/createUSDCPresaleTransaction', {
                contractId: activeContract.id,
                currency: state.chosenToken.currency,
                isNabox: state.isNabox,
                transactionHash: receipt.hash,
                value: parseEther(presaleTransactionData.amountOfTokensToPay.toString()).toString()
              }, {root: true})
            })
        }

        return promise
          .then((res) => {
            dispatch('presale/listPresaleTransactions', {filter: {isReferral: false}}, {root: true})
            dispatch('presale/listPresaleStages', {}, {root: true})
            dispatch('presale/listRocketFuelPresaleTransactions', {}, {root: true})
            commit('presale/resetPresaleTransaction', {}, {root: true})
            dispatch('presale/selfPresale', {}, {root: true})
            dispatch('calculateCurrentTokenBalance')
            return Promise.resolve(res);
          })
          .catch((err) => {
            commit('presale/setTransactionErrorMessages', Wallet.contractErrorMapping(err), {root: true})
            return Promise.reject();
            console.log("MAKE DEPOSIT ERROR::: ", Wallet.contractErrorMapping(err));
          })
      }
    }, updateChosenTokenAndNetwork({commit, rootGetters, dispatch, rootState}, {chainId, chosenToken, shouldExecute=true}) {
      commit('setChosenChainId', chainId);


      if (chosenToken) {
        commit('setChosenToken', chosenToken)
      } else {
        commit('setChosenToken', {
          address: rootGetters["presale/getActiveContract"].address,
          currency: rootGetters["presale/getActiveContract"].currency
        })
      }
      if(shouldExecute) {
        dispatch('presale/updatePresaleTransaction', null, {root: true})
        return dispatch('calculateCurrentTokenBalance');
      }
    }
  }, mutations: {
    setIsWalletConnected(state, isWalletConnected) {
      state.isWalletConnected = isWalletConnected;
    }, setCurrentNetworkId(state, currentNetworkId) {
      state.currentNetworkId = currentNetworkId;
    }, setCurrentAddress(state, currentAddress) {
      state.currentAddress = currentAddress;
    }, setCurrentTokenBalance(state, currentTokenBalance) {
      state.currentTokenBalance = currentTokenBalance;
    }, setCurrentTokenPrice(state, currentTokenPrice) {
      state.currentTokenPrice = currentTokenPrice;
    }, setChosenToken(state, token) {
      state.chosenToken = token
    }, setChosenChainId(state, chainId) {
      state.chosenChainId = chainId
    }, setSiweAccessToken(state, siweAccessToken) {
      state.siweAccessToken = siweAccessToken;
    },
    setIsWalletLoading(state, isWalletLoading) {
      state.isWalletLoading = isWalletLoading;
    },
    setIsBuyingInProgress(state, isBuyingInProgress) {
      state.isBuyingInProgress = isBuyingInProgress;
    },
    setKycAccessToken(state, kycAccessToken) {
      state.kycAccessToken = kycAccessToken;
    },
    setShowConnectWalletDialog(state, showConnectWalletDialog) {
      state.showConnectWalletDialog = showConnectWalletDialog;
    },
    setIsNabox(state, isNabox) {
      state.isNabox = isNabox
    }, resetState(state) {
      Object.assign(state, {
        ...defaultState(),
        chosenToken: state.chosenToken,
        chosenChainId: state.chosenChainId,
        currentTokenPrice: state.currentTokenPrice
      })
    }
  }, getters: {
    isKYCApproved: (state) => state.KYCStatus === 'APPROVED',
    getAvailableTokensToBuyWithWallet: (state, getters, rootState, rootGetters) => {
      if (!state.isWalletConnected || !rootGetters['presale/getActivePresaleStage']) {
        return null;
      }
      return ((parseInt(state.currentTokenBalance) * (parseInt(state.currentTokenPrice) || 1)) / rootGetters['presale/getActivePresaleStage'].price / 10 ** 18) - rootGetters['presale/blockchainFee'];


    }
  },


  state: defaultState()
}
