const chainIcons = {
  31337: '/assets/tokens/ETH.svg',
  1: '/assets/tokens/ETH.svg',
  41337: '/assets/tokens/BNB.svg',
  56: '/assets/tokens/BNB.svg',
  0: '/assets/tokens/NULS.svg'

};


const tokenIcons = {
  "ETH": '/assets/tokens/ETH.svg',
  "USDT": '/assets/tokens/USDT.svg',
  "BSC": '/assets/tokens/BNB.svg',
  "BNB": '/assets/tokens/BNB.svg',
  "NULS": '/assets/tokens/NULS.svg'
};


export {
  chainIcons, tokenIcons
};
