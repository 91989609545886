<template>
  <Teleport to="body">

    <vi-dialog label="Select network" content-class="whitelist-modal" :model-value="modelValue"
               @update:modelValue="$emit('update:modelValue', $event)">

      <div class="select-network">


        <div class="box">
          <div class="heading-s-regular">ETHEREUM</div>
          <!--                          <img :src="tokenIcons[presaleContract.stableCoinCurrency]" alt="">-->
        </div>


      </div>

    </vi-dialog>

  </Teleport>

</template>

<script>

import SbcButton from "@/components/general/SbcButton.vue";
import {mapState} from "vuex";
import {chainIcons, tokenIcons} from '@/services/chainAndTokenIcons.js'

export default {
  name: "SelectNetwork",
  components: {SbcButton},
  props: {
    modelValue: Boolean,
    bitcoinName: String,
    option1: String,
    option2: String,
    option1Icon: String,
    option2Icon: String,
  },

  data: () => ({
    chainIcons,
    tokenIcons,
  }),

  computed: {},

  methods: {}
};
</script>

<style lang='scss'>
@import "SelectNetwork";
</style>
