<template>
  <div style="margin-top: 150px; color: white">
    <button @click="openWallet">Click me</button>
    <button @click="setState">Set stage</button>
    <button @click="unpauseStage">Unpause stage</button>
    <button @click="depositCoin">Deposit coin</button>
    <button @click="depositCoinUSDC">Deposit usdc</button>
    <button @click="getBalance">Get SUISSE Balance</button>
    <button @click="getETHPrice">Get ETH Price</button>
    <button @click="walletAuth">Authenticate wallet</button>

    <p>IS WALLET CONNECTED: {{ isWalletConnected }} </p>
    <p>CURRENT NETWORK: {{ currentNetworkId }} </p>
    <p>CURRENT ADDREESS: {{ currentAddress }} </p>
  </div>
</template>

<script>
import {Wallet} from "@/services/wallet.js";
import {BrowserProvider} from "ethers";
import {mapState} from "vuex";
import {createSiweMessage, signInWithEthereum, sendForVerification} from "@/services/walletAuth.js"

export default {
  computed: {
    ...mapState({
      isWalletConnected: state => state.wallet.isWalletConnected,
      currentNetworkId: state => state.wallet.currentNetworkId,
      currentAddress: state => state.wallet.currentAddress,
      presaleContracts: state => state.presale.presaleContracts,
    })
  },

  methods: {
    walletAuth() {
      signInWithEthereum()
        .then(({message, signature, preSignedToken, address}) =>
          sendForVerification({message, signature, preSignedToken, address})
        )
    },
    openWallet() {
      Wallet.open();
    },
    setState() {
      Wallet.setStage({})
        .then(() => {
          console.log("SET STAGE")
        })
        .catch((err) => {
          console.log("SET STAGE ERR", err)
        })
    },
    numberOfStages() {
      Wallet.numberOfStages({})
        .then(() => {
          console.log("SET STAGE")
        })
        .catch((err) => {
          console.log("SET STAGE ERR", err)
        })
    },
    unpauseStage() {
      Wallet.unpause({})
        .then(() => {
          console.log("UNPAUSE STAGE")
        })
        .catch((err) => {
          console.log("UNPAUSE ERR", err)
        })
    },
    depositCoin() {
      Wallet.depositCoin({})
        .then(({receipt}) => {
          console.log("depositCoin", receipt)
          return this.$store.dispatch('presale/createPresaleTransaction', {
            contractId: "39c61b61-dbf6-495e-82bf-3d52f7eab698", // ova kako ke go prakjam da vidam - needs to be set in store, it will change when we change network
            currency: "ETH",
            isNabox: false,
            transactionHash: receipt.hash
          })
        })
        .catch((err) => {
          console.log("depositCoin ERR", err)
        })
    },
    depositCoinUSDC() {
      Wallet.depositCoinUSDC({})
        .then(({depositAmount, receipt}) => {
          console.log("depositCoinUSDC", depositAmount, receipt)
          return this.$store.dispatch('presale/createUSDCPresaleTransaction', {
            contractId: "39c61b61-dbf6-495e-82bf-3d52f7eab698", // ova kako ke go prakjam da vidam - needs to be set in store, it will change when we change network
            currency: "USDC",
            isNabox: false,
            transactionHash: receipt.hash,
            value: depositAmount.toString()
            // value: parseUnits("240", 18).toString()
          })
        })
        .catch((err) => {
          console.log("depositCoin ERR", err)
        })
    },
    getBalance() {
      Wallet.getBalance({})
        .then(() => {
          console.log("getBalance")
        })
        .catch((err) => {
          console.log("getBalance ERR", err)
        })
    },

    getETHPrice() {
      Wallet.getETHPrice({})
        .then(() => {
          console.log("getBalance")
        })
        .catch((err) => {
          console.log("getBalance ERR", err)
        })
    },
    init() {
      this.$store.commit('wallet/setIsWalletConnected', Wallet.isConnected())
      Wallet.getCurrentNetwork().then(network => {
        console.log("network", network)
        this.$store.commit('wallet/setCurrentNetworkId', network);
      })
      this.$store.commit('wallet/setCurrentAddress', Wallet.getCurrentAddress());

      Wallet.getCurrentBalance('0xdAC17F958D2ee523a2206206994597C13D831ec7', this.currentAddress)
        .then((res) => {
          console.log('BALANCE', res)
        })
    }
  },


  created() {
    Wallet.initilizeModal(this.presaleContracts)
      .then(() => {
        this.init();
      })

    Wallet.modal.subscribeEvents(event => {
      console.log('EVENT::', event.data.event)
      if (event.data.event === 'CONNECT_SUCCESS') {
        this.init();
      } else if (event.data.event === 'MODAL_CLOSE') {
        this.$store.commit('wallet/setIsWalletConnected', Wallet.isConnected())
        if (!Wallet.isConnected()) {
          this.$store.commit('wallet/resetState')
        }
      } else if (event.data.event === 'SWITCH_NETWORK') {
        this.init();
      }
    }) // subscribe to events


  }
}
</script>

<style lang="scss" scoped>

</style>
