<template>
  <a v-focus-in-viewport="0.2" v-if="benefitBox.link" :href="benefitBox.link" target="_blank" class="benefit-box">

    <div class="icon-container" v-html="benefitBox.image"></div>

    <div class="title body-m-regular m-body-m-regular" v-html="benefitBox.title">
    </div>

  </a>

  <div v-focus-in-viewport v-else class="benefit-box">

    <div class="icon-container" v-html="benefitBox.image"></div>

    <div class="title body-m-regular m-body-m-regular" v-html="benefitBox.title">
    </div>

  </div>
</template>

<script>
import focusInViewport from "@/directives/focusInViewport.js";

export default {
  name: "BenefitBox",
  props: {
    benefitBox: {
      type: Object,
    }
  },
  directives: {focusInViewport},

};
</script>

<style lang='scss'>
@import "BenefitBox";
</style>