<template>
  <div class="blueprint-box" :class="{'state' : blueprintBox.state}">

    <div class="left-content">
      <div v-if="blueprintBox.state" class="body-m-medium m-body-s-medium">up until</div>
      <div class="value display-m-regular m-display-s-regular" v-html="blueprintBox.value"></div>
    </div>

    <div class="right-content">

      <div class="title heading-s-regular m-body-l-regular" v-html="blueprintBox.title"></div>

      <ul class="item-list">

        <li v-for="listItem in blueprintBox.list" class="body-s-regular m-body-s-regular" v-html="listItem"></li>

      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: "BlueprintBox",
  props: {
    state: Boolean,
    blueprintBox: Object,
  },
};
</script>

<style lang='scss'>
@import "BlueprintBox";
</style>