<template>
  <div class="connect-wallet" id="connect-wallet" v-if="!self">

    <div class="top-bar">

      <div class="title">
        <div class="heading-s-regular m-heading-s-regular text-white ">Account</div>
      </div>

    </div>

    <div class="checkbox-container">

      <!--      <agree-checkbox/>-->

      <sbc-button
        button-label="CONNECT WALLET"
        :is-primary="true"
        @click="$emit('connectWallet')"
      />

    </div>

  </div>

</template>

<script>
import TotalBox from "@/components/hero-components/TotalBox.vue";
import AgreeCheckbox from "@/components/hero-components/AgreeCheckbox.vue";
import SbcButton from "@/components/general/SbcButton.vue";
import {mapState} from 'vuex';

export default {
  name: "ConnectWallet",
  components: {SbcButton, AgreeCheckbox, TotalBox},
  computed:{
    ...mapState({
      self: state => state.presale.self
    })
  }
};
</script>

<style lang='scss'>
@import "ConnectWallet";
</style>
