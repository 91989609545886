localStorage.clear();
import '@/css/app.scss'
import store from './store'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import ViCommon from "vi-common";
import ViFormBuilder from "vi-form-builder";
import FontAwesomeIcon from "/src/boot/fontawesome.js";
import tooltip from "./directives/tooltip.js";
import "./css/tooltip.css"
import {formatPrice, formatAmount, formatDate, formatTokenAmount,formatDateTime} from "@/services/utils.js";
import {formatUnits, parseUnits} from "ethers";
import Tooltip from "@programic/vue3-tooltip"
import '@programic/vue3-tooltip/dist/index.css'
import * as Sentry from "@sentry/vue";

const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://19e40a88071ebd195134006ca15a9084@o4507821294485504.ingest.de.sentry.io/4507821296320592",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/presale-next\.suisseblockchain\.app/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const options = {};

app.use(Tooltip, options);

app.use(router)
  .use(store)
  .use(ViCommon, {
    toastify: {
      autoClose: 2000, theme: 'colored', hideProgressBar: true
    }
  })
  .use(ViFormBuilder, {})
  .component('font-awesome-icon', FontAwesomeIcon);


app.mount('#app');

app.directive("tooltip", tooltip);

app.config.globalProperties.$isMobile = document.body.clientWidth < 1025
app.config.globalProperties.$formatPrice = formatPrice;
app.config.globalProperties.$formatAmount = formatAmount;
app.config.globalProperties.$formatTokenAmount = formatTokenAmount;
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.$formatDateTime = formatDateTime;
app.config.globalProperties.$formatUnits = formatUnits;
app.config.globalProperties.$parseUnits = parseUnits;
