import {ApolloClient, InMemoryCache, HttpLink, ApolloLink, Observable} from '@apollo/client/core'
import {onError} from "@apollo/client/link/error";
import {Store} from "@/store";

// import fetch from "node-fetch";

let uri = 'http://localhost:3000/';

// if (process.env.API_URL) {
//   uri = process.env.API_URL
// }

if (import.meta.env.VITE_SBC_DESK_API_URL) {
  uri = import.meta.env.VITE_SBC_DESK_API_URL
}


const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri, fetch: globalThis.fetch, // Use the native Fetch API in ESM
});

// Create the apollo client


const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
  let gqlError = graphQLErrors ? graphQLErrors[0] : null;

  if (gqlError && gqlError.extensions && gqlError.extensions.code === 'UNAUTHENTICATED') {
    return new Observable(observer => {
      Store.dispatch('auth/refreshToken')
        .then(response => {
          operation.setContext(({headers = {}}) => ({
            headers: {
              ...headers, authorization: `${response.accessToken}` || null,
            }
          }))
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber)
        })
    });
  } else if (gqlError && gqlError.code === 'INVALID_REFRESH_TOKEN') {
    Store.dispatch('auth/signOut')
      .then(() => {
        window.location = ''
      })
  } else {

  }
});

const newLink = errorLink.concat(httpLink);

const link = new ApolloLink((operation, forward) => {
  let headers = {};

  if (Store.state.wallet.siweAccessToken) {
    headers["presale-token"] = Store.state.wallet.siweAccessToken
  }
  operation.setContext({
    headers
  });
  return forward(operation);
}).concat(newLink);

const apolloClient = new ApolloClient({
  link,

  cache: new InMemoryCache({
    addTypename: false
  })
});

export {
  apolloClient
}
