<template>
  <div class="rocket-fuel-row">

    <div class="transaction-content hide-mobile">

      <div class="date caption-l-regular">{{ $formatDate(transaction.createdAt) }}</div>

      <div class="blockchain">

        <div v-if="points === 'BASE'" class="text caption-l-regular">Purchasing SUISSE Tokens</div>

        <div v-if="points === 'NABOX'" class="text caption-l-regular">Purchasing with Nabox</div>

        <div v-if="points === 'NULS'" class="text caption-l-regular">Purchasing with Nuls</div>

        <div v-if="points === 'WHITELIST'" class="text caption-l-regular">Whitelist Participation</div>

        <div v-if="points === 'MY_REFERRAL'" class="text caption-l-regular">Referral</div>

        <div v-if="points === 'REFERRAL'" class="text caption-l-regular">Level {{ transaction.referralLevel ? transaction.referralLevel : 0 }} Referral</div>

      </div>

      <div class="amount">

        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.8125 18.4375C11.8281 17.5938 16.3281 15.625 18.6719 14.0781C23.5469 10.75 23.8281 5.82812 23.2656 2.73438C20.1719 2.17188 15.25 2.45312 11.9219 7.32812C10.375 9.625 8.35938 14.0781 7.46875 16.0938L9.8125 18.4375ZM6.10938 15.5312C6.20312 15.25 6.34375 14.875 6.53125 14.5H1.75C1.46875 14.5 1.23438 14.4062 1.09375 14.1719C0.953125 13.9375 0.953125 13.6562 1.04688 13.4219L3.4375 8.6875C3.95312 7.65625 4.98438 7 6.10938 7H10.3281C10.4688 6.8125 10.5625 6.67188 10.7031 6.48438C14.5469 0.8125 20.2656 0.625 23.6406 1.28125C24.2031 1.375 24.625 1.79688 24.7188 2.35938C25.375 5.73438 25.1875 11.4531 19.5156 15.2969C19.3281 15.4375 19.1875 15.5312 19 15.6719V19.8906C19 21.0156 18.3438 22.0469 17.3125 22.5625L12.5781 24.9531C12.3438 25.0469 12.0625 25.0469 11.8281 24.9062C11.5938 24.7656 11.5 24.5312 11.5 24.25L11.4531 19.375C11.0781 19.5625 10.7031 19.7031 10.375 19.8438C9.85938 20.0781 9.20312 19.9375 8.78125 19.5156L6.39062 17.1719C5.96875 16.7031 5.82812 16.0938 6.10938 15.5312ZM13 23.0781L16.6562 21.2031C17.1719 20.9688 17.5 20.4531 17.5 19.8906V16.5156C16.0469 17.3125 14.4062 18.0625 13 18.7188V23.0781ZM6.10938 8.5C5.54688 8.5 5.03125 8.82812 4.79688 9.34375L2.92188 13H7.23438C7.89062 11.5938 8.6875 9.95312 9.48438 8.5H6.10938ZM19.375 7.75C19.375 7.14062 18.8594 6.625 18.25 6.625C17.5938 6.625 17.125 7.14062 17.125 7.75C17.125 8.40625 17.5938 8.875 18.25 8.875C18.8594 8.875 19.375 8.40625 19.375 7.75ZM18.25 5.125C19.1875 5.125 20.0312 5.64062 20.5 6.4375C20.9688 7.28125 20.9688 8.26562 20.5 9.0625C20.0312 9.90625 19.1875 10.375 18.25 10.375C17.2656 10.375 16.4219 9.90625 15.9531 9.0625C15.4844 8.26562 15.4844 7.28125 15.9531 6.4375C16.4219 5.64062 17.2656 5.125 18.25 5.125Z"
              fill="#00BDD6"/>
        </svg>
        <div v-if="points === 'BASE'" class="caption-l-regular">+{{ $formatAmount(transaction.basePoints) }}</div>
        <div v-if="points === 'NABOX'" class="caption-l-regular">+{{ $formatAmount(transaction.naboxPoints) }}</div>
        <div v-if="points === 'NULS'" class="caption-l-regular">+{{ $formatAmount(transaction.nulsPoints) }}</div>
        <div v-if="points === 'WHITELIST'" class="caption-l-regular">+{{ $formatAmount(transaction.whitelistReferralPoints) }}</div>
        <div v-if="points === 'MY_REFERRAL'" class="caption-l-regular">+{{ $formatAmount(transaction.referralPoints) }}</div>
        <div v-if="points === 'REFERRAL'" class="caption-l-regular">+{{ $formatAmount(transaction.referralPoints) }}</div>

      </div>

    </div>

    <div class="transaction-content show-mobile">

      <div class="date-blockchain-wrapper">

        <div class="amount">

          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.8125 18.4375C11.8281 17.5938 16.3281 15.625 18.6719 14.0781C23.5469 10.75 23.8281 5.82812 23.2656 2.73438C20.1719 2.17188 15.25 2.45312 11.9219 7.32812C10.375 9.625 8.35938 14.0781 7.46875 16.0938L9.8125 18.4375ZM6.10938 15.5312C6.20312 15.25 6.34375 14.875 6.53125 14.5H1.75C1.46875 14.5 1.23438 14.4062 1.09375 14.1719C0.953125 13.9375 0.953125 13.6562 1.04688 13.4219L3.4375 8.6875C3.95312 7.65625 4.98438 7 6.10938 7H10.3281C10.4688 6.8125 10.5625 6.67188 10.7031 6.48438C14.5469 0.8125 20.2656 0.625 23.6406 1.28125C24.2031 1.375 24.625 1.79688 24.7188 2.35938C25.375 5.73438 25.1875 11.4531 19.5156 15.2969C19.3281 15.4375 19.1875 15.5312 19 15.6719V19.8906C19 21.0156 18.3438 22.0469 17.3125 22.5625L12.5781 24.9531C12.3438 25.0469 12.0625 25.0469 11.8281 24.9062C11.5938 24.7656 11.5 24.5312 11.5 24.25L11.4531 19.375C11.0781 19.5625 10.7031 19.7031 10.375 19.8438C9.85938 20.0781 9.20312 19.9375 8.78125 19.5156L6.39062 17.1719C5.96875 16.7031 5.82812 16.0938 6.10938 15.5312ZM13 23.0781L16.6562 21.2031C17.1719 20.9688 17.5 20.4531 17.5 19.8906V16.5156C16.0469 17.3125 14.4062 18.0625 13 18.7188V23.0781ZM6.10938 8.5C5.54688 8.5 5.03125 8.82812 4.79688 9.34375L2.92188 13H7.23438C7.89062 11.5938 8.6875 9.95312 9.48438 8.5H6.10938ZM19.375 7.75C19.375 7.14062 18.8594 6.625 18.25 6.625C17.5938 6.625 17.125 7.14062 17.125 7.75C17.125 8.40625 17.5938 8.875 18.25 8.875C18.8594 8.875 19.375 8.40625 19.375 7.75ZM18.25 5.125C19.1875 5.125 20.0312 5.64062 20.5 6.4375C20.9688 7.28125 20.9688 8.26562 20.5 9.0625C20.0312 9.90625 19.1875 10.375 18.25 10.375C17.2656 10.375 16.4219 9.90625 15.9531 9.0625C15.4844 8.26562 15.4844 7.28125 15.9531 6.4375C16.4219 5.64062 17.2656 5.125 18.25 5.125Z"
                fill="#00BDD6"/>
          </svg>
          <div v-if="points === 'BASE'" class="caption-l-regular">+{{ $formatAmount(transaction.basePoints) }}</div>
          <div v-if="points === 'NABOX'" class="caption-l-regular">+{{ $formatAmount(transaction.naboxPoints) }}</div>
          <div v-if="points === 'NULS'" class="caption-l-regular">+{{ $formatAmount(transaction.nulsPoints) }}</div>
          <div v-if="points === 'WHITELIST'" class="caption-l-regular">+{{ $formatAmount(transaction.whitelistReferralPoints) }}</div>
          <div v-if="points === 'MY_REFERRAL'" class="caption-l-regular">+{{ $formatAmount(transaction.referralPoints) }}</div>
          <div v-if="points === 'REFERRAL'" class="caption-l-regular">+{{ $formatAmount(transaction.referralPoints) }}</div>

        </div>

        <div class="date caption-l-regular">{{ $formatDate(transaction.createdAt) }}</div>

      </div>

      <div class="blockchain">

        <div v-if="points === 'BASE'" class="text caption-l-regular">Purchasing SUISSE Tokens</div>

        <div v-if="points === 'NABOX'" class="text caption-l-regular">Purchasing with Nabox</div>

        <div v-if="points === 'NULS'" class="text caption-l-regular">Purchasing with Nuls</div>

        <div v-if="points === 'WHITELIST'" class="text caption-l-regular">Whitelist Participation</div>

        <div v-if="points==='MY_REFERRAL'" class="text caption-l-regular">Referral</div>

        <div v-if="points === 'REFERRAL'" class="text caption-l-regular">Level {{ transaction.referralLevel ? transaction.referralLevel : 0 }} Referral</div>

      </div>

      <div class="line"></div>

    </div>

  </div>
</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: "RocketFuelRow",
  components: {SbcButton},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    points: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang='scss'>
@import "RocketFuelRow";
</style>
