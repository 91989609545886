<template>
  <div class="sbc-tooltip" :class="{'tooltip-bottom': tooltipBottom}">

    <div class="main-wrapper">

      <div class="sub-wrapper">

        <div class="caption-s-regular m-caption-s-regular" v-html="label"></div>

        <div class="arrow-wrapper">
          <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 12" fill="none">
            <path d="M7 12L0 0L14 1.21137e-06L7 12Z" fill="white"/>
          </svg>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "SbcTooltip",
  props: {
    label: String,
    tooltipBottom: Boolean,
  }
};
</script>

<style lang='scss'>
@import "SbcTooltip";
</style>
