<template>
  <div class="sandbox-section">

    <sbc-button
      button-label="Test Button"
    />

    <sbc-button
      button-label="Test Button"
      :button-medium="true"
    />

    <sbc-button
      button-label="Test Button"
      :button-small="true"
    />

    <sbc-button
      button-label="Test Button"
      :is-blue-gradient="true"
    />

    <sbc-button
      button-label="Test Button"
      :is-primary="true"
    />

    <sbc-button
      button-label="Test Button"
      :button-medium="true"
    />

    <sbc-button
      button-label="Test Button"
      :button-small="true"
    />

    <sbc-button
      button-label="Test Button"
      :is-dark-gray="true"
    />

    <sbc-button
      button-label="Test Button"
      :button-medium="true"
      :is-dark-gray="true"
    />

    <sbc-button
      button-label="Test Button"
      :button-small="true"
      :is-dark-gray="true"
    />

    <sbc-button
      button-label="Test Button"
      :button-medium="true"
      :is-black="true"
      icon-right="fa-check"
    />

    <!--    <sbc-tooltip-->
    <!--        message="Token claim will be available after TGE.<br> For more details, visit our Docs section."-->
    <!--        color="blue"-->
    <!--        width="240px"-->
    <!--    >-->

    <!--      <template v-slot:tooltip>-->

    <!--        <sbc-button-->
    <!--            button-label="Test test test"-->
    <!--            icon-right="fa-check"-->
    <!--            :is-disabled2="true"-->
    <!--        />-->

    <!--      </template>-->

    <!--    </sbc-tooltip>-->

    <div>

      <button @click="kycStart = true">KYCstart</button>
      <vi-dialog v-model="kycStart" label="">
        <k-y-c-start/>
      </vi-dialog>

      <button @click="transactionCompleteDialog = true">transactionComplete</button>
      <vi-dialog v-model="transactionCompleteDialog" label="">
        <transaction-complete/>
      </vi-dialog>

      <button @click="selectPaymentDialog = true">select payment</button>
      <vi-dialog v-model="selectPaymentDialog" label="Select payment method">
        <select-payment/>
      </vi-dialog>

      <button @click="transactionFailedDialog = true">transactionFailed</button>
      <vi-dialog v-model="transactionFailedDialog" label="">
        <transaction-failed/>
      </vi-dialog>

      <button @click="KYCInProgressDialog = true">KYCInProgress</button>
      <vi-dialog v-model="KYCInProgressDialog" label="">
        <KYCInProgress/>
      </vi-dialog>
    </div>

  </div>
</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";
import KYCStart from "@/components/dialogs/ConnectWalletInit.vue";
import TransactionComplete from "@/components/dialogs/TransactionComplete.vue";
import SelectPayment from "@/components/dialogs/SelectPayment.vue";
import Subscribe from "@/components/Subscribe.vue";
import TransactionFailed from "@/components/dialogs/TransactionFailed.vue";
import KYCInProgress from "@/components/dialogs/KYCInProgress.vue";

export default {
  name: "SandboxSection",
  components: {
    KYCInProgress,
    TransactionFailed, Subscribe, SelectPayment, TransactionComplete, KYCStart, SbcButton
  },
  data: () => ({
    kycStart: false,
    transactionCompleteDialog: false,
    selectPaymentDialog: false,
    transactionFailedDialog: false,
    KYCInProgressDialog: false,
  }),

};
</script>

<style lang='scss'>
@import "SandboxSection";
</style>
