<template>
  <div class="total-box" :class="{'is-tokens' : isTokens}">

    <div class="title caps-s-regular m-caps-s-regular" v-html="title"></div>

    <div class="value-container top-container heading-m-regular">
      <svg v-if="isTokens" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M9.26009 15.6486V14.7402H7.58188C7.1585 14.7402 6.81209 14.3938 6.81209 13.9699V2.68501C6.81209 1.20802 8.02009 0 9.49705 0H14.5043C15.9808 0 17.1888 1.20849 17.1888 2.68501V5.28469C17.1888 5.70808 16.8424 6.05495 16.4191 6.05495C15.9952 6.05495 15.6488 5.70854 15.6488 5.28469V2.68501C15.6488 2.0548 15.1336 1.53959 14.5039 1.53959H9.49659C8.86685 1.53959 8.35166 2.05526 8.35166 2.68501V9.25981H9.25963V7.58156C9.25963 7.15771 9.60603 6.81176 10.0299 6.81176H18.9593H21.315C22.7915 6.81176 24 8.02025 24 9.49677V14.5042C24 15.9807 22.7915 17.1892 21.315 17.1892H18.7154C18.292 17.1892 17.9452 16.8428 17.9452 16.4194C17.9452 15.996 18.292 15.6491 18.7154 15.6491H21.315C21.9448 15.6491 22.46 15.1339 22.46 14.5042V9.49677C22.46 8.86703 21.9448 8.35135 21.315 8.35135H14.7399V9.25981H16.4181C16.842 9.25981 17.1884 9.60621 17.1884 10.0296V21.315C17.1884 22.792 15.9804 24 14.5039 24H9.49659C8.01963 24 6.81163 22.792 6.81163 21.315V18.7153C6.81163 18.2915 7.15803 17.945 7.58188 17.945C8.00526 17.945 8.35166 18.2915 8.35166 18.7153V21.315C8.35166 21.9447 8.86685 22.4604 9.49659 22.4604H14.5039C15.1331 22.4604 15.6488 21.9443 15.6488 21.315V14.7402H14.7404V16.4184C14.7404 16.8418 14.394 17.1882 13.9706 17.1882H5.04067H2.68496C1.20846 17.1882 0 15.9802 0 14.5032V9.49585C0 8.01886 1.208 6.81084 2.68496 6.81084H5.28459C5.70797 6.81084 6.05437 7.15724 6.05437 7.5811C6.05437 8.00448 5.70797 8.35089 5.28459 8.35089H2.68496C2.05522 8.35089 1.54002 8.8661 1.54002 9.49631V14.5037C1.54002 15.1334 2.05522 15.6486 2.68496 15.6486H9.25963H9.26009ZM15.6488 13.2006V10.7999H13.2008V8.35135H10.8001V10.7999H8.35212V13.2006H10.8001V15.6486H13.2008V13.2006H15.6488Z"
              fill="white"/>
      </svg>
      <div class="value heading-m-regular m-heading-s-regular">{{ mainValue }}</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "TotalBox",
  props: {
    title: String,
    mainValue: String,
    isTokens: Boolean,
  }
};
</script>

<style lang='scss'>
@import "TotalBox";
</style>
