<template>
  <Teleport to="body">
    <vi-dialog
      label=""
      :model-value="modelValue"
      @update:modelValue="closeDialog">

      <connect-wallet-init
        v-if="!isKYCApproved && !isWalletConnected && step===0"
        @decline="closeDialog"
        @continue="step=1"
      />

      <select-payment
        v-else-if="step===1"
        :default-state="false"
        @continue="openWallet"
        @close="closeDialog"
       @networkChanged="openWallet"
      />

    </vi-dialog>
  </Teleport>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ConnectWalletInit from "@/components/dialogs/ConnectWalletInit.vue";
import KYCInProgress from "@/components/dialogs/KYCInProgress.vue";
import {Wallet} from "@/services/wallet.js";
import SelectPayment from "@/components/dialogs/SelectPayment.vue";
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: 'ConnectWalletDialog',
  emits: ['initWallet', 'update:modelValue'],
  components: {SbcButton, SelectPayment, KYCInProgress, ConnectWalletInit},
  props: {
    modelValue: Boolean
  },

  data: () => ({
    step: 0,
  }),

  computed: {
    ...mapState({
      isWalletConnected: state => state.wallet.isWalletConnected,
    }),
    ...mapGetters('wallet', ["isKYCApproved"])
  },


  methods: {
    openWallet() {
      Wallet.open()
        .then((shouldInitWallet) => {
          if (shouldInitWallet) {
            this.$emit('initWallet')
          }
        });
      this.$emit('update:modelValue', false)
    },
    closeDialog() {
      this.step = 0;
      this.$emit('update:modelValue', false)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
