import benefitIcon1 from '/assets/icons/benefit/layer-plus.svg'
import benefitIcon2 from '/assets/icons/benefit/print-magnifying-glass.svg'
import benefitIcon3 from '/assets/icons/benefit/circle-check.svg'
import benefitIcon4 from '/assets/icons/benefit/code.svg'
import benefitIcon5 from '/assets/icons/benefit/globe-stand.svg'
import benefitIcon6 from '/assets/icons/benefit/link.svg'

const BenefitBoxes = [
    {
        image: '<svg width="64" height="62" viewBox="0 0 64 62" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M55 1V7H61C61.5 7 62 7.5 62 8C62 8.625 61.5 9 61 9H55V15C55 15.625 54.5 16 54 16C53.375 16 53 15.625 53 15V9H47C46.375 9 46 8.625 46 8C46 7.5 46.375 7 47 7H53V1C53 0.5 53.375 0 54 0C54.5 0 55 0.5 55 1ZM32 14C32.75 14 33.5 14.25 34.25 14.625L62.5 27.75C63.375 28.125 64 29 64 30C64 31 63.375 32 62.5 32.375L34.25 45.5C33.5 45.875 32.75 46 32 46C31.125 46 30.375 45.875 29.625 45.5L1.375 32.375C0.5 32 0 31 0 30C0 29 0.5 28.125 1.375 27.75L29.625 14.625C30.375 14.25 31.125 14 32 14ZM30.5 16.375L2.25 29.5C2.125 29.625 2 29.875 2 30C2 30.25 2.125 30.5 2.25 30.625L30.5 43.75C30.875 44 31.375 44 32 44C32.5 44 33 44 33.375 43.75L61.625 30.625C61.875 30.5 62 30.25 62 30C62 29.875 61.875 29.625 61.625 29.5L33.375 16.375C33 16.125 32.5 16 32 16C31.375 16 30.875 16.125 30.5 16.375ZM8.875 40.25L11.25 41.375L2.25 45.5C2.125 45.625 2 45.875 2 46C2 46.25 2.125 46.5 2.25 46.625L30.5 59.75C30.875 60 31.375 60 32 60C32.5 60 33 60 33.375 59.75L61.625 46.625C61.75 46.5 61.875 46.25 61.875 46C61.875 45.875 61.75 45.625 61.625 45.5L52.625 41.375L55 40.25L62.5 43.75C63.375 44.125 64 45 64 46C64 47 63.375 48 62.5 48.375L34.25 61.5C33.5 61.875 32.75 62 32 62C31.125 62 30.375 61.875 29.625 61.5L1.375 48.375C0.5 48 0 47 0 46C0 45 0.5 44.125 1.375 43.75L8.875 40.25Z" fill="#1B1C1D"/>\n' +
            '</svg>',
        title: 'Registered in<br> Switzerland',
        link: 'https://www.zefix.ch/en/search/entity/list/firm/1545328',
    },

    {
        image: '<svg width="72" height="65" viewBox="0 0 72 65" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M10 20H8V8C8 3.625 11.5 0 16 0H44.625C46.75 0 48.75 0.875 50.25 2.375L53.625 5.75C55.125 7.25 56 9.25 56 11.375V20H54V11.375C54 9.75 53.25 8.25 52.125 7.125L48.875 3.875C47.75 2.75 46.25 2 44.625 2H16C12.625 2 10 4.75 10 8V20ZM8 26C4.625 26 2 28.75 2 32V48C2 49.125 2.875 50 4 50H8V44C8 41.875 9.75 40 12 40H28C28 40.75 28 41.375 28 42H12C10.875 42 10 43 10 44V60C10 61.125 10.875 62 12 62H52C53 62 54 61.125 54 60V59.125C54 59.125 54.125 59.125 54.25 59L55.875 60.75C55.625 62.625 53.875 64 52 64H12C9.75 64 8 62.25 8 60V52H4C1.75 52 0 50.25 0 48V32C0 27.625 3.5 24 8 24H35.875C35.125 24.625 34.375 25.375 33.625 26H8ZM48 26.125C42.875 26.125 38.375 28.75 35.875 33.125C33.25 37.375 33.25 42.75 35.875 47.125C38.375 51.375 42.875 54.125 48 54.125C53 54.125 57.5 51.375 60 47.125C62.625 42.75 62.625 37.375 60 33.125C57.5 28.75 53 26.125 48 26.125ZM48 56.125C39.125 56.125 32 48.875 32 40.125C32 31.25 39.125 24.125 48 24.125C56.75 24.125 64 31.25 64 40.125C64 44.125 62.375 47.875 60 50.625L71.625 62.375C72 62.75 72 63.375 71.625 63.75C71.25 64.125 70.625 64.125 70.25 63.75L58.5 52.125C55.75 54.5 52 56.125 48 56.125Z" fill="#1B1C1D"/>\n' +
            '</svg>\n',
        title: 'Audited by<br> Grant Thornton',
        link: 'https://www.grantthornton.com/',
    },

    {
        image: '<svg width="68" height="64" viewBox="0 0 68 64" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M34 2C23.25 2 13.375 7.75 8 17C2.625 26.375 2.625 37.75 8 47C13.375 56.375 23.25 62 34 62C44.625 62 54.5 56.375 59.875 47C65.25 37.75 65.25 26.375 59.875 17C54.5 7.75 44.625 2 34 2ZM34 64C22.5 64 12 58 6.25 48C0.5 38.125 0.5 26 6.25 16C12 6.125 22.5 0 34 0C45.375 0 55.875 6.125 61.625 16C67.375 26 67.375 38.125 61.625 48C55.875 58 45.375 64 34 64ZM46.625 24.75L30.625 40.75C30.25 41.125 29.625 41.125 29.25 40.75L21.25 32.75C20.875 32.375 20.875 31.75 21.25 31.375C21.625 31 22.25 31 22.625 31.375L30 38.625L45.25 23.375C45.625 23 46.25 23 46.625 23.375C47 23.75 47 24.375 46.625 24.75Z" fill="url(#paint0_linear_2710_5703)"/>\n' +
            '<defs>\n' +
            '<linearGradient id="paint0_linear_2710_5703" x1="2.14679" y1="3.55059e-05" x2="68.2266" y2="2.22476" gradientUnits="userSpaceOnUse">\n' +
            '<stop stop-color="#D7001D"/>\n' +
            '<stop offset="1" stop-color="#C20A47"/>\n' +
            '</linearGradient>\n' +
            '</defs>\n' +
            '</svg>\n',
        title: 'Swiss Financial Market<br> Supervisory Authority<br> Non-Action-Letter',
        link: 'https://www.finma.ch/en/',
    },

    {
        image: '<svg width="78" height="66" viewBox="0 0 78 66" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M50.25 1.125C50.75 1.25 51.125 1.875 50.875 2.375L28.875 64.375C28.75 64.875 28.125 65.25 27.625 65C27.125 64.875 26.875 64.25 27 63.75L49 1.75C49.25 1.25 49.75 0.875 50.25 1.125ZM59.25 16.375C59.625 16 60.25 16 60.625 16.375L77.625 32.375C77.875 32.5 78 32.75 78 33C78 33.375 77.875 33.625 77.625 33.75L60.625 49.75C60.25 50.125 59.625 50.125 59.25 49.75C58.875 49.375 58.875 48.75 59.25 48.375L75.5 33L59.25 17.75C58.875 17.375 58.875 16.75 59.25 16.375ZM18.625 16.375C19 16.75 19 17.375 18.625 17.75L2.375 33L18.625 48.375C19 48.75 19 49.375 18.625 49.75C18.25 50.125 17.625 50.125 17.25 49.75L0.25 33.75C0 33.625 0 33.375 0 33C0 32.75 0 32.5 0.25 32.375L17.25 16.375C17.625 16 18.25 16 18.625 16.375Z" fill="#1B1C1D"/>\n' +
            '</svg>\n',
        title: 'Audited<br> Code',
        link: 'https://docs.suisseblockchain.io/suissepad/token/audits',
    },

    {
        image: '<svg width="53" height="62" viewBox="0 0 53 62" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M1.25 42.375L3.875 39.75C4.25 39.375 4.875 39.375 5.25 39.75C15 49.5 30.875 49.5 40.625 39.75C50.375 30 50.375 14.125 40.625 4.375C40.25 4 40.25 3.375 40.625 3L43.25 0.375C43.625 0 44.25 0 44.625 0.375C45 0.75 45 1.375 44.625 1.75L42.75 3.75C52.625 14.25 52.375 30.875 42 41.125C37.5 45.625 31.875 48.25 26 48.875V60H44C44.5 60 45 60.5 45 61C45 61.625 44.5 62 44 62H6C5.375 62 5 61.625 5 61C5 60.5 5.375 60 6 60H24V49C17 49.25 10 46.875 4.625 41.875L2.625 43.75C2.25 44.125 1.625 44.125 1.25 43.75C0.875 43.375 0.875 42.75 1.25 42.375ZM5 22C5 28.5 8.375 34.375 14 37.625C19.5 40.875 26.375 40.875 32 37.625C37.5 34.375 41 28.5 41 22C41 15.625 37.5 9.75 32 6.5C26.375 3.25 19.5 3.25 14 6.5C8.375 9.75 5 15.625 5 22ZM43 22C43 29.25 39.125 35.75 33 39.375C26.75 43 19.125 43 13 39.375C6.75 35.75 3 29.25 3 22C3 14.875 6.75 8.375 13 4.75C19.125 1.125 26.75 1.125 33 4.75C39.125 8.375 43 14.875 43 22Z" fill="#1B1C1D"/>\n' +
            '</svg>\n',
        title: 'Global<br> Network',
        link: 'https://docs.suisseblockchain.io/suissepad/information/partnerships'
    },

    {
        image: '<svg width="78" height="62" viewBox="0 0 78 62" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M71.5 29.625L54.5 46.625C48.25 52.875 38.125 52.875 31.875 46.625C25.625 40.375 25.625 30.25 31.875 24L36.875 19C37.25 18.625 37.875 18.625 38.25 19C38.625 19.375 38.625 20 38.25 20.5L33.25 25.375C27.875 30.875 27.875 39.75 33.25 45.25C38.75 50.625 47.625 50.625 53.125 45.25L70 28.25C75.5 22.75 75.5 13.875 70 8.375C64.625 3 55.75 3 50.25 8.375L48.125 10.5C47.75 11 47.125 11 46.75 10.5C46.375 10.125 46.375 9.5 46.75 9.125L48.875 7C55.125 0.75 65.25 0.75 71.5 7C77.75 13.25 77.75 23.375 71.5 29.625ZM6.375 32.5L23.375 15.5C29.625 9.25 39.75 9.25 46 15.5C52.25 21.75 52.25 31.875 46 38.125L41.125 43.125C40.625 43.5 40 43.5 39.625 43.125C39.25 42.75 39.25 42 39.625 41.625L44.625 36.75C50.125 31.25 50.125 22.375 44.625 16.875C39.125 11.5 30.25 11.5 24.75 16.875L7.875 33.875C2.375 39.375 2.375 48.25 7.875 53.625C13.25 59.125 22.125 59.125 27.625 53.625L29.75 51.5C30.125 51.125 30.75 51.125 31.125 51.5C31.5 52 31.5 52.625 31.125 53L29 55.125C22.75 61.375 12.625 61.375 6.375 55.125C0.125 48.875 0.125 38.75 6.375 32.5Z" fill="#1B1C1D"/>\n' +
            '</svg>\n',
        title: 'Supporting Over<br>40 Blockchains',
        link: 'https://docs.suisseblockchain.io/suissepad/product/supported-blockchains'
    },

];

export default BenefitBoxes;
