<template>

  <div class="hero-section" :class="{'user-dashboard' : showUserDashboard && $isMobile}">

    <div class="main-container">

      <div class="sbc-logo show-mobile" v-if="!showUserDashboard && $isMobile  || !$isMobile">

        <div class="main-logo">
          <img src="/public/assets/logo/suisse.svg" alt="">
          <img src="/public/assets/logo/union.svg" alt="">
        </div>

        <div class="sub-title">
          <div class="icon-box">
            <img src="/assets/icons/swiss-box.svg" alt="">
          </div>

          <div class="caps-s-regular m-caps-s-regular">
            swiss regulatory compliant launchpad
          </div>
        </div>
      </div>

      <div class="wrapper">

        <div class="content-wrapper">

          <div class="content main-info hide-mobile" v-if="!isWalletConnected">

            <div class="sbc-logo">

              <div class="main-logo">
                <img src="/public/assets/logo/suisse.svg" alt="">
                <img src="/public/assets/logo/union.svg" alt="">
              </div>

              <div class="sub-title">
                <div class="icon-box">
                  <img src="/assets/icons/swiss-box.svg" alt="">
                </div>

                <div class="caps-s-regular m-caps-s-regular">
                  swiss regulatory compliant launchpad
                </div>
              </div>

            </div>

            <div class="text-container">

              <div class="title">
                <div class="heading-l-regular m-heading-m-regular">Launching</div>

                <div class="heading-l-regular m-heading-m-regular">The Future of Web3</div>
              </div>

              <div class="description body-l-regular m-caption-l-regular">Gain early access to high-potential projects
                with
                transparent token distributions and enjoy a secure platform backed by our top industry partnerships.
              </div>

            </div>

            <div class="boxes-container">

              <div class="box-wrapper">

                <div class="boxes">

                  <support-box
                    label="KYC by"
                    :logo="sumSub"
                    link="https://sumsub.com/"
                  />

                  <support-box
                    class="fix-width"
                    label="Financial Audit by"
                    :logo="grantThornton"
                    link="https://www.grantthornton.com/"
                  />

                </div>

                <support-box
                  class="extended hide-mobile"
                  label="Backed by"
                  :logo="digitalTwins"
                  :logo2="ecoSystem"
                  link="https://www.digitaltwinscapital.com/"
                  link2="https://ecosystemventures-ice.com/"
                />

              </div>

              <support-box
                class="extended show-mobile"
                label="Backed by"
                :logo="digitalTwins"
                :logo2="ecoSystem"
                link="https://www.digitaltwinscapital.com/"
                link2="https://ecosystemventures-ice.com/"
              />

            </div>

          </div>

          <div class="content wallet-connected"
               v-if="(!$isMobile && isWalletConnected) || (isWalletConnected && showUserDashboard && $isMobile)">
            <user-dashboard/>
          </div>


        </div>

        <div class="content-wrapper">

          <div v-if="showCountdownTimer" class="content has-timer">

            <release-timer
              :time="getNextStage?.startsAt"
            />

          </div>

          <template v-else>
            <div class="content" v-if="isWhitelist">

              <div @click="showWhitelistDialog = true" class="info-bar">

                <sbc-carousel :items="infoRows" :min-width="2000" :auto-scroll="true" :auto-scroll-speed="0.3">

                  <template #listItem="{item, index}" :key="index">

                    <div class="caps-s-regular m-caps-s-regular">{{ item.title }}</div>

                  </template>

                </sbc-carousel>

              </div>

              <div class="asset-wrapper">

                <a href="https://docs.suisseblockchain.io/suissepad/token/pre-sale" target="_blank"
                   class="heading-s-regular">Pre-Sale
                  <span class="highlight">Coming Soon</span></a>

              </div>

              <sbc-button
                @click="showWhitelistDialog = true"
                button-label="Whitelist for SUISSE Token"
                class="whitelist-button"
              />

            </div>

            <div class="content"
                 v-if="(!isWhitelist && !$isMobile) || (!isWhitelist && !showUserDashboard && $isMobile)">
              <pre-sale
                @openPaymentDialog="showSelectPaymentDialog = true"
                @connectWallet="openConnectWalletDialog"
              />

            </div>
          </template>


        </div>
      </div>

    </div>

    <whitelist-dialog v-model="showWhitelistDialog"/>

    <select-payment-dialog v-model="showSelectPaymentDialog" @initWallet="$emit('initWallet')"/>

    <select-network v-model="showSelectNetworkDialog"/>

    <connect-wallet-dialog v-model="showConnectWalletDialog" @initWallet="$emit('initWallet')"/>

  </div>

</template>

<script>
import SupportBox from "@/components/SupportBox.vue";
import TimeCountdown from "@/components/hero-components/TimeCountdown.vue";
import StagePriceBar from "@/components/hero-components/StagePriceBar.vue";
import PayReceiveBox from "@/components/hero-components/PayBox.vue";
import SbcButton from "@/components/general/SbcButton.vue";
import SbcCarousel from "@/components/general/SbcCarousel.vue";
import ReceiveBox from "@/components/hero-components/ReceiveBox.vue";
import WhitelistDialog from "@/components/WhitelistDialog.vue";
import PayBox from "@/components/hero-components/PayBox.vue";
import StageNumber from "@/components/hero-components/StageNumber.vue";
import DividerText from "@/components/hero-components/DividerText.vue";
import AgreeCheckbox from "@/components/hero-components/AgreeCheckbox.vue";
import TotalBox from "@/components/hero-components/TotalBox.vue";
import KYCBox from "@/components/hero-components/KYCBox.vue";
import AddressBox from "../components/hero-components/AddressBox.vue";
import TotalBox2 from "@/components/hero-components/TotalBox2.vue";
import TransactionsList from "@/components/hero-components/TransactionsList.vue";
import TransactionComplete from "@/components/dialogs/TransactionComplete.vue";
import {mapGetters, mapState} from "vuex";
import {Wallet} from "@/services/wallet.js";
import SelectPayment from "@/components/dialogs/SelectPayment.vue";
import PreSale from "@/components/hero-components/PreSale.vue";
import ConnectWalletDialog from "@/components/dialogs/ConnectWalletDialog.vue";
import UserDashboard from "@/components/hero-components/UserDashboard.vue";
import {Tooltip} from '@programic/vue3-tooltip'
import SelectNetwork from "@/components/dialogs/SelectNetwork.vue";
import SelectPaymentDialog from "@/components/dialogs/SelectPaymentDialog.vue";
import ReleaseTimer from "@/components/general/ReleaseTimer.vue"

export default {
  name: "HeroSection",
  components: {
    SelectPaymentDialog,
    SelectNetwork,
    UserDashboard,
    ConnectWalletDialog,
    PreSale,
    SelectPayment,
    TransactionComplete,
    TransactionsList,
    TotalBox2,
    AddressBox,
    KYCBox,
    TotalBox,
    AgreeCheckbox,
    DividerText,
    StageNumber,
    PayBox,
    WhitelistDialog,
    ReceiveBox, SbcCarousel, SbcButton, PayReceiveBox, StagePriceBar, TimeCountdown, SupportBox, Tooltip,
    ReleaseTimer
  },

  emits: ['initWallet'],
  data: () => ({
    grantThornton: "/assets/support/grant-thornton-logo.svg",
    sumSub: "/assets/support/sum-sub-logo.svg",
    certik: "/assets/support/certik-logo.svg",
    digitalTwins: "/assets/support/digital-twins-logo.svg",
    ecoSystem: "/assets/support/eco-system.svg",

    eth: "/assets/blockchains/01.Ethereum.svg",
    suisse: "/assets/logo/suisse-logo-circle.svg",
    showWhitelistDialog: false,
    showSelectPaymentDialog: false,
    showSelectNetworkDialog: false,
    showConnectWalletDialog: false,
    isWhitelist: false,
    hasTimer: true,
  }),

  computed: {
    ...mapState({
      isWalletConnected: state => state.wallet.isWalletConnected,
      isWalletLoading: state => state.wallet.isWalletLoading,
      currentAddress: state => state.wallet.currentAddress,
      presaleContracts: state => state.presale.presaleContracts,
      showUserDashboard: state => state.general.showUserDashboard
    }),
    ...mapGetters('wallet', ["isKYCApproved"]),
    ...mapGetters('presale', ["getNextStage"]),
    ...mapGetters('presale', ["getPastStage"]),
    ...mapGetters('presale', ["getActivePresaleStage"]),
    showCountdownTimer() {
      return !this.getPastStage && (!this.getActivePresaleStage || (this.getActivePresaleStage && new Date(parseInt(this.getActivePresaleStage.startsAt)).getTime() > Date.now()))
    }
  },

  methods: {
    openConnectWalletDialog() {
      if (!this.isWalletLoading) {
        this.showConnectWalletDialog = true
      }
    }
  },


  mounted() {
    if (this.$route?.query?.whitelist) {
      this.showWhitelistDialog = true
    }
  },

  watch: {
    showSelectPaymentDialog(newVal) {
      if (newVal) {
        document.body.classList.add('prevent-scroll');
      } else {
        document.body.classList.remove('prevent-scroll');
      }
    },
    showConnectWalletDialog(newVal) {
      if (newVal) {
        document.body.classList.add('prevent-scroll');
      } else {
        document.body.classList.remove('prevent-scroll');
      }
    }
  }
};
</script>

<style lang='scss'>
@import "HeroSection";
</style>
