<template>
  <div class="tokenomics-section">

    <div class="main-container-2">

      <div class="tokenomics-header">
        <a href="https://docs.suisseblockchain.io/suissepad/token/tokenomics" target="_blank" class="title-container">
          <div class="title caps-m-regular m-caps-s-regular">tokenomics</div>
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.3125 1.40625C8.3125 1.05078 8.58594 0.75 8.96875 0.75H13.3164C13.6992 0.75 13.9727 1.05078 13.9727 1.40625V5.78125C13.9727 6.16406 13.6992 6.4375 13.3164 6.4375C12.9609 6.4375 12.6602 6.16406 12.6602 5.78125V2.99219L6.5625 9.08984C6.31641 9.36328 5.90625 9.36328 5.66016 9.08984C5.38672 8.84375 5.38672 8.43359 5.66016 8.16016L11.7578 2.0625H8.96875C8.58594 2.0625 8.3125 1.78906 8.3125 1.40625ZM1.96875 1.625H5.46875C5.82422 1.625 6.125 1.92578 6.125 2.28125C6.125 2.66406 5.82422 2.9375 5.46875 2.9375H1.96875C1.58594 2.9375 1.3125 3.23828 1.3125 3.59375V12.7812C1.3125 13.1641 1.58594 13.4375 1.96875 13.4375H11.1562C11.5117 13.4375 11.8125 13.1641 11.8125 12.7812V9.28125C11.8125 8.92578 12.0859 8.625 12.4688 8.625C12.8242 8.625 13.125 8.92578 13.125 9.28125V12.7812C13.125 13.875 12.2227 14.75 11.1562 14.75H1.96875C0.875 14.75 0 13.875 0 12.7812V3.59375C0 2.52734 0.875 1.625 1.96875 1.625Z"
                fill="#1B1C1D"/>
          </svg>
        </a>
        <div class="text-gray caps-m-regular m-caps-s-regular">Token Count</div>
      </div>

      <tokenomic-statistic
          v-for="(statistic, index) in Statistics" :key="index"
          :statistic="statistic"
      />

      <div class="tokenomics-footer">
        <div class="text-gray caps-s-regular m-caps-s-regular">Total</div>
        <div class="value body-s-regular">200.000.000</div>
      </div>

    </div>

  </div>
</template>

<script>
import TokenomicStatistic from "@/components/TokenomicStatistic.vue";
import Statistics from "@/services/tokenomicStatistics.js";

export default {
  name: "TokenomicsSection",
  components: {TokenomicStatistic},

  data() {
    return {
      Statistics,
    }
  }
}

</script>

<style lang='scss'>
@import "TokenomicsSection";
</style>
