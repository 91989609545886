<template>
  <div class="join-section">

    <div class="main-container spacing">

      <div class="content-left">
        <div class="content-box">
          <div>
            <div class="highlight display-s-regular m-heading-m-regular">Join</div>
            <div class="display-s-regular m-heading-m-regular">The Blockchain Revolution</div>
          </div>
          <subscribe
              class="hide-mobile"
              label="SUBSCRIBE"
              placeholder="e-mail"
          />
          <subscribe
              class="show-mobile"
              label="JOIN"
              placeholder="e-mail"
              :subscribe-medium="true"
          />
        </div>
      </div>

      <div class="content-right">

        <div class="content-box">
          <div>
            <div class="text-highlight display-s-regular m-heading-m-regular">Ready to</div>
            <div class="display-s-regular m-heading-m-regular">Launch with Us?</div>
          </div>

          <div class="description body-l-regular m-body-s-regular">Apply to launch your project on our platform.</div>

          <sbc-button
              class="hide-mobile coming-soon-button"
              button-label="COMING SOON"
              :is-disabled="true"
          />

          <sbc-button
              :button-medium="true"
              :is-disabled="true"
              class="show-mobile"
              button-label="COMING SOON"
          />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Subscribe from "@/components/Subscribe.vue";
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: "JoinSection",
  components: {SbcButton, Subscribe},
};
</script>

<style lang='scss'>
@import "JoinSection";
</style>