<template>
  <div class="info-split-box" :class="{'secondary':secondary}">

    <div class="content-left">
      <slot name="content-left"></slot>
    </div>

    <div class="content-right">
      <slot name="content-right">
      </slot>
      <img class="background-image" :src="background" alt="">
    </div>

  </div>
</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: "InfoSplitBox",
  components: {SbcButton},

  props: {
    background: String,
    secondary: Boolean,
  }
};
</script>

<style lang='scss'>
@import "InfoSplitBox";
</style>