<template>
  <div class="stage-number">
    <div class="heading-s-regular text-white">Stage</div>
    <div class="stage">
      <div class="current-stage heading-s-regular">{{ activePresaleStage?.number }}</div>
      <div class="text-blue heading-s-regular" v-if="activePresaleStage">Ongoing</div>
      <div class="text-red heading-s-regular" v-else-if="!activePresaleStage">Closed</div>
      <div class="text-blue heading-s-regular" v-else>Opening</div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "StageNumber",

  computed: {
    ...mapGetters('presale', ["getTotalStagesCount"]),
    ...mapGetters('presale', ["getActivePresaleStage"]),
    activePresaleStage() {
      return this.getActivePresaleStage
    }
  }
};
</script>

<style lang='scss'>
@import "StageNumber";
</style>

