<template>
  <Teleport to="body">
    <vi-dialog
      v-if="modelValue"
      :model-value="modelValue"
      :label="label ? label : ''"
      @update:modelValue="$emit('update:modelValue', $event)"
    >

      <div class="kyc-authentication-dialog">

        <div ref="kycAuthentication" class="kyc-authentication"/>


        <div class="kyc-button-container">

          <sbc-button
            button-label="Back to Dashboard"
            :button-medium="true"
            :is-black="true"
            @click="$emit('update:modelValue', false)"
          />

        </div>
      </div>

    </vi-dialog>
  </Teleport>
</template>

<script>
import snsWebSdk from "@sumsub/websdk";
import {mapState} from "vuex";
import {updateKycAccessToken} from "@/services/walletAuth.js";
import SbcButton from "@/components/general/SbcButton.vue";

export default {
  name: "KycAuthenticationDialog",
  components: {SbcButton},
  props: {
    modelValue: Boolean,
    label: {type:String}
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState({
      kycAccessToken: state => state.wallet.kycAccessToken,
      self: state => state.presale.self
    }),
  },

  methods: {
    launchWebSdk() {
      console.log("ALALAL")
      let snsWebSdkInstance = snsWebSdk
        .init(
          this.kycAccessToken,
          // new query for update
          () => this.updateSumsubKycAccessToken()
        )
        .withConf({
          lang: "en",
          id: self.id,
        })
        .withOptions({addViewportTag: false, adaptIframeHeight: true})
        .on("idCheck.onReady", (payload) => {
          console.log("onReady", payload);
        })
        .on("idCheck.onApplicantStatusChanged", (payload) => {
          //get self
          if (payload.reviewResult && payload.reviewResult.reviewAnswer === "GREEN") {
            console.log("onApplicantStatusChanged", payload)
            this.loading = false;
            this.$emit("reviewResult");
          }
        })
        .on("idCheck.onError", (error) => {
          console.log("onError", error);
        })
        .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch(this.$refs.kycAuthentication);
    },

    updateSumsubKycAccessToken() {
      return updateKycAccessToken(this.self.id)
    },
  },

  mounted() {
    console.log('CREATED')
    if (this.kycAccessToken) {
      console.log("LAUNCH")
      this.launchWebSdk();
    }
  }
}
</script>

<style lang='scss'>
@import "KycAuthenticationDialog";
</style>
