<template>

  <hero-section
    id="buy-suisse"
    @initWallet="$emit('initWallet')"
  />

  <div class="home-page" v-if="!showUserDashboard && $isMobile || !$isMobile">

    <partnership-section
      class="show-mobile"
    />

    <logo-slider-section/>

    <product-section/>

    <solution-section/>

    <blockchain-section/>

    <token-section/>

    <tokenomics-section/>

    <benefits-section/>

    <team-section/>

    <blueprint-section/>

    <f-a-q-section/>

    <join-section/>

  </div>
</template>

<script>
import idInViewport from "@/directives/idInViewport.js";
import ProductSection from "@/sections/ProductSection.vue";
import LogoSliderSection from "@/sections/LogoSliderSection.vue";
import TokenSection from "@/sections/TokenSection.vue";
import SolutionSection from "@/sections/SolutionSection.vue";
import BlockchainSection from "@/sections/BlockchainSection.vue";
import TokenomicsSection from "@/sections/TokenomicsSection.vue";
import BenefitsSection from "@/sections/BenefitsSection.vue";
import BlueprintSection from "@/sections/BlueprintSection.vue";
import TeamSection from "@/sections/TeamSection.vue";
import HeroSection from "@/sections/HeroSection.vue";
import MainFooter from "@/components/general/MainFooter.vue";
import JoinSection from "@/sections/JoinSection.vue";
import FAQSection from "@/sections/FAQSection.vue";
import SandboxSection from "@/sections/SandboxSection.vue";
import PartnershipSection from "@/sections/PartnershipSection.vue";
import ConnectWalletDialog from "@/components/dialogs/ConnectWalletDialog.vue";
import SelectPayment from "@/components/dialogs/SelectPayment.vue";
import {mapState} from "vuex";

export default {
  name: "HomePage",
  components: {
    SelectPayment,
    ConnectWalletDialog,
    PartnershipSection,
    SandboxSection,
    FAQSection,
    JoinSection,
    MainFooter,
    HeroSection,
    TeamSection,
    BlueprintSection,
    BenefitsSection,
    TokenomicsSection,
    BlockchainSection,
    SolutionSection,
    TokenSection,
    LogoSliderSection,
    ProductSection
  },
  directives: {
    'id-in-viewport': idInViewport
  },

  computed:{
    ...mapState({
      showUserDashboard: state => state.general.showUserDashboard
    })
  }
}

</script>

<style lang='scss'>
@import "HomePage";
</style>
