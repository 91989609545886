<template>
  <a :href="logoBox.link" target="_blank" class="logo-box">

    <img :src="logoBox.image" alt="">

  </a>
</template>

<script>
export default {
  name: "LogoBox",
  props: {
    logoBox: Object,
    link: String,
  }
};
</script>

<style lang='scss'>
@import "LogoBox";
</style>