import {createRouter, createWebHistory} from 'vue-router'
import WalletPage from '../pages/WalletPage.vue'
import HomePage from '../pages/HomePage.vue'
import UnsubscribePage from '../pages/UnsubscribePage.vue'
import *  as VueRouter from 'vue-router'
import SandboxSection from "@/sections/SandboxSection.vue";

const routes = [{
  path: '/', name: 'HomePage', component: HomePage, meta: {
    subpages: ['home', 'purpose', 'benefits', 'launchpad', 'team', 'roadmap', 'contact']
  }
}, {
  path: '/wallet', name: 'WalletPage', component: WalletPage,
},

  {
    path: '/unsubscribe/:token',
    props: true,
    name: 'UnsubscribePage',
    component: UnsubscribePage
  },

  {
    path: '/sandbox',
    name: 'SandboxPage',
    component: SandboxSection,
    meta: {
      subpages: ['home', 'purpose', 'benefits', 'launchpad', 'team', 'roadmap', 'contact']
    }
  },
]

let scrollingTimeout = null

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(), routes, scrollBehavior(to, from, savedPosition) {
    if (window.disableScroll) {
      return savedPosition
    }
    if (to.hash) {
      window.disableScroll = true
      clearTimeout(scrollingTimeout)
      scrollingTimeout = setTimeout(() => {
        window.disableScroll = false
      }, 500)
      return {
        el: to.hash, top: +60, behavior: 'smooth',
      }
    } else {
      return {top: 0}
    }

  },
})

export default router
