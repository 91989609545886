<template>
  <div class="opening-stage" v-if="activeStage">

    <div class="header">

      <div class="stage-number text-white m-heading-s-regular">
        <div class="heading-s-regular ">Stage</div>
        <div class="stage">
          <div class="current-stage heading-s-regular">{{ activeStage?.number }}</div>
          <div class="text-blue heading-s-regular">Opening</div>
        </div>
      </div>

      <div class="stage-date body-m-regular m-body-m-regular">{{ activeStage?.startsAt? $formatDateTime(activeStage?.startsAt): 'TBA' }}</div>

    </div>

    <div class="total-boxes">

      <total-box
        class="amount-raised"
        title="AMOUNT TO RAISE"
        :main-value="$formatPrice(activeStage?.total * $formatUnits(activeStage?.price, 8), 0)"
      />


      <total-box
        title="TOKEN PRICE"
        :main-value="$formatPrice($formatUnits(activeStage?.price.toString(), 8))"

      />

    </div>

  </div>
</template>

<script>
import TotalBox from "@/components/hero-components/TotalBox.vue";
import {mapGetters} from "vuex";

export default {
  name: "OpeningStage",
  components: {TotalBox},

  computed: {
    ...mapGetters('presale', ["getActivePresaleStage", "getIsActiveStageStarted", "getNextStage"]),
    activeStage() {
      if (this.getIsActiveStageStarted) {
        return this.getActivePresaleStage
      } else
        return this.getNextStage;
    },

  }
};
</script>

<style lang='scss'>
@import "OpeningStage";
</style>
