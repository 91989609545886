<template>
  <div class="team-container">

    <div class="title display-s-regular m-heading-m-regular" v-html="teamContainer.title"></div>

    <div class="members-wrapper">

      <member-box
          ref="members"
          :teamBox="member"
          @flip="onFlip(member)"
          v-for="member in teamContainer.teamMembers"
      />

    </div>

  </div>
</template>

<script>
import MemberBox from "@/components/MemberBox.vue";

export default {
  name: "TeamContainer",
  components: {MemberBox},

  props: {
    teamContainer: Object,
  },

  methods: {
    onFlip(member) {
      this.$refs.members.forEach(m => {
        if(m.teamBox !== member) {
          m.resetCardDetails()
        }
      })
      this.$emit('flip')
    },

    resetCardDetails() {
      this.$refs.members.forEach(m => {
        m.resetCardDetails()
      })
    }
  }
};
</script>

<style lang='scss'>
@import "TeamContainer";
</style>