<template>

  <div class="transaction-row">

    <div class="transaction-content hide-mobile">

      <div class="date caption-l-regular">{{ $formatDate(transaction.createdAt) }}</div>

      <div class="blockchain">
        <img :src="chainIcons[transaction?.contract?.chainId]" alt="">
        <div class="caption-l-regular">{{ transaction.contract.name }}</div>
      </div>

      <div class="blockchain secondary">
        <img :src="tokenIcons[transaction?.currency]" alt="">
        <div class="caption-l-regular">
          {{ $formatTokenAmount($formatUnits(this.transaction?.amountCoin, 8), transaction.currency) }}
          {{ transaction.currency }}
        </div>
      </div>

      <sbc-button
        button-label="DETAILS"
        icon-right="fa-arrow-up-right-from-square"
        icon-type="light"
        :is-black="true"
        :button-extra-small="true"
        :href="transaction?.contract.explorerUrl + transaction.transactionHash"
      />
    </div>

    <div class="transaction-content transaction-mobile show-mobile">

      <div class="container">

        <div class="blockchain">
          <img :src="chainIcons[transaction?.contract?.chainId]" alt="">
          <div class="caption-l-regular">{{ transaction.contract.name }}</div>
        </div>

        <div class="date caption-l-regular">{{ $formatDate(transaction.createdAt) }}</div>

      </div>

      <div class="container">

        <div class="blockchain currency">
          <img :src="tokenIcons[transaction?.currency]" alt="">
          <div class="caption-l-regular"> {{ transactionAmount }} {{ transaction.currency }}</div>
        </div>

        <sbc-button
          button-label="DETAILS"
          icon-right="fa-arrow-up-right-from-square"
          icon-type="light"
          :button-extra-small="true"
          :is-dark-gray="true"
          :href="transaction?.contract.explorerUrl + transaction.transactionHash"
        />

      </div>

    </div>

  </div>

</template>

<script>
import SbcButton from "@/components/general/SbcButton.vue";
import {tokenIcons, chainIcons} from "@/services/chainAndTokenIcons.js";

export default {
  name: "TransactionRow",
  components: {SbcButton},
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    tokenIcons,
    chainIcons
  }),

  computed: {
    transactionAmount() {
      let decimals = this.transaction?.currency === 'ETH' ? 4 : 2;
      return this.$formatAmount(this.$formatUnits(this.transaction?.amountCoin, 8), decimals);
    },
  }
};
</script>

<style lang='scss'>
@import "TransactionRow";
</style>
