<template>
  <div class="blockchain-banner">

    <sbc-carousel :items="blockchains" :min-width="2000" :auto-scroll="true">

      <template #listItem="{item, index}" :key="index">

        <a :href="item.link" target="_blank" class="blockchain" :class="{'small-size' : item.smallSize}">
          <img :src="item.src" alt="">
        </a>

      </template>

    </sbc-carousel>

  </div>
</template>

<script>

import blockchains from "@/services/blockchains.js";
import SbcCarousel from "@/components/general/SbcCarousel.vue";
import BenefitBox from "@/components/BenefitBox.vue";

export default {
  name: "BlockchainBanner",
  components: {BenefitBox, SbcCarousel},
  data() {
    return {
      blockchains
    }
  },
  props: {
    smallSize: Boolean,
  }
};
</script>

<style lang='scss'>
@import "BlockchainBanner";
</style>