<template>
  <div class="blockchain-section spacing">

    <div class="main-container-2">

      <div class="display-s-regular text-highlight m-heading-m-regular hide-mobile">Harnessing the Power of</div>
      <div class="display-s-regular m-heading-m-regular hide-mobile">Over 40 Blockchains</div>

      <div class="title-mobile">
        <div class="m-heading-m-regular text-highlight show-mobile">Harnessing the</div>
        <div class="m-heading-m-regular show-mobile"><span class="text-highlight">Power of </span> Over 40</div>
        <div class="m-heading-m-regular show-mobile">Blockchains</div>
      </div>

    </div>

    <div class="main-container">

      <blockchain-banner/>

    </div>

    <div class="main-container-2">

      <div class="text-container">
        <div class="body-s-regular m-body-s-regular text-left">An inclusive, decentralized landscaped is the main idea
          behind
          blockchain. Together
          with NULS, we've
          created the platform to support founders from all kinds of blockchains on one, chain-agnostic launchpad.
        </div>
        <div class="body-s-regular m-body-s-regular text-right">It's through their technology that we're able to offer
          mutliple
          staking vaults,
          cross-chain capabilities,
          and a custom wallet that is compatible with networks like BNB Chain, Ethereum, or even Blast.
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import BlockchainBanner from "@/components/BlockchainBanner.vue";

export default {
  name: "BlockchainSection",
  components: {BlockchainBanner},

};
</script>

<style lang='scss'>
@import "BlockchainSection";
</style>
