<template>
  <div class="pre-sale">

    <template v-if="presaleStage && isActiveStageStarted">

      <ongoing-stage/>

      <pay-box
        v-model="amountToPay"
        @openPaymentDialog="$emit('openPaymentDialog')"
      />

      <receive-box
        :amount-to-pay="amountToPay"
        @connectWallet="$emit('connectWallet')"
        @makeDeposit="makeDeposit"
      />
    </template>

    <template v-else>
      <closed-stage/>

      <opening-stage/>

      <total-values/>

      <connect-wallet
        @connectWallet="$emit('connectWallet')"
      />

    </template>


    <Teleport to="body">
      <vi-dialog label="" v-model="showTransactionDialog">

        <transaction-complete
          v-if="isTransactionSuccess===true"
          :transaction="currentTransaction"
          @goToDashboard="showTransactionDialog=false"
        />

        <transaction-failed
          v-if="isTransactionSuccess===false"
          @goToDashboard="showTransactionDialog=false"
        />

      </vi-dialog>

      <vi-dialog label="" v-model="showKYCInProgressDialog">
        <k-y-c-in-progress
          @back="showKYCInProgressDialog = false"
        />
      </vi-dialog>

      <kyc-authentication-dialog label="Connect KYC" v-if="showKYCDialog" v-model="showKYCDialog"/>

      <vi-dialog label="" v-model="showMaxTokenLimitDialog">

        <token-limit-reached/>

      </vi-dialog>

    </Teleport>

  </div>


</template>

<script>


import {mapGetters, mapState} from "vuex";
import ReceiveBox from "@/components/hero-components/ReceiveBox.vue";
import PayBox from "@/components/hero-components/PayBox.vue";
import OngoingStage from "@/components/hero-components/OngoingStage.vue";
import ClosedStage from "@/components/hero-components/ClosedStage.vue";
import OpeningStage from "@/components/hero-components/OpeningStage.vue";
import TotalValues from "@/components/hero-components/TotalValues.vue";
import ConnectWallet from "@/components/hero-components/ConnectWallet.vue";
import TransactionComplete from "@/components/dialogs/TransactionComplete.vue";
import TransactionFailed from "@/components/dialogs/TransactionFailed.vue";
import KYCInProgress from "@/components/dialogs/KYCInProgress.vue";
import KycAuthenticationDialog from "@/components/dialogs/KycAuthenticationDialog.vue";
import TokenLimitReached from "@/components/dialogs/TokenLimitReached.vue";


export default {
  name: "PreSale",
  components: {
    TokenLimitReached,
    KycAuthenticationDialog,
    KYCInProgress,
    TransactionFailed,
    TransactionComplete,
    ConnectWallet,
    TotalValues,
    OpeningStage,
    ClosedStage,
    OngoingStage,
    PayBox,
    ReceiveBox,
  },

  data: () => ({
    amountToPay: 0,
    isTransactionSuccess: false,
    showTransactionDialog: false,
    showKYCInProgressDialog: false,
    showKYCDialog: false,
    showMaxTokenLimitDialog: false,
    currentTransaction: null
  }),

  computed: {
    ...mapState({
      isBuyingInProgress: state => state.wallet.isBuyingInProgress,
      self: state => state.presale.self,
    }),
    ...mapGetters('presale', ["hasTransactionErrors", "getActivePresaleStage", "getIsActiveStageStarted"]),
    presaleStage() {
      return this.getActivePresaleStage;
    },
    isActiveStageStarted() {
      return this.getIsActiveStageStarted
    }
  },

  methods: {
    makeDeposit() {
      this.showTransactionDialog = false;
      if(this.hasTransactionErrors) return;

      if (this.self.kycStatus === 'NOT_STARTED') {
        this.showKYCDialog = true;
        return;
      } else if (this.self.kycStatus === 'PENDING') {
        this.showKYCInProgressDialog = true;
        return;
      }

      if (!this.hasTransactionErrors && !this.isBuyingInProgress) {
        this.$store.commit('wallet/setIsBuyingInProgress', true)
        this.$store.dispatch('wallet/makeDeposit')
          .then((res) => {
            this.currentTransaction = res;
            this.showTransactionDialog = true;
            this.isTransactionSuccess = true;
            this.$store.commit('wallet/setIsBuyingInProgress', false)
          })
          .catch(() => {
            if(this.self) {
              this.showTransactionDialog = true;
              this.isTransactionSuccess = false;
            }
            this.$store.commit('wallet/setIsBuyingInProgress', false)
          })
      }
    }
  },

  watch: {
    showTransactionDialog(newVal) {
      if (newVal) {
        document.body.classList.add('prevent-scroll');
      } else {
        document.body.classList.remove('prevent-scroll');
      }
    },
    showKYCInProgressDialog(newVal) {
      if (newVal) {
        document.body.classList.add('prevent-scroll');
      } else {
        document.body.classList.remove('prevent-scroll');
      }
    },
    showMaxTokenLimitDialog(newVal) {
      if (newVal) {
        document.body.classList.add('prevent-scroll');
      } else {
        document.body.classList.remove('prevent-scroll');
      }
    },
    showKYCDialog(newVal) {
      if (newVal) {
        document.body.classList.add('prevent-scroll');
      } else {
        document.body.classList.remove('prevent-scroll');
      }
    },
  }

}
</script>

<style lang="scss">
@import "PreSale";
</style>
