<template>
  <font-awesome-icon
      class="sbc-icon"
      :icon="iconName"
      v-bind="$attrs"
      @click="$emit('click',$event)"
  />
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'SbcIcon',
  components: {FontAwesomeIcon},
  props: {
    icon: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'light'
    },
  },

  computed: {
    typeClass() {
      switch (this.type) {
        case 'regular':
          return 'fa-regular';
        case 'thin':
          return 'fa-thin';
        case 'solid':
          return 'fa-solid';
        default :
          return 'fa-light'
      }
    },

    iconName() {
      return `${this.typeClass} ${this.icon}`
    }
  }

}
</script>

<style lang="scss">
@import "SbcIcon";
</style>
